import { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { routes } from '@gss/router';
import { getIsCheckInGuestAuthenticated } from '@gss/store/flows/checkInFlow/selectors';

export const requireCheckInGuestAuthenticated = <
  Props extends Record<string, unknown>
>(
  WrappedComponent: ComponentType<Props>
): typeof WrappedComponent => {
  const CheckInGuestAuthenticatedValidator = (props: Props): JSX.Element => {
    const isCheckInGuestAuthenticated = useSelector(
      getIsCheckInGuestAuthenticated
    );

    if (!isCheckInGuestAuthenticated) {
      return <Redirect to={routes.CHECK_IN_AUTH.path} />;
    }

    return <WrappedComponent {...props} />;
  };

  return CheckInGuestAuthenticatedValidator;
};
