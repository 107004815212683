import convert from 'color-convert';
import { getPalette } from 'config';
import { getTypography } from 'config/fonts';
import i18n from 'i18next';
import getMaterialPalette from 'material-palette';
import { MinibarItem, PaymentStatus } from 'types/Api/Cashiering';
import { CashieringSettings } from 'types/Api/CashieringSettings';
import { Dictionary } from 'types/Api/Dictionaries';
import {
  FolioStyleType,
  MembershipLevel,
  MembershipType,
  ProfileType,
  PropertyFloor,
  RatePlan,
  RoomLocation,
  TaxRule,
  TransactionCode,
  TranslatedEntity,
} from 'types/Api/Entities';
import {
  ParsedBaseKioskConfigurationEntity,
  ParsedDateFormat,
  ParsedKioskCommunicationType,
  ParsedKioskConfigurationConsent,
  ParsedKioskDictionaryEntryDetails,
  ParsedKioskLayoutSettings,
} from 'types/Api/ParsedKioskConfiguration';
import { PaymentOnlineInterface } from 'types/Api/PaymentInterface';
import {
  AgeBucket,
  AgeQualifyingCode,
  PurchaseElement,
} from 'types/Api/RateManager';
import {
  CorrespondenceDefaultDefinitions,
  HourlyInventoryManagement,
} from 'types/Api/Reservation';
import { TranslatedDescription } from 'types/Api/Shared';
import {
  ColorCodes,
  StoredBillingTransactionCode,
  StoredFile,
  StoredTransactionCode,
  SwitchCodes,
} from 'types/Configurator';
import { DateManager } from 'utils';
import { TIME_12H_FORMAT, TIME_24H_FORMAT } from 'utils/DateManager';

import { KioskComponentsConfigurator, ThemeType } from '@ac/kiosk-components';
import { mapLongDateFormat, RawNationality } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import {
  BaseKioskConfigurationEntity,
  KioskConfigurationProperty,
  KioskTelephoneRegionPrefixes,
} from '@gss/api/KioskApi/entries';
import { LogoPlaceholder } from '@gss/assets/images';
import { DEFAULT_APP_LANGUAGE } from '@gss/configs/constants';
import { customMessagesCodesMap } from '@gss/store/settings/interfaces/settings/customMessagesState';

const DEFAULT_MAX_MINIBAR_QUANTITY = 99;

const PREFIX = 'aboveCloud.';
const DEFAULT_PROPERTY_SETTINGS = {
  checkInTime: '',
  checkOutTime: '',
  currencyCode: '',
  dateTimeFormat: {
    longDateFormat: '',
    shortDateFormat: '',
    timeFormatCode: '',
  },
  name: '',
};

const keys = {
  PREFIX,
  TRANSLATIONS: `${PREFIX}translations`,
  SWITCHES: `${PREFIX}switches`,
  FILES: `${PREFIX}files`,
  PROPERTY: `${PREFIX}property`,
  COUNTRIES: `${PREFIX}countries`,
  NATIONALITIES: `${PREFIX}nationalities`,
  GENDERS: `${PREFIX}genders`,
  MOBILE_PREFIXES: `${PREFIX}mobile_prefixes`,
  CREDIT_CARD_AUTHORIZATION_RULES: `${PREFIX}credit_card_authorization_rules`,
  PURCHASE_ELEMENTS: `${PREFIX}purchase_elements`,
  MEMBERSHIP_LEVELS: `${PREFIX}membership_levels`,
  MEMBERSHIP_TYPES: `${PREFIX}membership_types`,
  ROOM_ATTRIBUTES: `${PREFIX}room_attributes`,
  PROPERTY_FLOORS: `${PREFIX}property_floors`,
  ROOM_LOCATIONS: `${PREFIX}room_locations`,
  DOCUMENT_TYPES: `${PREFIX}document_types`,
  PROFILE_TYPES: `${PREFIX}profile_types`,
  CONSENTS: `${PREFIX}consents`,
  ENTITIES: `${PREFIX}entities`,
  ADDRESS_TYPES: `${PREFIX}address_types`,
  EMAIL_TYPES: `${PREFIX}email_types`,
  MOBILE_TYPES: `${PREFIX}mobile_types`,
  SUGGESTEDPHONEPREFIXES: `${PREFIX}suggested_types`,
  CASHIERING: `${PREFIX}cashiering`,
  NUMBER_VALUES: `${PREFIX}number_values`,
  THEME: `${PREFIX}theme`,
  BILLING_TRANSACTION_CODES: `${PREFIX}billing_transaction_codes`,
  TRANSACTION_CODES: `${PREFIX}transaction_codes`,
  TITLES: `${PREFIX}titles`,
  PURPOSES_OF_STAY: `${PREFIX}stay_purposes`,
  PAYMENT_INTERFACE: `${PREFIX}paymentInterface`,
  COLORS: `${PREFIX}colors`,
  RATE_PLANS: `${PREFIX}rate_plans`,
  TAX_RULES: `${PREFIX}tax_rules`,
  CORRESPONDENCE: `${PREFIX}correspondence`,
  ET_GUARANTEED: `${PREFIX}et_guaranteed`,
  DEFAULT_FOLIO_STYLE: `${PREFIX}default_folio_style`,
  FOLIO_STYLES: `${PREFIX}folio_styles`,
  DEFAULT_ADDRESS: `${PREFIX}address`,
  DEFAULT_COMMUNICATION: `${PREFIX}communication`,
  AGE_BUCKETS: `${PREFIX}ageBuckets`,
  SETTINGS_IMAGES: `${PREFIX}settings_images`,
  POP_COUNTRY_LIST: `${PREFIX}pop_country_list`,
};

interface ConfigProperty {
  [attr: string]: any;
}

interface EntityType {
  id?: string;
  code?: string;
  isActive?: boolean;
}

interface ActiveConfigProperty extends ConfigProperty {
  isActive?: boolean;
  active?: boolean;
}

interface HslPalette {
  [key: string]: {
    h: number;
    s: number;
    l: number;
  };
}

function pickAttributes(obj: { [key: string]: any }, keys: string[]) {
  return keys
    .map((key) => (key in obj ? { [key]: obj[key] } : {}))
    .reduce((result, o) => ({ ...result, ...o }), {});
}

function getActiveSettings<T extends ActiveConfigProperty>(settings: T[]): T[] {
  return settings.filter((setting) => setting.isActive || setting.active);
}

function mapSettingsValues(
  settings: ParsedKioskLayoutSettings,
  codes: { [key: string]: string }
): ParsedKioskLayoutSettings {
  return Object.fromEntries(
    Object.entries(settings).filter(([key]) =>
      Object.values(codes).some((code) => key === code)
    )
  );
}

function mapCustomMessagesSettings(
  codes: typeof customMessagesCodesMap,
  settings: ParsedKioskLayoutSettings
): ParsedKioskLayoutSettings {
  const mappedSettings = Object.values(codes).map((customMessage) => {
    const matchedSettingKey = Object.keys(settings).find(
      (setting) => setting === customMessage.key
    );

    if (!matchedSettingKey) return undefined;

    const matchedSettingSwitch = Object.keys(settings).find(
      (setting) => setting === customMessage.switch
    );

    if (matchedSettingSwitch && !settings[matchedSettingSwitch])
      return undefined;

    const settingValue = settings[customMessage.key];

    return !(typeof settingValue === 'boolean')
      ? [matchedSettingKey, settingValue]
      : undefined;
  });

  const filteredSettings = mappedSettings.filter(isDefined);

  return Object.fromEntries(filteredSettings);
}

export const firstLetterUppercase = (string: string) => {
  return (
    (string || '').charAt(0).toUpperCase() +
    (string || '').toLowerCase().slice(1)
  );
};

export const getLanguageCode = () => DEFAULT_APP_LANGUAGE;

class Configurator {
  // Codes and statuses
  public static ageCodes = {
    ADULT: 'Adult',
    CHILD: 'Child',
  };
  public static roomStatuses = {
    INSPECTED: 'IN',
    CLEAN: 'CL',
  };
  public static roomMaintenanceStatuses = {
    OUT_OF_ORDER: 'OutOfOrder',
    OUT_OF_SERVICE: 'OutOfService',
  };
  public static frontdeskStatuses = {
    VACANT: 'VA',
  };
  public static housekeepingStatuses = {
    VACANT: 'VA',
    OCCUPIED: 'OC',
  };
  public static reservationStatuses = {
    IN_HOUSE: 'IH',
    RESERVED: 'RS',
    DUE_IN: 'DI',
    DUE_OUT: 'DO',
  };
  public static deviceTypes = {
    PaymentDevice: 'PaymentDevice',
    PassportScanner: 'PassportScanner',
  };
  public static defaultCommunicationCodes = {
    EMAIL: 'KIOSKDEFAULTEMAIL',
    PHONE: 'KIOSKDEFAULTPHONE',
  };
  public static defaultAddressCodes = {
    GUEST: 'KIOSKDEFAULTGUESTADDRESS',
    COMPANY: 'KIOSKDEFAULTCOMPANYADDRESS',
  };

  public static colorCodes: ColorCodes = {
    HEADER_BACKGROUND: 'KIOSKHEADERBGCOLOR',
    HEADER_FONT_COLOR: 'KIOSKHEADERFONTCOLOR',
    BODY_BACKGROUND: 'KIOSKBODYBGCOLOR',
    BODY_FONT_COLOR: 'KIOSKBODYFONTCOLOR',
    FOOTER_BACKGROUND: 'KIOSKFOOTERBGCOLOR',
    FOOTER_FONT_COLOR: 'KIOSKFOOTERFONTCOLOR',
    RESERVATION_HEADER_BACKGROUND: 'KIOSKRESERVATIONHEADERBGCOLOR',
    RESERVATION_HEADER_FONT_COLOR: 'KIOSKRESERVATIONHEADERFONTCOLOR',
    HINT_FONT_COLOR: 'KIOSKHINTFONTCOLOR',
    BUTTON_PRIMARY_BACKGROUND_ACTIVE: 'MAINACTIVEBACKGROUNDCOLOR',
    BUTTON_PRIMARY_BORDER_ACTIVE: 'MAINACTIVEBORDERCOLOR',
    BUTTON_PRIMARY_TEXT_ACTIVE: 'MAINACTIVETEXTCOLOR',
    BUTTON_PRIMARY_BACKGROUND_DISABLED: 'MAINDISABLEDBACKGROUNDCOLOR',
    BUTTON_PRIMARY_BORDER_DISABLED: 'MAINDISABLEDBORDERCOLOR',
    BUTTON_PRIMARY_TEXT_DISABLED: 'MAINDISABLEDTEXTCOLOR',
    BUTTON_PRIMARY_BACKGROUND_CLICKED: 'MAINCLICKEDBACKGROUNDCOLOR',
    BUTTON_PRIMARY_BORDER_CLICKED: 'MAINCLICKEDBORDERCOLOR',
    BUTTON_PRIMARY_TEXT_CLICKED: 'MAINCLICKEDTEXTCOLOR',
    BUTTON_SECONDARY_BACKGROUND_ACTIVE: 'SECONDARYACTIVEBACKGROUNDCOLOR',
    BUTTON_SECONDARY_BORDER_ACTIVE: 'SECONDARYACTIVEBORDERCOLOR',
    BUTTON_SECONDARY_TEXT_ACTIVE: 'SECONDARYACTIVETEXTCOLOR',
    BUTTON_SECONDARY_BACKGROUND_DISABLED: 'SECONDARYDISABLEDBACKGROUNDCOLOR',
    BUTTON_SECONDARY_BORDER_DISABLED: 'SECONDARYDISABLEDBORDERCOLOR',
    BUTTON_SECONDARY_TEXT_DISABLED: 'SECONDARYDISABLEDTEXTCOLOR',
    BUTTON_SECONDARY_BACKGROUND_CLICKED: 'SECONDARYCLICKEDBACKGROUNDCOLOR',
    BUTTON_SECONDARY_BORDER_CLICKED: 'SECONDARYCLICKEDBORDERCOLOR',
    BUTTON_SECONDARY_TEXT_CLICKED: 'SECONDARYCLICKEDTEXTCOLOR',
    ADDONS_BACKGROUND_COLOR: 'ADDONBACKGROUNDCOLOR',
    COUNTER_FINISHED_BACKGROUND_COLOR: 'KIOSKPROGRESSFINISHEDBG',
    COUNTER_BACKGROUND_COLOR: 'KIOSKPROGRESSTODOBG',
    COUNTER_BORDER_COLOR: 'KIOSKPROGRESSSTEPBORDER',
    COUNTER_FONT_COLOR: 'KIOSKPROGRESSFONT',
  };
  public static switchCodes: SwitchCodes = {
    USE_INSPECTED_STATUS: 'USEINSPECTEDSTATUS',
    SHOW_CHECK_IN: 'SHOWCHECKIN',
    SHOW_CHECK_OUT: 'SHOWCHECKOUT',
    SHOW_GENERATE_KEY: 'SHOWGENERATEKEY',
    SHOW_CONTACT: 'SHOWCONTACT',
    SHOW_DOCUMENT: 'SHOWDOCUMENT',
    SHOW_ADDRESS: 'SHOWADDRESS',
    SHOW_SCREENSAVER: 'SHOWSCREENSAVER',
    SHOW_SCAN_DOCUMENT: 'SHOWSCANDOCUMENT',
    KIOSKS_PASSPORT_SCANNING: 'KIOSKSPASSPORTSCANNING',
    SHOW_NUMBER_OF_KEYS: 'SHOWNUMBEROFKEYS',
    SHOW_MINIBAR: 'SHOWMINIBAR',
    SHOW_COMPANY_INVOICE: 'COMPANYINVOICE',
    SHOW_CHECK_IN_SUMMARY: 'CHECKINSUMMARYSIGN',
    SHOW_CHECK_OUT_SUMMARY: 'CHECKOUTSUMMARYSIGN',
    SHOW_ADDONS: 'SHOWADDONS',
    SHOW_PREPAYMENT: 'SHOWPREPAYMENTSTEP',
    SHOW_PRE_AUTHORIZATION: 'SHOWPREAUTHSTEP',
    SHOW_PURPOSE_OF_STAY: 'SHOWPURPOSEOFSTAY',
    SHOW_ALLOCATION_STEP: 'SHOWROOMALLOCSTEP',
    CHECK_IN_BY_SURNAME_ONLY: 'CHECKINBYSURNAMEONLY',
    SEPARATE_HEADER_BG: 'SEPARATEHEADERBG',
    SEPARATE_FOOTER_BG: 'SEPARATEFOOTERBG',
    SEPARATE_RESERVATION_HEADER_BG: 'KIOSKSEPARATERESERVATIONHEADER',
    SEPARATE_WELCOME_HEADER_BG: 'SEPARATEMAINSCRHEADERBG',
    SHOW_CONF_NUMBER: 'SHOWCONFNUMBER',
    SHOW_DUE_DATE: 'SHOWDUEDATE',
    SHOW_NO_OF_NIGHTS: 'SHOWNOOFNIGHTS',
    COMPANY_INVOICE_ON_CHECK_OUT: 'COMPANYINVOICEONCHECKOUT',
    SHOW_EXPAND_FOR_RESERVATION_HEADER: 'KIOSKRESERVATIONHEADEREXPAND',
    SHOW_SCREENSAVER_MESSAGE: 'SHOWSCREENSAVERMESSAGE',
    SHOW_RATE_PLAN: 'KIOSKRPONRSVNPRESENCE',
    SHOW_TERMS_AND_CONDITIONS: 'KIOSKSHOWTERMSNCOND',
    SHOW_NATIONALITY_FIELD: 'KIOSKSHOWNATIONALITY',
    ALLOW_KIOSK_PROFILE_MATCH: 'KIOSKPROFILEMATCH',
    ALLOW_KIOSK_PROFILE_DUPLICATES: 'KIOSKPROFILEDUPLICATES',
    SHOW_FISCAL_DOCUMENT_SELECTION: 'SHOWFISCALDOCUMENTTYPESELECTION',
    SHOW_TAX_ID: 'SHOWTAXIDDURINGCHECKINCHECKOUT',
    PRE_AUTH_DEDUCT_DEPOSIT: 'PREAUTHDEDUCTDEPOSIT',
    PRE_AUTH_DEDUCT_BI: 'PREAUTHDEDUCTBI',
    FAST_TRACK_CHECK_OUT: 'ACTIVATEFASTTRACKCHECKOUT',
    DISTRICT: 'DISTRICT',
  };
  public static settingsImagesCodes = {
    WELCOME_LOGO: 'WELCOMELOGO',
    SCREEN_SAVER_LOGO: 'SCREENSAVERLOGO',
    WELCOME_BACKROUND_IMAGE: 'WELCOMEBACKGROUNDIMAGE',
    CARD_PAYMENT_IMAGE1: 'PREAUTHIMAGE1',
    CARD_PAYMENT_IMAGE2: 'PREAUTHIMAGE2',
    VIEW_BACKGROUND_IMAGE: 'BACKGROUNDIMAGE',
    KEYS_GENERATE_IMAGE: 'KEYGENERATEIMAGE',
    PASSPORT_SCANNING_IMAGE: 'PASSPORTSCANNINGIMAGE',
  };
  public static fileCodes = {
    PURCHASE_ELEMENTS: 'PURCHASE_ELEMENT',
  };
  public static entityCodes = {
    COMPANY_ADDRESS_TYPE: 'TAADDRTYPE',
    ADDRESS_TYPE: 'INDADDRTYPE',
    EMAIL_TYPE: 'INDCOMEMAIL',
    BILLING_EMAIL: 'BILLINGEMAIL',
    MOBILE_TYPE: 'INDCOMMOB',
    TRANSACTION_CODE_FOR_CREDIT_CARD: 'TRANSACTIONCODEFORCREDITCARD',
    BACKGROUND_POSITION: 'BACKGROUNDIMAGEPOS',
    KIOSK_IFC_CASHIER_NUMBER: 'KIOSKUSEDCASHIERID',
    KIOSK_USED_FONT: 'KIOSKUSEDFONT',
    KIOSK_MINIBAR_ITEMS: 'KIOSKMINIBARITEMS',
    KIOSK_PRE_AUTH_RULE: 'PREAUTHORIZATIONRULE',
    DEFAULT_FOLIO_STYLE: 'FOLIOSTYLE',
    SUGGESTEDPHONEPREFIXES: 'SUGGESTEDPHONEPREFIXES',
    SHORT_DATE_FORMAT: 'SHORTDATEFORMAT',
    LONG_DATE_FORMAT: 'LONGDATEFORMAT',
    POP_COUNTRY_LIST: 'POPCOUNTRYLIST',
  };
  public static folioCodes = {
    FOLIO_COMPANY_TYPE: 'COM',
  };
  public static folioStatuses = {
    SETTLED: 'Settled',
    ACTIVE: 'Active',
  };
  public static folioStatusCodes = {
    SETTLED: 'CO',
    ACTIVE: 'AC',
  };
  public static consentConfig = {
    CONSENT_SOURCE: 'OTH',
    CONSENT_DESCRIPTION: 'Kiosk',
  };
  public static cashieringSettingsCodes = {
    CASHIER_NUMBER: 'cashierNumber',
    LEAVE_OPEN_ACCOUNT: 'leaveOpenAccount',
    FORCE_CHECKOUT_FOLIO: 'forceCheckOutFolio',
  };
  public static operationStatuses = {
    RUNNING: 'Running',
    COMPLETED: 'Completed',
    SUCCEEDED: 'Succeeded',
    FAILED: 'Failed',
    PAYMENT_SUCCESS: 'PaymentCompleted',
    PAYMENT_FAILURE: 'ProcessingFailed',
    USER_CANCELLED: 'User Cancelled',
    PAYMENT_ONGOING: 'ProcessingPayment',
    PAYMENT_REJECTED: 'PaymentRejected',
    PAYMENT_INITIALIZE: 'ToBeInitialized',
  };
  public static paymentCommandCodes = {
    TOP_UP: 'TOPUP',
  };
  public static paymentMethodsOperationStatuses = {
    ToBeInitialized: PaymentStatus.ToBeInitialized,
    ProcessingFailed: PaymentStatus.ProcessingFailed,
    ProcessingPayment: PaymentStatus.ProcessingPayment,
    PaymentRejected: PaymentStatus.PaymentRejected,
    PaymentCompleted: PaymentStatus.PaymentCompleted,
  };
  public static aggregatorParams = {
    TRANSACTIONS: 'Transactions',
  };
  public static transactionCodes = {
    REVENUE: 'Revenue',
    PAYMENT: 'Payment',
  };
  public static numberValueCodes = {
    CHECK_IN_BEFORE_ARRIVAL: 'CHECKINBEFOREARRIVAL',
  };
  public static cuttingKeysConfig = {
    ENCODER: '11111111-1111-1111-1111-111111111111',
    DEFAULT_CODE: 'TEST-STATION',
  };
  public static profileRoles = {
    COMPANY: 'CMP',
    INDIVIDUAL: 'IND',
  };
  public static themeCodes = {
    THEME_COLOR: 'THEMECOLOR',
  };
  public static calculationRuleCodes = {
    PER_PERSON: 'PerPerson',
    PER_RESERVATION: 'PerReservation',
  };
  public static dateAnchorCodes = {
    PER_PERSON: 'Span',
    PER_RESERVATION: 'Exact',
    DEPARTURE: 'Departure',
    ARRIVAL: 'Arrival',
  };
  public static paymentTypeCodes = {
    CREDIT_CARD: 'CreditCard',
    CASH: 'Cash',
  };
  public static creditCardTypeCodes = {
    ONLINE: 'Online',
    OFFLINE: 'Offline',
  };
  public static generateKeysErrorCodes = {
    TOO_MANY_RESERVATIONS: 'Too many reservations',
  };
  public static paymentErrorCodes = {
    WRONG_AMOUNT: 'WrongTransactionAmount',
  };
  public static roomErrorCodes = {
    NO_AVAILABLE_ROOMS: 'No available rooms were found.',
    DIRTY_ROOM_EXISTS: 'DirtyRoomExists',
    ROOM_ASSIGN_FAILED: 'Room assign failed',
  };
  public static checkInErrorCodes = {
    TOO_MUCH_RESERVATIONS_ERROR_CODE: 'MoreThenOneResultFoundException',
    MULTIROOM_RESERVATION_NEEDS_CONFIRMATION_NO:
      'MultiroomReservationNeedsConfirmationNo',
  };
  public static checkOutCodes = {
    NOT_ALL_FOLIOS_CHECKED_OUT: 'AccountCheckOutNotAllFoliosAreCheckedOut',
  };
  public static folioTypeCodes = {
    COMPANY: 'COM',
    INDIVIDUAL: 'GUE',
    TRAVEL_AGENT: 'TRA',
  };
  public static consentTypeCodes = {
    CROSS_BORDER: 'CBP',
  };
  public static correspondences = {
    CORRESPONDENCE_DEFINITIONS: 'CORRESPONDENCEDEFINITIONS',
  };
  public static etaEdtGuaranteed = {
    HOURLY_INVENTORY_MANAGEMENT: 'HOURLYINVENTORYMANAGEMENT',
  };
  public static correspondenceRoleTypes = {
    POST_CHECK_IN: 'PostCheckIn',
  };
  public static adapterInterfaceCode = 'ShijiOnlinePayment';
  public static idleTimeout = 60 * 1000;
  public static inavtivityModalCountdown = 10 * 1000;
  public static cashieringDefaultSettings = {
    leaveOpenAccount: false,
    forceCheckOutFolio: false,
    billViewAllowed: true,
    remoteCheckOutAllowed: true,
    cashierNumber: undefined,
  };

  public static pricePlaceholder = 'XXX';

  // Entities
  public static get propertySettings(): KioskConfigurationProperty {
    return this.getLocalSettings(keys.PROPERTY, DEFAULT_PROPERTY_SETTINGS);
  }
  public static get propertyBusinessDate(): Date {
    return (
      this.getLocalSettings(keys.PROPERTY, DEFAULT_PROPERTY_SETTINGS)
        .businessDate || new Date()
    );
  }
  public static get timeFormat(): string {
    const timeFormat = this.propertySettings.dateTimeFormat?.timeFormat;
    const formats: { [key: string]: string } = {
      H24: TIME_24H_FORMAT,
      H12: TIME_12H_FORMAT,
    };

    return timeFormat
      ? formats[timeFormat] || TIME_12H_FORMAT
      : TIME_12H_FORMAT;
  }
  public static get membershipLevels(): MembershipLevel[] {
    return this.getLocalSettings(keys.MEMBERSHIP_LEVELS, []);
  }
  public static get membershipTypes(): MembershipType[] {
    return this.getLocalSettings(keys.MEMBERSHIP_TYPES, []);
  }
  public static get roomAttributes(): TranslatedEntity[] {
    return this.getLocalSettings(keys.ROOM_ATTRIBUTES, []);
  }
  public static get titles(): ParsedKioskDictionaryEntryDetails[] {
    return this.getLocalSettings(keys.TITLES, []);
  }
  public static get taxRules(): TaxRule[] {
    return this.getLocalSettings(keys.TAX_RULES, []);
  }
  public static get documentTypes(): ParsedKioskDictionaryEntryDetails[] {
    return this.getLocalSettings(keys.DOCUMENT_TYPES, []);
  }
  public static get profileTypes(): ProfileType[] {
    return this.getLocalSettings(keys.PROFILE_TYPES, []);
  }
  public static get consents(): ParsedKioskConfigurationConsent[] {
    return this.getLocalSettings(keys.CONSENTS, []);
  }
  public static get propertyFloors(): PropertyFloor[] {
    return this.getLocalSettings(keys.PROPERTY_FLOORS, []);
  }
  public static get roomLocations(): RoomLocation[] {
    return this.getLocalSettings(keys.ROOM_LOCATIONS, []);
  }
  public static get addressTypes(): ParsedKioskDictionaryEntryDetails[] {
    return this.getLocalSettings(keys.ADDRESS_TYPES, []);
  }
  public static get emailTypes(): ParsedKioskCommunicationType[] {
    return this.getLocalSettings(keys.EMAIL_TYPES, []);
  }
  public static get mobileTypes(): ParsedKioskCommunicationType[] {
    return this.getLocalSettings(keys.MOBILE_TYPES, []);
  }
  public static get suggestedPhonePrefixes(): string[] {
    const getSuggested = this.getLocalSettings(keys.ENTITIES, []);

    return getSuggested[this.entityCodes.SUGGESTEDPHONEPREFIXES];
  }
  public static get popCountryList(): string[] {
    const getSuggested = this.getLocalSettings(keys.ENTITIES, []);

    return getSuggested[this.entityCodes.POP_COUNTRY_LIST];
  }
  public static get billingTransactionCodes(): StoredBillingTransactionCode[] {
    return this.getLocalSettings(keys.BILLING_TRANSACTION_CODES, []);
  }
  public static get genders(): ParsedBaseKioskConfigurationEntity[] {
    return this.getLocalSettings(keys.GENDERS, []);
  }
  public static get purposesOfStay(): ParsedKioskDictionaryEntryDetails[] {
    return this.getLocalSettings(keys.PURPOSES_OF_STAY, []);
  }
  public static get primaryColor() {
    const savedPrimaryColor = this.getThemeSetting(
      this.themeCodes.THEME_COLOR,
      null
    );
    if (!savedPrimaryColor) return null;
    const themeHsl = convert.hex.hsl(savedPrimaryColor);
    const hslPalette: HslPalette = getMaterialPalette({
      h: themeHsl[0],
      s: themeHsl[1],
      l: themeHsl[2],
    });

    return Object.entries(hslPalette)
      .map(([key, value]) => ({
        [key]: convert.hsl.hex(
          Object.values(value as any) as [number, number, number]
        ),
      }))
      .reduce((obj: any, item: any) => {
        obj[Object.keys(item)[0]] = `#${Object.values(item)[0]}`;

        return obj;
      }, {});
  }

  public static get defaultCompanyAddressType() {
    return (
      this.getEntityTypeId(this.entityCodes.COMPANY_ADDRESS_TYPE) ||
      this.defaultCompanyAddressTypeForKiosk
    );
  }

  public static get defaultGuestAddressType() {
    return (
      this.getEntityTypeId(this.entityCodes.ADDRESS_TYPE) ||
      this.defaultGuestAddressTypeForKiosk
    );
  }

  public static get defaultCommunicationEmailType() {
    return (
      this.getEntityTypeId(this.entityCodes.EMAIL_TYPE) ||
      this.defaultCommunicationEmailTypeForKiosk
    );
  }

  public static get defaultCommunicationMobileType() {
    return (
      this.getEntityTypeId(this.entityCodes.MOBILE_TYPE) ||
      this.defaultCommunicationMobileTypeForKiosk
    );
  }

  public static getActiveEntityCodeOrDefault(
    entities: EntityType[],
    code: string | undefined,
    defaultCode: string
  ) {
    const foundEntity = entities.find(
      ({ code: entityCode }) => code === entityCode
    );
    const isEntityIsActive = foundEntity ? foundEntity.isActive ?? true : false;

    return isEntityIsActive ? (code as string) : defaultCode;
  }

  // Getting Settings
  public static get dateFormat(): ParsedDateFormat {
    const entityTypes = this.getLocalSettings(keys.ENTITIES, {});

    const shortDateFormat = (
      entityTypes[this.entityCodes.SHORT_DATE_FORMAT] ??
      this.propertySettings.dateTimeFormat?.shortDateFormat
    ).toUpperCase();
    const longDateFormat =
      entityTypes[this.entityCodes.LONG_DATE_FORMAT] ??
      this.propertySettings.dateTimeFormat?.longDateFormat;
    const formattedLondDate =
      longDateFormat && mapLongDateFormat(longDateFormat);

    return { shortDateFormat, longDateFormat: formattedLondDate };
  }

  public static get defaultCompanyAddressTypeForKiosk() {
    const { COMPANY } = this.defaultAddressCodes;

    const defaultAddressTypes = this.getLocalSettings(keys.DEFAULT_ADDRESS, {});

    return this.addressTypes.find(
      ({ id }) => id === defaultAddressTypes[COMPANY]
    );
  }

  public static get defaultGuestAddressTypeForKiosk() {
    const { GUEST } = this.defaultAddressCodes;

    const defaultAddressTypes = this.getLocalSettings(keys.DEFAULT_ADDRESS, {});

    return this.addressTypes.find(
      ({ id }) => id === defaultAddressTypes[GUEST]
    );
  }

  public static get defaultCommunicationEmailTypeForKiosk() {
    const { EMAIL } = this.defaultCommunicationCodes;
    const defaultCommunicationTypes = this.getLocalSettings(
      keys.DEFAULT_COMMUNICATION,
      {}
    );

    return this.emailTypes.find(
      ({ id }) => id === defaultCommunicationTypes[EMAIL]
    );
  }

  public static get defaultCommunicationMobileTypeForKiosk() {
    const { PHONE } = this.defaultCommunicationCodes;

    const defaultCommunicationTypes = this.getLocalSettings(
      keys.DEFAULT_COMMUNICATION,
      {}
    );

    return this.mobileTypes.find(
      ({ id }) => id === defaultCommunicationTypes[PHONE]
    );
  }

  public static get files(): { [index: string]: StoredFile } {
    return this.getLocalSettings(keys.FILES, {});
  }
  public static get settingImages(): {
    [index: string]: { id: string; content: string };
  } {
    return this.getLocalSettings(keys.SETTINGS_IMAGES, {});
  }
  public static get ageBuckets(): AgeBucket[] {
    return this.getLocalSettings(keys.AGE_BUCKETS, []);
  }
  public static get translations(): { [key: string]: TranslatedDescription[] } {
    return this.getLocalSettings(keys.TRANSLATIONS, []);
  }
  public static get logo(): string {
    return (
      this.settingImages[this.settingsImagesCodes.WELCOME_LOGO]?.content ||
      LogoPlaceholder
    );
  }
  public static get welcomeBackground(): string {
    return this.settingImages[this.settingsImagesCodes.WELCOME_BACKROUND_IMAGE]
      ?.content;
  }
  public static get screenSaverImage(): string {
    return this.settingImages[this.settingsImagesCodes.SCREEN_SAVER_LOGO]
      ?.content;
  }
  public static get allTransactionCodes(): StoredTransactionCode[] {
    return this.getLocalSettings(keys.TRANSACTION_CODES, {});
  }
  public static get ratePlans(): RatePlan[] {
    return this.getLocalSettings(keys.RATE_PLANS, {});
  }
  public static get cardPaymentImage1(): string {
    return this.settingImages[this.settingsImagesCodes.CARD_PAYMENT_IMAGE1]
      ?.content;
  }
  public static get cardPaymentImage2(): string {
    return this.settingImages[this.settingsImagesCodes.CARD_PAYMENT_IMAGE2]
      ?.content;
  }
  public static get viewBackgroundImage(): string {
    return this.settingImages[this.settingsImagesCodes.VIEW_BACKGROUND_IMAGE]
      ?.content;
  }
  public static get keysGenerateImage(): string {
    return this.settingImages[this.settingsImagesCodes.KEYS_GENERATE_IMAGE]
      ?.content;
  }
  public static get passportScanningImage(): string {
    return this.settingImages[this.settingsImagesCodes.PASSPORT_SCANNING_IMAGE]
      ?.content;
  }
  public static get viewBackgroundPosition(): string {
    const entityTypes = this.getLocalSettings(keys.ENTITIES, {});

    return entityTypes[this.entityCodes.BACKGROUND_POSITION];
  }
  public static get defaultFolioStyle(): { id: string; code: string } {
    return this.getLocalSettings(keys.DEFAULT_FOLIO_STYLE, [{}])[0];
  }
  public static get folioStyles(): FolioStyleType[] {
    return this.getLocalSettings(keys.FOLIO_STYLES, []);
  }
  public static get postCheckInLetter() {
    const {
      [this.correspondences.CORRESPONDENCE_DEFINITIONS]:
        correspondenceDefinitions,
    }: {
      [key: string]: CorrespondenceDefaultDefinitions | undefined;
    } = this.getLocalSettings(keys.CORRESPONDENCE, {});

    return correspondenceDefinitions?.definitionsPerRoles.find(
      (definition) =>
        definition.role === this.correspondenceRoleTypes.POST_CHECK_IN
    );
  }
  public static get etGuaranteed() {
    const {
      [this.etaEdtGuaranteed.HOURLY_INVENTORY_MANAGEMENT]: options,
    }: {
      [key: string]: HourlyInventoryManagement | undefined;
    } = this.getLocalSettings(keys.ET_GUARANTEED, {});

    return options;
  }

  public static get minibarTransactionCodes(): StoredBillingTransactionCode[] {
    const allCodes = this.billingTransactionCodes || [];
    const minibarCodeIds =
      this.getEntityTypeId(this.entityCodes.KIOSK_MINIBAR_ITEMS) || [];

    return allCodes.filter((code) => minibarCodeIds.includes(code.id));
  }

  public static get minibarItems(): MinibarItem[] {
    return Configurator.minibarTransactionCodes.map((item) => ({
      title: item.description
        ? Configurator.getDescription(item.description)
        : '',
      id: item.id,
      price: item.defaultPostingValue || 0,
      transactionCode: item.code,
      maxQuantity: this.getMinibarItemMaxQuantity(item),
    }));
  }

  public static getMinibarItemMaxQuantity = (
    item: StoredBillingTransactionCode
  ) => {
    const { postingValueRange, defaultPostingValue } = item;
    const isWrongRange = Boolean(
      !postingValueRange || (postingValueRange && !postingValueRange.to)
    );
    if (isWrongRange || !defaultPostingValue) {
      return DEFAULT_MAX_MINIBAR_QUANTITY;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    return Math.floor((postingValueRange!.to as number) / defaultPostingValue);
  };

  public static getFile(key: string): StoredFile {
    return this.files[key];
  }

  public static getPurchaseElementImage(code: string) {
    return this.getFile(`${this.fileCodes.PURCHASE_ELEMENTS}.${code}`);
  }

  public static getAgeBucket(code: AgeQualifyingCode) {
    return this.ageBuckets.find(
      (ageBucket) => ageBucket.ageQualifyingCode === code
    );
  }

  public static get cashieringSettings(): CashieringSettings {
    return this.getLocalSettings(
      keys.CASHIERING,
      this.cashieringDefaultSettings
    );
  }

  public static get paymentInterface(): {
    adaptersConfiguration: PaymentOnlineInterface[];
    transactionCode: string;
  } {
    return this.getLocalSettings(keys.PAYMENT_INTERFACE, {});
  }
  public static get isTopUpAllowed(): boolean {
    const paymentInterface = this.paymentInterface;
    const supportedCommandCodes = paymentInterface?.adaptersConfiguration
      ? paymentInterface.adaptersConfiguration.reduce(
          (prev: string[], curr: PaymentOnlineInterface) => {
            const codes = curr.supportedPaymentCommands.map(
              (item) => item.commandName
            );

            return [...prev, ...codes];
          },
          []
        )
      : [];

    return supportedCommandCodes.includes(this.paymentCommandCodes.TOP_UP);
  }
  public static getTransactionCode(code: string) {
    const translationCodes = this.allTransactionCodes;

    return translationCodes.find((item) => item.code === code);
  }
  public static getTransactionCodeDescription(code: string) {
    const transactionCode = this.getTransactionCode(code);

    return transactionCode
      ? this.getDescription(transactionCode.description)
      : '';
  }
  public static getTranslation(key: string, language?: string) {
    const translationValues = this.translations[key] || [];

    return this.getDescription(translationValues, language);
  }

  public static getTranslations(key: string) {
    return this.translations[key] || [];
  }

  public static getTranslationCodes(): { [key: string]: string } {
    const codes = Object.entries(customMessagesCodesMap).map((code) => {
      const [key, codeMap] = code;

      return [key, codeMap.key];
    });

    return Object.fromEntries(codes);
  }

  public static getSwitch(key: string, defaultValue?: boolean) {
    const switches = this.getLocalSettings(keys.SWITCHES, {});
    const switchSetting = switches[key];

    return switchSetting !== undefined ? switchSetting : defaultValue;
  }

  public static getColor(key: string, defaultValue?: string) {
    const colors = this.getLocalSettings(keys.COLORS, {});

    return colors[key] || defaultValue;
  }

  public static getThemeSetting(key: string, defaultValue?: any) {
    const themeSettings = this.getLocalSettings(keys.THEME, {});
    const themeSetting = themeSettings[key];

    return themeSetting !== undefined ? themeSetting : defaultValue;
  }

  public static getEntityTypeId(key: string) {
    const entityTypes = this.getLocalSettings(keys.ENTITIES, {});
    const entityTypeId = entityTypes[key];

    return entityTypeId;
  }

  public static getEntityTypeCode<T extends EntityType>(
    entityTypes: T[],
    entityCode: string
  ) {
    const entityTypeId = this.getEntityTypeId(entityCode);
    const entityType = entityTypes.find(
      (entityType) => entityType.id === entityTypeId
    );

    return entityType ? entityType.code : undefined;
  }

  public static getCountries(
    languageCode: string
  ): BaseKioskConfigurationEntity[] {
    return this.getLocalSettings(
      `${keys.COUNTRIES}.${languageCode}`,
      this.getLocalSettings(`${keys.COUNTRIES}.${DEFAULT_APP_LANGUAGE}`, [])
    );
  }

  public static getNationalities(languageCode: string): RawNationality[] {
    return this.getLocalSettings(
      `${keys.NATIONALITIES}.${languageCode}`,
      this.getLocalSettings(`${keys.NATIONALITIES}.${DEFAULT_APP_LANGUAGE}`, [])
    );
  }

  public static getProfileTypeCode(role: string): string {
    return this.profileTypes.filter((type) => type.role === role)[0]?.code;
  }

  public static getMobilePrefixes(
    languageCode: string
  ): KioskTelephoneRegionPrefixes[] {
    return this.getLocalSettings(
      `${keys.MOBILE_PREFIXES}.${languageCode}`,
      this.getLocalSettings(`${keys.COUNTRIES}.${DEFAULT_APP_LANGUAGE}`, [])
    );
  }

  public static getCreditCardAuthorizationRules(
    languageCode: string
  ): Dictionary[] {
    return this.getLocalSettings(
      `${keys.CREDIT_CARD_AUTHORIZATION_RULES}.${languageCode}`,
      this.getLocalSettings(
        `${keys.CREDIT_CARD_AUTHORIZATION_RULES}.${DEFAULT_APP_LANGUAGE}`,
        []
      )
    );
  }

  public static getPurchaseElements(
    languageCode: string = DEFAULT_APP_LANGUAGE
  ): PurchaseElement[] {
    const elements = this.getLocalSettings(keys.PURCHASE_ELEMENTS, []);

    return elements.map((elem: PurchaseElement) => ({
      ...elem,
      description: this.getDescription(elem.description, languageCode),
      longDescription: this.getDescription(elem.longDescription, languageCode),
    }));
  }

  public static getRatePlanByTransactionCodeId(id: string) {
    return this.ratePlans.find((plan) => plan.transactionCodeId === id)!;
  }

  public static getNumberValues(key: string, defaultValue?: number) {
    const numberValues = this.getLocalSettings(keys.NUMBER_VALUES, {});
    const numberValue = numberValues[key];

    return numberValue !== undefined ? parseInt(numberValue, 10) : defaultValue;
  }

  public static getDescription(
    descriptions: TranslatedDescription[] = [],
    language?: string
  ) {
    const translation = this.findDescription(
      descriptions,
      language || i18n.language
    );
    const description =
      translation || this.findDescription(descriptions, DEFAULT_APP_LANGUAGE);

    return description ? description.content : '';
  }

  // Saving Settings
  public static updateSwitch(key: string, value: any) {
    const switches = this.getLocalSettings(keys.SWITCHES, {});
    switches[key] = value;
    this.saveLocalSettings(keys.SWITCHES, switches);
  }
  public static saveCashieringSettings(key: string, value?: any) {
    const cashieringSettings = this.getLocalSettings(
      `${keys.CASHIERING}`,
      this.cashieringDefaultSettings
    );
    this.saveLocalSettings(keys.CASHIERING, {
      ...cashieringSettings,
      [key]: value,
    });
  }
  public static clearCashieringSettings() {
    this.saveLocalSettings(keys.CASHIERING, {
      ...this.cashieringDefaultSettings,
    });
  }

  public static saveSettings(settings: ParsedKioskLayoutSettings) {
    const switches = mapSettingsValues(settings, this.switchCodes);
    const translations = mapCustomMessagesSettings(
      customMessagesCodesMap,
      settings
    );
    const entities = mapSettingsValues(settings, this.entityCodes);
    const themeSettings = mapSettingsValues(settings, this.themeCodes);
    const numberValues = mapSettingsValues(settings, this.numberValueCodes);
    const colors = mapSettingsValues(settings, this.colorCodes);
    const correspondence = mapSettingsValues(settings, this.correspondences);
    const etGuaranteed = mapSettingsValues(settings, this.etaEdtGuaranteed);
    const defaultCommunicationTypes = mapSettingsValues(
      settings,
      this.defaultCommunicationCodes
    );
    const defaultAddressTypes = mapSettingsValues(
      settings,
      this.defaultAddressCodes
    );
    const settingsImagesCodes = mapSettingsValues(
      settings,
      this.settingsImagesCodes
    );

    this.saveLocalSettings(keys.COLORS, colors);
    this.saveLocalSettings(keys.SWITCHES, switches);
    this.saveLocalSettings(keys.TRANSLATIONS, translations);
    this.saveLocalSettings(keys.ENTITIES, entities);
    this.saveLocalSettings(keys.THEME, themeSettings);
    this.saveLocalSettings(keys.NUMBER_VALUES, numberValues);
    this.saveLocalSettings(keys.CORRESPONDENCE, correspondence);
    this.saveLocalSettings(keys.ET_GUARANTEED, etGuaranteed);
    this.saveLocalSettings(keys.DEFAULT_ADDRESS, defaultAddressTypes);
    this.saveLocalSettings(
      keys.DEFAULT_COMMUNICATION,
      defaultCommunicationTypes
    );
    this.saveLocalSettings(keys.SETTINGS_IMAGES, settingsImagesCodes);
  }

  public static saveFile(key: string, value: StoredFile) {
    const files = this.getLocalSettings(keys.FILES, {});
    this.saveLocalSettings(keys.FILES, { ...files, [key]: value });
  }

  public static saveAgeBuckets(ageBuckets: AgeBucket) {
    this.saveLocalSettings(keys.AGE_BUCKETS, ageBuckets, {
      saveActiveOnly: true,
    });
  }

  public static saveCountries(
    languageCode: string,
    countries: BaseKioskConfigurationEntity[]
  ) {
    this.saveLocalSettings(`${keys.COUNTRIES}.${languageCode}`, countries);
  }

  public static saveNationalities(
    languageCode: string,
    nationalities: RawNationality[]
  ) {
    this.saveLocalSettings(
      `${keys.NATIONALITIES}.${languageCode}`,
      nationalities
    );
  }

  public static saveMobilePrefixes(
    languageCode: string,
    countries: KioskTelephoneRegionPrefixes[]
  ) {
    this.saveLocalSettings(
      `${keys.MOBILE_PREFIXES}.${languageCode}`,
      countries
    );
  }

  public static saveAdaptersConfiguration(
    adaptersConfiguration: PaymentOnlineInterface[]
  ) {
    const config = {
      ...this.paymentInterface,
      adaptersConfiguration: adaptersConfiguration || [],
    };
    this.saveLocalSettings(keys.PAYMENT_INTERFACE, config);
  }

  public static saveKioskTransactionCode(data: TransactionCode) {
    const updatedInterface = {
      ...this.paymentInterface,
      transactionCode: data.code,
    };
    this.saveLocalSettings(keys.PAYMENT_INTERFACE, updatedInterface || {});
  }

  public static saveTransactionCodes(codes: TransactionCode[]) {
    this.saveLocalSettings(keys.TRANSACTION_CODES, codes, {
      saveActiveOnly: false,
      attributes: ['id', 'code', 'description'],
    });
  }

  public static saveCreditCardAuthorizationRules(
    languageCode: string,
    rules: Dictionary[]
  ) {
    this.saveLocalSettings(
      `${keys.CREDIT_CARD_AUTHORIZATION_RULES}.${languageCode}`,
      rules,
      { attributes: ['code', 'name'] }
    );
  }

  public static saveBillingTransactionCodes(codes: TransactionCode[]) {
    const billingTransactionCodes = codes.filter((code) =>
      isDefined(code.isNonRevenue)
    );
    this.saveLocalSettings(
      keys.BILLING_TRANSACTION_CODES,
      billingTransactionCodes,
      {
        saveActiveOnly: false,
        attributes: [
          'postingValueRange',
          'id',
          'code',
          'description',
          'defaultPostingValue',
        ],
      }
    );
  }

  public static saveMembershipLevels(membershipLevels: MembershipLevel[]) {
    this.saveLocalSettings(keys.MEMBERSHIP_LEVELS, membershipLevels, {
      saveActiveOnly: true,
      attributes: ['id', 'code', 'membershipTypeId', 'description'],
    });
  }

  public static saveDefaultFolioStyle(folioStyle: FolioStyleType) {
    this.saveLocalSettings(keys.DEFAULT_FOLIO_STYLE, folioStyle, {
      saveActiveOnly: true,
      attributes: ['id', 'code'],
    });
  }

  public static saveFolioStyles(folioStyles: FolioStyleType[]) {
    this.saveLocalSettings(keys.FOLIO_STYLES, folioStyles, {
      saveActiveOnly: true,
    });
  }

  public static saveMembershipTypes(membershipTypes: MembershipType[]) {
    this.saveLocalSettings(keys.MEMBERSHIP_TYPES, membershipTypes, {
      saveActiveOnly: true,
      attributes: ['id', 'code', 'description'],
    });
  }

  public static saveRoomAttributes(roomAttributes: TranslatedEntity[]) {
    this.saveLocalSettings(keys.ROOM_ATTRIBUTES, roomAttributes, {
      saveActiveOnly: true,
      attributes: ['id', 'description'],
    });
  }

  public static saveTitles(titles: ParsedKioskDictionaryEntryDetails[]) {
    this.saveLocalSettings(keys.TITLES, titles, { saveActiveOnly: true });
  }

  public static saveTaxRules(taxRules: TaxRule[]) {
    this.saveLocalSettings(keys.TAX_RULES, taxRules, {
      saveActiveOnly: true,
      attributes: ['active', 'forceToUse', 'code', 'id', 'description'],
    });
  }

  public static savePurposesOfStay(
    purposes: ParsedKioskDictionaryEntryDetails[]
  ) {
    this.saveLocalSettings(keys.PURPOSES_OF_STAY, purposes, {
      saveActiveOnly: true,
    });
  }

  public static saveDocumentTypes(
    documentTypes: ParsedKioskDictionaryEntryDetails[]
  ) {
    this.saveLocalSettings(keys.DOCUMENT_TYPES, documentTypes, {
      saveActiveOnly: true,
    });
  }

  public static saveProfileTypes(profileTypes: ProfileType[]) {
    this.saveLocalSettings(keys.PROFILE_TYPES, profileTypes, {
      saveActiveOnly: true,
      attributes: ['code', 'role', 'description'],
    });
  }

  public static savePurchaseElements(purchaseElements: PurchaseElement[]) {
    this.saveLocalSettings(keys.PURCHASE_ELEMENTS, purchaseElements, {
      saveActiveOnly: false,
    });
  }

  public static savePropertyFloors(propertyFloors: PropertyFloor[]) {
    this.saveLocalSettings(keys.PROPERTY_FLOORS, propertyFloors, {
      saveActiveOnly: true,
      attributes: ['id', 'description'],
    });
  }

  public static saveRoomLocations(roomLocations: RoomLocation[]) {
    this.saveLocalSettings(keys.ROOM_LOCATIONS, roomLocations, {
      saveActiveOnly: true,
      attributes: ['id', 'code', 'description'],
    });
  }

  public static saveConsents(consents: ParsedKioskConfigurationConsent[]) {
    this.saveLocalSettings(keys.CONSENTS, consents, {
      saveActiveOnly: true,
    });
  }

  public static saveAddressTypes(
    addressTypes: ParsedKioskDictionaryEntryDetails[]
  ) {
    this.saveLocalSettings(keys.ADDRESS_TYPES, addressTypes, {
      saveActiveOnly: true,
    });
  }

  public static saveGenders(genders: ParsedBaseKioskConfigurationEntity[]) {
    this.saveLocalSettings(keys.GENDERS, genders);
  }

  public static saveMobileTypes(mobileTypes: ParsedKioskCommunicationType[]) {
    this.saveLocalSettings(keys.MOBILE_TYPES, mobileTypes, {
      saveActiveOnly: true,
    });
  }
  public static saveEmailTypes(emailTypes: ParsedKioskCommunicationType[]) {
    this.saveLocalSettings(keys.EMAIL_TYPES, emailTypes, {
      saveActiveOnly: true,
    });
  }

  public static saveProperty(settings: KioskConfigurationProperty) {
    this.saveLocalSettings(keys.PROPERTY, settings);
  }

  public static savePropertyBusinessDate(value: string) {
    const propertySettings = this.propertySettings;
    const updatedSettings = {
      ...propertySettings,
      businessDate: DateManager.getDateFromString(value),
    };
    this.saveLocalSettings(keys.PROPERTY, updatedSettings);
  }

  private static findDescription(
    descriptions: TranslatedDescription[],
    language: string
  ) {
    return descriptions.find(
      (description) =>
        description.languageCode.toLowerCase() === language.toLowerCase()
    );
  }

  private static getLocalSettings(key: string, defaultValue: any) {
    const settings = JSON.parse(localStorage.getItem(key) || 'null');

    return settings || defaultValue;
  }

  private static saveLocalSettings<TType extends { [key: string]: any }>(
    key: string,
    settings: TType | TType[],
    options: {
      attributes?: Array<keyof TType & string>;
      saveActiveOnly?: boolean;
    } = {}
  ) {
    const { attributes, saveActiveOnly } = options;
    const filteredSettings =
      saveActiveOnly && Array.isArray(settings)
        ? getActiveSettings(settings)
        : settings;
    const mappedSettings =
      attributes && Array.isArray(filteredSettings)
        ? filteredSettings.map((setting: TType) =>
            pickAttributes(setting, attributes)
          )
        : filteredSettings;

    try {
      localStorage.setItem(key, JSON.stringify(mappedSettings));
    } catch {
      // eslint-disable-next-line no-useless-return
      return;
    }
  }
}

export default Configurator;
