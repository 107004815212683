import { PrivateRoute } from 'components';
import { allSteps } from 'utils/Router';

import CheckInAddons from './CheckInAddons';
import CheckInAuth from './CheckInAuth';
import CheckInCommunicationDetails from './CheckInCommunicationDetails';
import CheckInComplete from './CheckInComplete';
import CheckInConfirmation from './CheckInConfirmation';
import CheckInID from './CheckInID';
import CheckInIDScanning from './CheckInIDScanning';
import CheckInMultiRoom from './CheckInMultiRoom';
import CheckInNameDetails from './CheckInNameDetails';
import CheckInPayment from './CheckInPayment';
import CheckInPreAuthorization from './CheckInPreAuthorization';
import CheckInPrepayment from './CheckInPrepayment';
import CheckInRoom from './CheckInRoom';
import CheckInSummary from './CheckInSummary';
import CheckOutAuth from './CheckOutAuth';
import CheckOutCharges from './CheckOutCharges';
import CheckOutComplete from './CheckOutComplete';
import CheckOutPayment from './CheckOutPayment';
import CheckOutReservations from './CheckOutReservations';
import GenerateKeys from './GenerateKeys';

export const LegacyRoutes = (): JSX.Element => {
  return (
    <>
      {/* Check-in steps */}
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.AUTH.url}
        component={CheckInAuth}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.MULTIROOM.url}
        component={CheckInMultiRoom}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.NAME_DETAILS.url}
        component={CheckInNameDetails}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.ADDONS.url}
        component={CheckInAddons}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.COMMUNICATION_DETAILS.url}
        component={CheckInCommunicationDetails}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.ID_SCANNING.url}
        component={CheckInIDScanning}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.ID.url}
        component={CheckInID}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.ROOM.url}
        component={CheckInRoom}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.PAYMENT.url}
        component={CheckInPayment}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.PREPAYMENT.url}
        component={CheckInPrepayment}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.PRE_AUTHORIZATION.url}
        component={CheckInPreAuthorization}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.NUMBER_OF_KEYS.url}
        component={GenerateKeys}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.CONFIRMATION.url}
        component={CheckInConfirmation}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.SUMMARY.url}
        component={CheckInSummary}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_IN.COMPLETE.url}
        component={CheckInComplete}
      />

      {/* Check-out steps */}
      <PrivateRoute
        exact
        path={allSteps.CHECK_OUT.AUTH.url}
        component={CheckOutAuth}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_OUT.RESERVATIONS.url}
        component={CheckOutReservations}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_OUT.PAYMENT.url}
        component={CheckOutPayment}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_OUT.CHARGES.url}
        component={CheckOutCharges}
      />
      <PrivateRoute
        exact
        path={allSteps.CHECK_OUT.COMPLETE.url}
        component={CheckOutComplete}
      />
    </>
  );
};
