import {
  getRequester,
  postRequester,
} from '@ac/library-api/dist/api/requester';

import {
  MakeKeysOperationProgressDto,
  MakeKeysReservationDetailsDto,
  MakeKeysStartProcessDto,
  OperationIdPathParams,
} from '../entries';

const path = {
  startProcess: '/kiosk/v0/self-service/make-keys-process/start',
  finishProcess: '/kiosk/v0/self-service/make-keys-process/finish',
  reservationDetails: '/kiosk/v0/self-service/make-keys-process/reservation',
  createKey: '/kiosk/v0/self-service/make-keys-process/create-key',
  duplicateKey: '/kiosk/v0/self-service/make-keys-process/duplicate-key',
  operationStatus:
    '/kiosk/v0/self-service/make-keys-process/operations/{operationId}',
};

export const ApiCacheKeyMakeKeysReservationDetails =
  'ApiCacheKeyMakeKeysReservationDetails';
export const ApiCacheKeyMakeKeysOperationStatus =
  'ApiCacheKeyMakeKeysOperationStatus';

export const startProcess = postRequester<undefined, MakeKeysStartProcessDto>(
  path.startProcess
);

export const finishProcess = postRequester(path.finishProcess);

export const getReservationDetails = getRequester<
  undefined,
  MakeKeysReservationDetailsDto
>({
  path: path.reservationDetails,
  cacheKey: ApiCacheKeyMakeKeysReservationDetails,
});

export const createKey = postRequester(path.createKey);

export const duplicateKey = postRequester(path.duplicateKey);

export const operationStatus = getRequester<
  OperationIdPathParams,
  MakeKeysOperationProgressDto
>({
  path: path.operationStatus,
  cacheKey: ApiCacheKeyMakeKeysOperationStatus,
});
