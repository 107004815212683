import React, { createRef, PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import TextLoop from 'react-text-loop';
import classNames from 'classnames';
import { compose } from 'redux';
import { getScreenSaverMessages } from 'store/ui/selectors';
import { TranslatedDescription } from 'types/Api/Shared';
import State from 'types/Store';
import { Configurator } from 'utils';

import { DEFAULT_APP_LANGUAGE } from '@gss/configs/constants';
import { DEFAULT_NAMESPACE } from '@gss/i18n';
import { getExternalSettings } from '@gss/store/settings/selectors';
import { Fade, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './ScreenSaver.style';

const FADE_IN_DURATION = 1000;
const TEXT_LOOP_INTERVAL = 10 * 1000;

interface PassedProps {
  dataTestSelector?: string;
  className?: string;
}

interface ScreenSaverProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {
  screenSaverMessages: TranslatedDescription[];
  externalSettings: ReturnType<typeof getExternalSettings>;
}

class ScreenSaver extends PureComponent<ScreenSaverProps> {
  public static defaultProps = {
    screenSaverMessages: [],
  };

  public textLoop = createRef<TextLoop>();

  public render() {
    const { classes, className, t, externalSettings, dataTestSelector } =
      this.props;
    const supportedLanguageCodes =
      externalSettings?.LANGUAGE.supportedLanguages;
    const { SHOW_SCREENSAVER_MESSAGE } = Configurator.switchCodes;
    const showMessage = Configurator.getSwitch(SHOW_SCREENSAVER_MESSAGE);

    return (
      <Fade in timeout={FADE_IN_DURATION}>
        <div
          className={classNames(classes.wrapper, className)}
          data-test-selector={dataTestSelector}
        >
          <div className={classes.textWrapper}>
            {showMessage && (
              <TextLoop
                className={classes.text}
                interval={TEXT_LOOP_INTERVAL}
                noWrap={false}
                ref={this.textLoop}
              >
                {supportedLanguageCodes?.map(this.getText)}
              </TextLoop>
            )}
            <Typography className={classes.subtitle}>
              {`${t('PROPERTY')}: ${Configurator.propertySettings.name}`}
            </Typography>
          </div>
          {this.renderBackgroundImage()}
        </div>
      </Fade>
    );
  }

  public componentDidUpdate(prevProps: ScreenSaverProps) {
    const { screenSaverMessages } = this.props;
    const haveMessagesChanged = !this.areMessagesSame(
      screenSaverMessages,
      prevProps.screenSaverMessages
    );
    if (haveMessagesChanged) {
      this.refreshTextLoop();
    }
  }

  private refreshTextLoop = () => this.textLoop.current!.tick();

  private getText = (language: string) => {
    const { i18n, screenSaverMessages } = this.props;
    const message = screenSaverMessages.find(
      (message) => message.languageCode.toLowerCase() === language.toLowerCase()
    );
    const text =
      (message ? message.content : null) ||
      i18n.getResource(language, DEFAULT_NAMESPACE, 'TOUCH_TO_START') ||
      i18n.getResource(
        DEFAULT_APP_LANGUAGE,
        DEFAULT_NAMESPACE,
        'TOUCH_TO_START'
      );

    return (
      <Typography key={`text-${language}`} align="center">
        {text}
      </Typography>
    );
  };

  private renderBackgroundImage = () => {
    const { classes } = this.props;
    const { screenSaverImage } = Configurator;
    if (!screenSaverImage) return null;

    return (
      <div
        className={classes.image}
        style={{ backgroundImage: `url(${screenSaverImage})` }}
      />
    );
  };

  private areMessagesSame = (
    array1: TranslatedDescription[],
    array2: TranslatedDescription[]
  ) =>
    array1.length === array2.length &&
    array1.every(
      (value, i) =>
        value.languageCode?.toUpperCase() ===
          array2[i].languageCode?.toUpperCase() &&
        value.content === array2[i].content
    );
}

const mapStateToProps = (state: State) => ({
  screenSaverMessages: getScreenSaverMessages(state),
  externalSettings: getExternalSettings(state),
});

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(ScreenSaver) as (props: PassedProps) => JSX.Element;
