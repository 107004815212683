import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { LoadingView } from '@ac/kiosk-components';

import { RouteName, routes } from '@gss/router';
import { Authorizer } from '@gss/services';
import {
  prepareApplication,
  setIsKioskProEnv,
} from '@gss/store/configuration/actions';
import { getIsAppInitialized } from '@gss/store/configuration/selectors';
import { handleKioskProDevice } from '@gss/utils';
import { LegacyRoutes } from '@LEGACY/views';

import { stepsBinds } from './stepsBinds';

const routesNames = Object.keys(routes) as RouteName[];

export const App = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAppInitialized = useSelector(getIsAppInitialized);

  const [isAppAuthorized, setIsAppAuthorized] = useState(false);

  useEffect(() => {
    Authorizer.init(() => {
      if (!isAppAuthorized) {
        history.replace(window.location.pathname);
        dispatch(prepareApplication.trigger());
        setIsAppAuthorized(true);
        handleKioskProDevice(() => {
          dispatch(setIsKioskProEnv(true));
        });
      }
    });

    Authorizer.authorize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isAppAuthorized || !isAppInitialized ? (
    <LoadingView description={t('SHARED.AUTHORIZING')} />
  ) : (
    <Switch>
      {routesNames.map((routeName, index) => (
        <Route
          key={index}
          path={routes[routeName].path}
          component={stepsBinds[routeName]}
          exact
        />
      ))}
      <LegacyRoutes />
      <Redirect to={routes.LOGIN.path} />
    </Switch>
  );
};
