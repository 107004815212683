import {
  ApiOperationDto,
  UpdateReservationOperationDto,
} from '@ac/library-api';

const BEYOND_LIMIT_CODE = 'ItemIsBeyondLimit';

export const isUpdatePEOperationBeyondLimit = (
  response: ApiOperationDto<UpdateReservationOperationDto>
) => {
  return !!response.data?.data?.error?.details?.find(({ code }) => {
    return code === BEYOND_LIMIT_CODE;
  });
};
