import Big from 'big.js';
import { CallApiResponseAction } from 'store/middleware/api';
import {
  disableFetching,
  failure,
  request,
  success,
} from 'store/utils/reducer';
import { State } from 'types/Store/ReservationStore';
import { Api, Configurator } from 'utils';

import {
  getOperationId,
  mergePreAddedPurchasesWithChanges,
  parseElementsFromRate,
  parsePreAddedElements,
  parseReservationData,
  sumPrices,
} from './helpers';
import types from './types';
import { isUpdatePEOperationBeyondLimit } from './utils';

const { TOO_MUCH_RESERVATIONS_ERROR_CODE } = Configurator.checkInErrorCodes;

const initialState: State = {
  isCheckedIn: false,
  isPEChangesBeyondLimit: false,
  dataFetching: {
    reservation: false,
    postCheckInLetter: false,
    sharingSummary: false,
    purposeOfStay: false,
    purchases: false,
    room: false,
    keys: false,
    operationStatus: false,
    checkIn: false,
    profileUpdate: false,
    mooltiRoomSegment: false,
  },
  tooMuchReservations: false,
  multiRoomWithNoConfirmationProvided: false,
  addonsSum: 0,
  cutKey: {
    id: '',
    status: '',
  },
  duplicateKey: {
    id: '',
    status: '',
  },
  reservations: [
    {
      createdAtUtc: '',
      bookingTimestamp: '',
      roomTypeToChargeId: '',
      guaranteeTypeId: '',
      channelId: '',
      sourceId: '',
      bookingDate: '',
      arrivalDate: '',
      departureDate: '',
      eta: '',
      id: '',
      profileId: '',
      accountId: '',
      roomTypeId: '',
      roomId: '',
      confirmationNumber: '',
      version: 0,
      isRoomFixed: false,
      projectedRevenue: {
        currency: '',
        amount: 0,
      },
      projectedRevenueAmount: {
        gross: 0,
        net: 0,
        currency: '',
        basePriceType: '',
        isConfidential: false,
      },
      statusCode: {
        code: '',
      },
      isEtaEtdGuaranteed: false,
    },
  ],
  breakdown: [
    {
      fromDate: '',
      toDate: '',
      guests: [
        {
          ageBucketId: '',
          count: 0,
        },
      ],
      marketSegmentId: '',
      amountPerDay: {
        gross: 0,
        net: 0,
        currency: '',
        basePriceType: '',
      },
      pricePerDay: {
        gross: 0,
        net: 0,
        currency: '',
        basePriceType: '',
      },
      ratePricePerDay: {
        gross: 0,
        net: 0,
        currency: '',
        basePriceType: '',
      },
      ratePricingDetails: {
        roomTypeToChargeId: '',
        ratePlanId: '',
        promotionId: '',
        isConfidential: false,
        memberRateId: '',
        isOfflineOffer: false,
      },
    },
  ],
  suggestedRoom: {
    roomId: '',
    roomCode: '',
    roomDescription: '',
  },
  errors: [],
  assignRoomOperation: {
    id: '',
    status: '',
  },
  reservationDetails: {
    purposeOfStayId: '',
  },
  numberOfTries: 0,
  projectedRevenueAmount: {
    gross: 0,
    net: 0,
    currency: '',
    basePriceType: '',
    isConfidential: false,
  },
  accountId: '',
  linkedProfiles: {
    linkedTravelAgent: {
      id: '',
    },
    linkedCompany: {
      id: '',
    },
    guestContactProfiles: {
      primaryContact: {
        id: '',
        contactProfileRole: [''],
      },
    },
  },
  reservationExtendedBreakdown: [],
  purchasesElementsFromRate: [],
  preAddedPurchaseElements: [],
  preAddedInventoryItems: [],
  sharingSummary: {
    id: '',
    version: 0,
    roomTypeId: '',
    roomId: '',
    reservationIds: [],
  },
  multiRoomSegment: {
    confirmationNumber: '',
    externalIds: [],
    id: '',
    reservationIds: [],
    version: 0,
  },
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.ASSIGN_ROOM_REQUEST:
      return request(state, 'room');
    case types.ASSIGN_ROOM_SUCCESS: {
      const { location } = action.response.headers;

      return {
        ...success(state, 'room'),
        assignRoomOperation: {
          id: getOperationId(location),
        },
      };
    }
    case types.ASSIGN_ROOM_FAILURE:
      return failure(state, action, 'room');

    case types.UPDATE_PURPOSE_OF_STAY_REQUEST:
      return request(state, 'purposeOfStay');
    case types.UPDATE_PURPOSE_OF_STAY_SUCCESS:
      return success(state, 'purposeOfStay');
    case types.UPDATE_PURPOSE_OF_STAY_FAILURE:
      return failure(state, action, 'purposeOfStay');

    case types.FETCH_RESERVATION_DETAILS_REQUEST:
      return request(state, 'reservation');
    case types.FETCH_RESERVATION_DETAILS_SUCCESS: {
      const { data } = action.response;

      return {
        ...success(state, 'reservation'),
        reservationDetails: data,
      };
    }
    case types.FETCH_RESERVATION_DETAILS_FAILURE:
      return failure(state, action, 'reservation');

    case types.FETCH_RESERVATION_EXTENDED_REQUEST:
      return request(state, 'reservation');
    case types.FETCH_RESERVATION_EXTENDED_SUCCESS: {
      const { data } = action.response;
      const { results: reservationExtendedBreakdown } =
        data._embedded.breakdown;
      const { results: preAddedPurchaseElements } =
        data._embedded.purchaseElements;
      const { results: inventoryItems } = data._embedded.inventoryItems;

      return {
        ...success(state, 'reservation'),
        reservationExtendedBreakdown,
        preAddedInventoryItems: inventoryItems,
        preAddedPurchaseElements: parsePreAddedElements(
          preAddedPurchaseElements
        ),
        purchasesElementsFromRate: parseElementsFromRate(
          reservationExtendedBreakdown
        ),
        addonsSum: sumPrices(preAddedPurchaseElements),
        ...parseReservationData(data),
      };
    }
    case types.FETCH_RESERVATION_EXTENDED_FAILURE:
      return failure(state, action, 'reservation');

    case types.FETCH_RESERVATION_PURCHASES_REQUEST:
      return request(state, 'purchases');
    case types.FETCH_RESERVATION_PURCHASES_SUCCESS: {
      const { results: preBoughtPurchases } = action.response.data;

      return {
        ...success(state, 'purchases'),
        preAddedPurchaseElements: parsePreAddedElements(preBoughtPurchases),
        addonsSum: sumPrices(preBoughtPurchases),
      };
    }
    case types.FETCH_RESERVATION_PURCHASES_FAILURE:
      return failure(state, action, 'purchases');

    case types.UPDATE_RESERVATION_PURCHASES_REQUEST:
      return request(state, 'purchases');
    case types.UPDATE_RESERVATION_PURCHASES_SUCCESS: {
      const {
        reservations: [reservation],
        addonsSum: currentSum,
        projectedRevenueAmount,
        projectedRevenueAmount: { gross },
        preAddedPurchaseElements,
      } = state;
      const {
        payload: { confirmedNewPurchaseOrders, changes },
      } = action;
      const { modified, removed } = changes;

      const allAddedAddons = mergePreAddedPurchasesWithChanges(
        preAddedPurchaseElements,
        confirmedNewPurchaseOrders,
        modified,
        removed
      );

      const newAddonsSum = sumPrices(allAddedAddons);
      const addonSum = new Big(newAddonsSum).minus(currentSum).toNumber();

      const {
        projectedRevenue,
        projectedRevenue: { amount },
      }: any = reservation;
      const updatedReservation = {
        ...reservation,
        projectedRevenue: {
          ...projectedRevenue,
          amount: new Big(amount).plus(addonSum).toNumber(),
        },
      };
      const priceResult = new Big(gross).plus(addonSum).toNumber();
      const updatedProjectedRevenueAmount = {
        ...projectedRevenueAmount,
        gross: priceResult,
      };

      return {
        ...success(state, 'purchases'),
        addonsSum: new Big(currentSum).plus(addonSum).toNumber(),
        reservations: [updatedReservation],
        projectedRevenueAmount: updatedProjectedRevenueAmount,
      };
    }
    case types.UPDATE_RESERVATION_PURCHASES_FAILURE: {
      const isPEChangesBeyondLimit =
        action.payload &&
        typeof action.payload === 'object' &&
        !(action.payload instanceof Error) &&
        isUpdatePEOperationBeyondLimit(action.payload);

      return {
        ...state,
        isPEChangesBeyondLimit,
        dataFetching: disableFetching(state.dataFetching, 'purchases'),
        errors: isPEChangesBeyondLimit
          ? state.errors
          : [...state.errors, action.payload],
      };
    }

    case types.RESET_UPDATE_PURCHASE_ELEMENT_LIMIT_STATUS: {
      return {
        ...state,
        isPEChangesBeyondLimit: false,
      };
    }

    case types.UPDATE_RESERVATION_PROFILE_REQUEST:
      return request(state, 'profileUpdate');
    case types.UPDATE_RESERVATION_PROFILE_SUCCESS:
      return success(state, 'profileUpdate');
    case types.UPDATE_RESERVATION_PROFILE_FAILURE:
      return failure(state, action, 'profileUpdate');

    case types.GET_ASSIGN_ROOM_STATUS_REQUEST:
      return request(state, 'operationStatus');
    case types.GET_ASSIGN_ROOM_STATUS_SUCCESS: {
      const {
        data: { result, error },
      } = action.response.data;
      const { assignRoomOperation, errors } = state;
      const statusErrors = error ? error.details : [];

      return {
        ...success(state, 'operationStatus'),
        assignRoomOperation: {
          ...assignRoomOperation,
          status: result,
        },
        errors: [...errors, ...statusErrors],
      };
    }
    case types.GET_ASSIGN_ROOM_STATUS_FAILURE:
      return failure(state, action, 'operationStatus');

    case types.FETCH_RESERVATION_REQUEST:
      return request(state, 'reservation');
    case types.FETCH_RESERVATION_SUCCESS: {
      const { data } = action.response;

      return {
        ...success(state, 'reservation'),
        ...parseReservationData(data),
      };
    }
    case types.FETCH_RESERVATION_FAILURE:
      return failure(state, action, 'reservation');

    case types.FETCH_MULTIROOM_RESERVATION_LIST_REQUEST: {
      return request(state, 'reservation');
    }
    case types.FETCH_MULTIROOM_RESERVATION_LIST_SUCCESS: {
      const { results: reservations } = action.response.data;

      return {
        ...success(state, 'reservation'),
        reservations,
      };
    }
    case types.FETCH_MULTIROOM_RESERVATION_LIST_FAILURE: {
      return failure(state, action, 'reservation');
    }

    case types.FETCH_RESERVATION_CHECK_IN_REQUEST:
      return {
        ...request(state, 'reservation'),
        tooMuchReservations: false,
        multiRoomWithNoConfirmationProvided: false,
      };
    case types.FETCH_RESERVATION_CHECK_IN_SUCCESS: {
      const { data } = action.response;

      return {
        ...success(state, 'reservation'),
        reservations: data.results,
      };
    }
    case types.FETCH_RESERVATION_CHECK_IN_FAILURE: {
      const {
        response,
        response: { data },
        payload: { identificationNumber },
      } = action;
      const { errors, numberOfTries } = state;
      const {
        TOO_MUCH_RESERVATIONS_ERROR_CODE,
        MULTIROOM_RESERVATION_NEEDS_CONFIRMATION_NO,
      } = Configurator.checkInErrorCodes;

      const multiRoomWithNoConfirmationProvided =
        response.message === MULTIROOM_RESERVATION_NEEDS_CONFIRMATION_NO;

      const tooMuchReservations =
        !multiRoomWithNoConfirmationProvided &&
        data?.details?.length &&
        !identificationNumber &&
        numberOfTries < 3 &&
        data?.details[0].code === TOO_MUCH_RESERVATIONS_ERROR_CODE;

      return {
        ...failure(state, action, 'reservation'),
        multiRoomWithNoConfirmationProvided,
        tooMuchReservations,
        errors:
          multiRoomWithNoConfirmationProvided || tooMuchReservations
            ? errors
            : Api.getErrors(action.response, errors),
        numberOfTries: tooMuchReservations ? numberOfTries + 1 : numberOfTries,
      };
    }

    case types.FETCH_RESERVATION_CHECK_OUT_REQUEST:
      return request(state, 'reservation');
    case types.FETCH_RESERVATION_CHECK_OUT_SUCCESS: {
      const { results } = action.response.data;

      return {
        ...success(state, 'reservation'),
        reservations: results,
      };
    }
    case types.FETCH_RESERVATION_CHECK_OUT_FAILURE:
      return failure(state, action, 'reservation');

    case types.FETCH_BREAKDOWN_REQUEST:
      return request(state, 'reservation');
    case types.FETCH_BREAKDOWN_SUCCESS: {
      const { data } = action.response;
      const breakdown = data.results;

      return {
        ...success(state, 'reservation'),
        breakdown,
      };
    }
    case types.FETCH_BREAKDOWN_FAILURE:
      return failure(state, action, 'reservation');

    case types.FETCH_SHARING_SUMMARY_REQUEST:
      return request(state, 'sharingSummary');
    case types.FETCH_SHARING_SUMMARY_SUCCESS: {
      const { data: sharingSummary } = action.response;

      return {
        ...success(state, 'sharingSummary'),
        sharingSummary,
      };
    }
    case types.FETCH_SHARING_SUMMARY_FAILURE:
      return failure(state, action, 'sharingSummary');

    case types.FETCH_SUGGESTED_NUMBER_REQUEST:
      return request(state, 'room');
    case types.FETCH_SUGGESTED_NUMBER_SUCCESS: {
      const { data: suggestedRoom } = action.response;

      return {
        ...success(state, 'room'),
        suggestedRoom,
      };
    }
    case types.FETCH_SUGGESTED_NUMBER_FAILURE:
      return failure(state, action, 'room');

    case types.CHECK_IN_REQUEST:
      return request(state, 'checkIn');
    case types.CHECK_IN_SUCCESS:
      return {
        ...success(state, 'checkIn'),
        isCheckedIn: true,
      };
    case types.CHECK_IN_FAILURE:
      return failure(state, action, 'checkIn');

    case types.POST_CHECK_IN_LETTER_REQUEST:
      return request(state, 'postCheckInLetter');
    case types.POST_CHECK_IN_LETTER_SUCCESS:
      return success(state, 'postCheckInLetter');
    case types.POST_CHECK_IN_LETTER_FAILURE:
      return failure(state, action, 'postCheckInLetter');

    case types.CUT_KEY_REQUEST:
      return {
        ...request(state, 'keys'),
        cutKey: {
          id: '',
          status: '',
        },
      };
    case types.CUT_KEY_SUCCESS: {
      const { location } = action.response.headers;

      return {
        ...success(state, 'keys'),
        cutKey: {
          id: getOperationId(location),
        },
      };
    }
    case types.CUT_KEY_FAILURE:
      return failure(state, action, 'keys');

    case types.DUPLICATE_KEY_REQUEST:
      return {
        ...request(state, 'keys'),
        duplicateKey: {
          id: '',
          status: '',
        },
      };
    case types.DUPLICATE_KEY_SUCCESS: {
      const { location } = action.response.headers;

      return {
        ...success(state, 'keys'),
        duplicateKey: {
          id: getOperationId(location),
        },
      };
    }
    case types.DUPLICATE_KEY_FAILURE:
      return failure(state, action, 'keys');

    case types.GET_CUT_KEY_STATUS_REQUEST:
      return request(state, 'operationStatus');
    case types.GET_CUT_KEY_STATUS_SUCCESS: {
      const {
        data: { result, error },
      } = action.response.data;
      const { cutKey } = state;
      const { errors } = state;
      const statusErrors = error ? error.details : [];

      return {
        ...success(state, 'operationStatus'),
        cutKey: {
          ...cutKey,
          status: result,
        },
        errors: [...errors, ...statusErrors],
      };
    }
    case types.GET_CUT_KEY_STATUS_FAILURE:
      return failure(state, action, 'operationStatus');

    case types.GET_DUPLICATE_KEY_STATUS_REQUEST:
      return request(state, 'operationStatus');
    case types.GET_DUPLICATE_KEY_STATUS_SUCCESS: {
      const {
        data: { result, error },
      } = action.response.data;
      const { duplicateKey } = state;
      const { errors } = state;
      const statusErrors = error ? error.details : [];

      return {
        ...success(state, 'operationStatus'),
        duplicateKey: {
          ...duplicateKey,
          status: result,
        },
        errors: [...errors, ...statusErrors],
      };
    }
    case types.GET_DUPLICATE_KEY_STATUS_FAILURE:
      return failure(state, action, 'operationStatus');

    case types.CLEAR_RESERVATION_ERRORS:
      return {
        ...state,
        errors: [],
      };

    case types.CHOOSE_RESERVATION: {
      const { payload: id } = action;
      const [reservation] = state.reservations.filter(
        (reservation) => reservation.id === id
      );

      return {
        ...state,
        ...parseReservationData(reservation),
      };
    }

    case types.FETCH_RESERVATION_GENERATE_KEYS_REQUEST:
      return {
        ...request(state, 'reservation'),
        tooMuchReservations: false,
      };
    case types.FETCH_RESERVATION_GENERATE_KEYS_SUCCESS: {
      const { data } = action.response;

      return {
        ...success(state, 'reservation'),
        reservations: [data],
      };
    }
    case types.FETCH_RESERVATION_GENERATE_KEYS_FAILURE: {
      const {
        data: { details },
      } = action.response;
      const { errors, numberOfTries } = state;

      const tooMuchReservations =
        details &&
        details.length &&
        numberOfTries < 2 &&
        details[0].code === TOO_MUCH_RESERVATIONS_ERROR_CODE;

      return {
        ...failure(state, action, 'reservation'),
        tooMuchReservations,
        errors: tooMuchReservations
          ? errors
          : Api.getErrors(action.response, errors),
        numberOfTries: tooMuchReservations ? numberOfTries + 1 : numberOfTries,
      };
    }

    case types.FETCH_MULTIROOM_SEGMENT_REQUEST:
      return {
        ...state,
        ...request(state, 'mooltiRoomSegment'),
      };

    case types.FETCH_MULTIROOM_SEGMENT_SUCCESS:
      return {
        ...state,
        ...success(state, 'mooltiRoomSegment'),
        multiRoomSegment: action.response.data,
      };

    case types.FETCH_MULTIROOM_SEGMENT_FAILURE:
      return {
        ...state,
        ...failure(state, action, 'mooltiRoomSegment'),
      };

    default:
      return state;
  }
};
