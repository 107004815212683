import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import backend from 'i18next-http-backend';
import moment from 'moment';

import { KioskComponentsConfigurator } from '@ac/kiosk-components';
import { setUpTranslationsForStandaloneApplication } from '@ac/library-utils/dist/translations/react/standalone';

import { DEFAULT_APP_LANGUAGE } from '@gss/configs/constants';

export const DEFAULT_NAMESPACE = 'translation';

export const initI18nTranslations = (): void => {
  setUpTranslationsForStandaloneApplication({
    dateUtilsLibraryInstance: moment,
    i18n,
    config: {
      plugins: { backend, initReactI18next },
      moduleName: 'guestSelfService',
      repositoryName: 'frontend-guest-self-service',
      useRemoteTranslations: true,
      initOptions: {
        lng: DEFAULT_APP_LANGUAGE,
        interpolation: { escapeValue: false },
      },
    },
  });
};

export const changeUiLanguage = async (language?: string): Promise<void> => {
  if (!language) return;

  await i18n.changeLanguage(language);
  await KioskComponentsConfigurator.changeLanguage(language);
};
