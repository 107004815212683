import React, { PropsWithChildren } from 'react';
import { Footer, View } from 'components';

import { Header } from '@gss/components/layout';

import './OverlayView.scss';

interface OverlayViewProps {
  title: string;
  hasCancelButton?: boolean;
  cancelButtonLabel?: string;
  onCancelClick?: () => void;
  hasConfirmButton?: boolean;
  confirmButtonLabel?: string;
  onConfirmClick?: () => void;
}

const OverlayView = ({
  title,
  hasCancelButton,
  cancelButtonLabel,
  onCancelClick,
  hasConfirmButton,
  confirmButtonLabel,
  onConfirmClick,
  children,
}: PropsWithChildren<OverlayViewProps>) => {
  return (
    <View className="overlay-view-container" hideCounter>
      <Header className="overlay-view-header" title={title} />
      {children}
      <Footer
        isOnContinuePrior
        className="overlay-view-footer"
        hasBackButton={hasCancelButton}
        backButtonLabel={cancelButtonLabel}
        onGoBack={onCancelClick}
        hasContinueButton={hasConfirmButton}
        continueButtonLabel={confirmButtonLabel}
        onContinue={onConfirmClick}
      />
    </View>
  );
};

export default OverlayView;
