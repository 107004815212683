import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingView } from '@ac/kiosk-components';

import { useRouter } from '@gss/router';
import { prepareSettings } from '@gss/store/settings/actions';
import { getAreSettingsInitialized } from '@gss/store/settings/selectors';

export const Setup = (): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const areSettingsInitialized = useSelector(getAreSettingsInitialized);

  useEffect(() => {
    if (areSettingsInitialized) {
      return router?.goToNextStep();
    } else {
      dispatch(prepareSettings.trigger({ withDefaultLanguage: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areSettingsInitialized]);

  return (
    <LoadingView
      className="with-default-kiosk-components-theme"
      description={t('SHARED.SETTING_UP')}
    />
  );
};
