import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AlignItems,
  BasicModal,
  BasicModalSize,
  Button,
  ButtonPattern,
  Flex,
  JustifyContent,
  Text,
  TextSize,
} from '@ac/kiosk-components';
import { formatProfileName, TitleOrders } from '@ac/library-api';

import { CheckInGuestDto } from '@gss/api/KioskApi/entries';
import { getExternalSettings } from '@gss/store/settings/selectors';

interface Props {
  guest?: CheckInGuestDto;
  onClose?: () => void;
  onChange?: () => void;
  onConfirm?: () => void;
}

export const CheckNameDetailsModal = ({
  guest,
  onClose,
  onChange,
  onConfirm,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const externalSettings = useSelector(getExternalSettings);

  const guestName = useMemo(() => {
    const titleOrder =
      externalSettings?.TITLE_ORDER || TitleOrders.TitleFirstNameLastName;

    return formatProfileName(titleOrder, {
      firstName: guest?.firstName,
      lastName: guest?.lastName,
      title: guest?.title?.description,
      middleName: undefined,
      suffix: undefined,
    });
  }, [guest, externalSettings]);

  return (
    <BasicModal
      size={BasicModalSize.md}
      className="with-default-kiosk-components-theme check-name-details-modal"
    >
      <Text size={TextSize.xlg} className="spacing-top-sm spacing-bottom-lg">
        {t('CHECK_IN_FLOW.COMPONENTS.CHECK_NAME_DETAILS_MODAL.TITLE')}
      </Text>
      <Text className="spacing-left-xlg spacing-right-xlg">
        {t('CHECK_IN_FLOW.COMPONENTS.CHECK_NAME_DETAILS_MODAL.SUBTITLE')}
      </Text>

      <Flex
        className="spacing-vertical-xxlg gap-sm"
        justifyContent={JustifyContent.center}
        alignItems={AlignItems.center}
      >
        <Text className="content-label">
          {`${t(
            'CHECK_IN_FLOW.COMPONENTS.CHECK_NAME_DETAILS_MODAL.GUEST_NAME'
          )}:`}
        </Text>
        <Text size={TextSize.xlg}>{guestName}</Text>
      </Flex>

      <Flex className="gap-sm">
        <Button pattern={ButtonPattern.secondary} onClick={onClose}>
          {t('SHARED.CLOSE')}
        </Button>
        <Button
          pattern={ButtonPattern.secondary}
          onClick={onChange}
          className="spacing-left-auto"
        >
          {t('CHECK_IN_FLOW.COMPONENTS.CHECK_NAME_DETAILS_MODAL.CHANGE')}
        </Button>
        <Button onClick={onConfirm}>{t('SHARED.CONFIRM')}</Button>
      </Flex>
    </BasicModal>
  );
};
