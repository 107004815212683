import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  InactivityModal,
  TestEnvironmentBar,
  View as BasicView,
} from '@ac/kiosk-components';

import {
  FLOW_IDLE_PROMPT_TIMEOUT,
  FLOW_IDLE_TIMEOUT,
  ONE_SECOND,
} from '@gss/configs/timers';
import { getPropertyConfiguration } from '@gss/store/settings/selectors';

import './View.scss';

interface ViewProps extends React.ComponentProps<typeof BasicView> {
  withInactivityCheck?: boolean;
  onInactivity?: () => void;
}

const INACTIVITY_MODAL_COUNTDOWN_IN_SECONDS =
  FLOW_IDLE_PROMPT_TIMEOUT / ONE_SECOND;

export const View = ({
  children,
  className,
  withInactivityCheck = false,
  onInactivity,
  ...props
}: ViewProps): JSX.Element => {
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const [isInactivityModalOpen, setInactivityModalOpen] = useState(false);

  const handleActive = (): void => {
    setInactivityModalOpen(false);
  };

  const handlePrompt = (): void => {
    setInactivityModalOpen(true);
  };

  const handleCountdownEnd = (): void => {
    if (onInactivity) {
      setInactivityModalOpen(false);
      onInactivity();
    }
  };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { activate, start, pause } = useIdleTimer({
    onActive: handleActive,
    onPrompt: handlePrompt,
    timeout: FLOW_IDLE_TIMEOUT + FLOW_IDLE_PROMPT_TIMEOUT,
    promptBeforeIdle: FLOW_IDLE_PROMPT_TIMEOUT,
    startManually: true,
  });

  const handleStillHere = (): void => {
    activate();
  };

  useEffect(() => {
    if (!withInactivityCheck) {
      pause();
    } else {
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withInactivityCheck]);

  return (
    <BasicView
      {...props}
      className={classNames('gss-view-container', className)}
      modalsClassName="gss-view-modals with-default-kiosk-components-theme"
    >
      {isInactivityModalOpen && (
        <InactivityModal
          className="gss-view-modals with-default-kiosk-components-theme"
          countdownFrom={INACTIVITY_MODAL_COUNTDOWN_IN_SECONDS}
          onConfirmButtonClick={handleStillHere}
          onCountdownEnd={handleCountdownEnd}
        />
      )}

      {propertyConfiguration?.nonProduction && (
        <TestEnvironmentBar dataTestSelector="view-test-environment-bar" />
      )}

      {children}
    </BasicView>
  );
};
