export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;

/**
 * 1 minute is a time of device being being marked as online by BE, after keepAlive request being sent.
 * Online status prevents from selecting same device by a few applications.
 * Cookie should be refreshed every time keepAlive request will be sent.
 * This will let us take cached device in case of any emergency application reload
 * and still be sure that device is not used by anyone else.
 */
export const SELF_SERVICE_DEVICE_COOKIE_EXPIRATION_TIME = ONE_MINUTE;

/**
 * Interval for sent keepAlive request, will extend time for how long device will be marked as online.
 * Also to checks connection status.
 */
export const KEEP_DEVICE_ALIVE_INTERVAL = 5 * ONE_SECOND;

/**
 * Service modal prepared for reconfiguration and debug purposes.
 * Should be available only for hotel stuff.
 * Long press timeout should prevent from accidental opening by guest.
 */
export const SHOW_TECHNICAL_MODAL_LONG_PRESS_TIMEOUT = 5 * ONE_SECOND;

/**
 * Reload process of kiosk application is problematic.
 * Keep refreshing device list if user is waiting at configuration step for device to be available.
 */
export const REFRESH_DEVICE_LIST_INTERVAL = ONE_MINUTE;

export const REFRESH_CONFIGURATION_INTERVAL = 24 * ONE_HOUR;

export const SCREEN_SAVER_TIMEOUT = 10 * ONE_MINUTE;

/**
 * Inactivity modal timers
 */
export const FLOW_IDLE_TIMEOUT = ONE_MINUTE;
export const FLOW_IDLE_PROMPT_TIMEOUT = 10 * ONE_SECOND;

/**
 * Redirects to Welcome screen after flow complete screen
 */
export const REDIRECT_TO_WELCOME_TIMEOUT = 10 * ONE_SECOND;
