import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Store from 'types/Store';
import { Configurator } from 'utils';

import {
  Field,
  FieldMode,
  SelectField,
  ValidationHeader,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  createRequiredValidator,
  formNestedFieldFactory,
  FormRenderProps,
  FormSpy,
} from '@ac/react-infrastructure';

import {
  fetchDistricts,
  fetchStates,
} from '@gss/store/lazyLoadedDictionary/actions';
import {
  getDistrictStoreStatus,
  getStateStoreStatus,
} from '@gss/store/lazyLoadedDictionary/selectors';
import { mapSelectOptions } from '@gss/utils';
import { FormValidator, mapFieldRenderProps } from '@gss/utils/form';

import {
  AddressSubFormValues,
  CheckInCommunicationDetailsFormValues,
  CommunicationDetailsFormSection,
} from '../types';

import './AddressSubForm.scss';

interface AddressSubFormProps {
  header?: string;
  formRenderProps: FormRenderProps<CheckInCommunicationDetailsFormValues>;
  nestedPropertyName: CommunicationDetailsFormSection;
  className?: string;
  isDistrictFieldEnabled?: boolean;
}

const FormField =
  formNestedFieldFactory<Record<string, AddressSubFormValues>>();

const AddressSubForm = ({
  className,
  header,
  formRenderProps,
  nestedPropertyName,
  isDistrictFieldEnabled,
}: AddressSubFormProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [selectedCountryCode, setSelectedCountryCode] = useState<
    string | undefined
  >();

  const states = useSelector((state: Store) =>
    getStateStoreStatus(state, selectedCountryCode)
  );

  const districts = useSelector((state: Store) =>
    getDistrictStoreStatus(state, selectedCountryCode)
  );

  useEffect(() => {
    if (selectedCountryCode) {
      dispatch(
        fetchStates.trigger({
          countryCode: selectedCountryCode,
          languageCode: i18n.language,
          skipCache: true,
        })
      );

      if (isDistrictFieldEnabled) {
        dispatch(
          fetchDistricts.trigger({
            countryCode: selectedCountryCode,
            languageCode: i18n.language,
            skipCache: true,
          })
        );
      }
    }
  }, [selectedCountryCode]);

  const validator = useMemo(() => {
    return new FormValidator<Record<string, AddressSubFormValues>>({
      [nestedPropertyName]: {
        addressLine1: createRequiredValidator(t('FIELD_IS_MANDATORY')),
        postCode: createRequiredValidator(t('FIELD_IS_MANDATORY')),
        city: createRequiredValidator(t('FIELD_IS_MANDATORY')),
        countryCode: createRequiredValidator(t('FIELD_IS_MANDATORY')),
      },
    });
  }, [i18n.language]);

  const countryOptions = useMemo(() => {
    const countries = Configurator.getCountries(i18n.language);

    return mapSelectOptions(countries);
  }, [i18n.language]);

  const stateOptions = useMemo(() => {
    return mapSelectOptions(states.result);
  }, [states.result]);

  const districtOptions = useMemo(() => {
    return mapSelectOptions(districts.result, 'description', 'id');
  }, [districts.result]);

  const getSuggestedOptions = useMemo(() => {
    const popCountryList = Configurator.popCountryList;
    if (!popCountryList) {
      return;
    }

    return countryOptions
      ?.filter((element) => popCountryList?.includes(element.value || ''))
      .sort((a, b) => a.title?.localeCompare(b.title || '') || 0)
      .map((element) => element.value)
      .filter(isDefined);
  }, [countryOptions]);

  const handleFormValueChanges = ({
    values,
  }: Record<'values', CheckInCommunicationDetailsFormValues>) => {
    const subFormValues = values?.[nestedPropertyName] as AddressSubFormValues;
    if (!subFormValues) return;
    const countryCode = subFormValues.countryCode;
    if (countryCode !== selectedCountryCode) {
      setSelectedCountryCode(countryCode);
    }
  };

  return (
    <>
      <FormSpy
        subscription={{ values: true }}
        onChange={handleFormValueChanges}
      />

      <div className={classNames('address-form', className)}>
        <ValidationHeader
          isValid={validator.isValid}
          title={header || t('ADDRESS')}
          className="spacing-bottom-lg address-header"
        />

        <FormField
          valuePath={[nestedPropertyName, 'addressLine1']}
          validateFields={[[nestedPropertyName, 'addressLine1'].join('.')]}
          validate={validator.validateSingleField}
        >
          {(fieldRenderProps) => (
            <Field
              {...mapFieldRenderProps(fieldRenderProps)}
              label={t('ADDRESS_LINE_1')}
              placeholder={t('FILL')}
              className="address-addressline"
              dataTestSelector="check-in-contact-address-line-one-field"
            />
          )}
        </FormField>
        <FormField
          valuePath={[nestedPropertyName, 'addressLine2']}
          validateFields={[[nestedPropertyName, 'addressLine2'].join('.')]}
          validate={validator.validateSingleField}
        >
          {(fieldRenderProps) => (
            <Field
              {...mapFieldRenderProps(fieldRenderProps)}
              label={t('ADDRESS_LINE_2')}
              placeholder={t('FILL')}
              className="address-addressline"
              dataTestSelector="check-in-contact-address-line-two-field"
            />
          )}
        </FormField>
        <FormField
          valuePath={[nestedPropertyName, 'postCode']}
          validateFields={[[nestedPropertyName, 'postCode'].join('.')]}
          validate={validator.validateSingleField}
        >
          {(fieldRenderProps) => (
            <Field
              {...mapFieldRenderProps(fieldRenderProps)}
              label={t('CODE')}
              placeholder={t('FILL')}
              className="address-code"
              dataTestSelector="check-in-contact-post-code-field"
              mode={FieldMode.numeric}
            />
          )}
        </FormField>
        <FormField
          valuePath={[nestedPropertyName, 'city']}
          validateFields={[[nestedPropertyName, 'city'].join('.')]}
          validate={validator.validateSingleField}
        >
          {(fieldRenderProps) => (
            <Field
              {...mapFieldRenderProps(fieldRenderProps)}
              label={t('CITY')}
              placeholder={t('FILL')}
              dataTestSelector="check-in-contact-city-field"
              className="address-city"
            />
          )}
        </FormField>
        <FormField
          valuePath={[nestedPropertyName, 'countryCode']}
          validateFields={[[nestedPropertyName, 'countryCode'].join('.')]}
          validate={validator.validateSingleField}
        >
          {(fieldRenderProps) => {
            const mappedFieldRenderProps =
              mapFieldRenderProps(fieldRenderProps);

            return (
              <SelectField
                {...mappedFieldRenderProps}
                label={t('COUNTRY')}
                placeholder={t('SELECT')}
                className="address-country"
                modalClassName="with-default-kiosk-components-theme"
                dataTestSelector="check-in-contact-country-select"
                onChange={(value) => {
                  if (value !== selectedCountryCode) {
                    mappedFieldRenderProps.onChange(value);

                    const stateFieldKey = [
                      nestedPropertyName,
                      'stateCode',
                    ].join('.');
                    const districtFieldKey = [
                      nestedPropertyName,
                      'districtId',
                    ].join('.');

                    formRenderProps.form.change(
                      stateFieldKey as keyof CheckInCommunicationDetailsFormValues,
                      undefined
                    );

                    if (isDistrictFieldEnabled) {
                      formRenderProps.form.change(
                        districtFieldKey as keyof CheckInCommunicationDetailsFormValues,
                        undefined
                      );
                    }
                  }
                }}
                options={countryOptions}
                suggestedOptionValues={getSuggestedOptions}
              />
            );
          }}
        </FormField>
        <FormField
          valuePath={[nestedPropertyName, 'stateCode']}
          validateFields={[[nestedPropertyName, 'stateCode'].join('.')]}
          validate={validator.validateSingleField}
        >
          {(fieldRenderProps) => (
            <SelectField
              {...mapFieldRenderProps(fieldRenderProps)}
              label={t('STATE')}
              placeholder={t('SELECT')}
              className="address-state"
              modalClassName="with-default-kiosk-components-theme"
              options={stateOptions}
              dataTestSelector="check-in-contact-state-select"
              optionsLoading={states.isFetching}
              disabled={!selectedCountryCode}
              allowClear
              optional
            />
          )}
        </FormField>

        {isDistrictFieldEnabled && (
          <FormField
            valuePath={[nestedPropertyName, 'districtId']}
            validateFields={[[nestedPropertyName, 'districtId'].join('.')]}
            validate={validator.validateSingleField}
          >
            {(fieldRenderProps) => (
              <SelectField
                {...mapFieldRenderProps(fieldRenderProps)}
                label={t('SHARED.DISTRICT')}
                placeholder={t('SELECT')}
                className="address-district"
                modalClassName="with-default-kiosk-components-theme"
                options={districtOptions}
                dataTestSelector="check-in-contact-district-select"
                optionsLoading={districts.isFetching}
                disabled={!selectedCountryCode}
                allowClear
                optional
              />
            )}
          </FormField>
        )}
      </div>
    </>
  );
};

export default AddressSubForm;
