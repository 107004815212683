import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Body, FormHeader, View } from 'components';
import { CheckNameDetailsModal } from 'components/modals';
import { compose } from 'redux';
import { fetchCheckInData } from 'store/actions';
import { activeAuthorizations } from 'store/cashiering/authorization/selectors';
import { getReservationOutstandingDeposit } from 'store/cashiering/reservationOutstandingDeposit/selectors';
import {
  getActiveBillingInstructions,
  getAddons,
  getFolios,
  getPreAuthorizationAmount,
} from 'store/cashiering/selectors';
import { fetchAvailableRooms } from 'store/housekeeping/actions';
import { shouldReassignRoom } from 'store/housekeeping/selectors';
import { chooseProfile, fetchProfile } from 'store/profile/actions';
import { getAllProfiles } from 'store/profile/selectors';
import { getLocalPropertyDateTime } from 'store/propertyManagement/selectors';
import {
  assignRoom,
  chooseReservation,
  fetchMultiRoomCheckinReservationList,
} from 'store/reservation/actions';
import {
  getAllReservations,
  getMultiRoomSegment,
  getSuggestedRoom,
  isMultiRoomReservation,
  isRoomFixed,
} from 'store/reservation/selectors';
import { fetchRoomDetails, fetchRoomList } from 'store/room/actions';
import { getIsDataLoading, getRoomList } from 'store/room/selectors';
import { getErrors, isLoading } from 'store/selectors';
import { PurchaseItem } from 'types/Api/Availability';
import {
  BillingInstruction,
  CreditCardAuthorizationDetails,
  Folio,
} from 'types/Api/Cashiering';
import { Profile, ProfileAdditionalNameTypes } from 'types/Api/Profile';
import {
  MultiRoomSegment,
  ReservationView,
  SuggestedRoom,
} from 'types/Api/Reservation';
import { Details } from 'types/Api/Room';
import { ApiError, Money } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator, DateManager, findAdditionalName, Router } from 'utils';
import { Path } from 'utils/Router';
import { configureCheckInRouting } from 'views/CheckInAuth/routing';

import { Header } from '@gss/components/layout';
import { RoomError } from '@gss/utils/errors';
import { Box, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import MultiRoomReservationList from './MultiRoomReservationList/MultiRoomReservationList';
import styles from './CheckInMultiRoom.style';
import { MultiRoomReservationsListDataModel } from './types';

const { CHECK_IN_FAILED_MESSAGE, ROOM_NOT_READY_MESSAGE } =
  Configurator.getTranslationCodes();

interface CheckInMultiRoomProps
  extends RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  reservations: ReservationView[];
  roomList: Details[];
  profileList: Profile[];
  isDataLoading: boolean;
  multiRoomSegment: MultiRoomSegment;
  shouldReassignRoom: boolean;
  isLoading: boolean;
  suggestedRoom: SuggestedRoom;
  errors: ApiError[];
  addons: PurchaseItem[];
  activeAuthorizations: CreditCardAuthorizationDetails[];
  outstandingDeposit: Money;
  localPropertyDateTime: string;
  preAuthorizationAmount: number;
  billingInstructions: BillingInstruction[];
  folios: Folio[];
  isMultiRoomReservation: boolean;
  isRoomFixed: boolean;
  fetchRoomList: typeof fetchRoomList;
  fetchProfile: typeof fetchProfile;
  fetchMultiRoomCheckinReservationList: typeof fetchMultiRoomCheckinReservationList;
  fetchAvailableRooms: typeof fetchAvailableRooms;
  chooseReservation: typeof chooseReservation;
  chooseProfile: typeof chooseProfile;
  assignRoom: typeof assignRoom;
  fetchCheckInData: typeof fetchCheckInData;
  fetchRoomDetails: typeof fetchRoomDetails;
}

interface CheckInMultiRoomState {
  multiRoomReservationDataStructure: MultiRoomReservationsListDataModel[];
  isModalOpen: boolean;
  isLoading: boolean;
  chosenReservationData?: MultiRoomReservationsListDataModel;
  error?: ApiError;
}

class CheckInMultiRoom extends Component<
  CheckInMultiRoomProps,
  CheckInMultiRoomState
> {
  public static defaultProps = {
    reservations: [],
    roomList: [],
    profileList: [],
    isDataLoading: true,
  };

  public state: CheckInMultiRoomState = {
    multiRoomReservationDataStructure: [],
    isModalOpen: false,
    isLoading: false,
    chosenReservationData: undefined,
    error: undefined,
  };

  async componentDidMount() {
    const {
      fetchRoomList,
      fetchProfile,
      fetchMultiRoomCheckinReservationList,
    } = this.props;
    const {
      multiRoomSegment: { reservationIds },
    } = this.props;
    await fetchMultiRoomCheckinReservationList(reservationIds);

    const { reservations } = this.props;
    const roomIds = reservations
      .map((res) => res.roomId)
      .filter((roomId) => roomId) as string[];
    const profileIds = reservations
      .map((res) => res.profileId)
      .reduce((acc, profileId) => {
        if (profileId && !acc.includes(profileId)) {
          acc.push(profileId);
        }

        return acc;
      }, [] as string[]);

    await Promise.all([
      ...profileIds.map((id) => fetchProfile(id)),
      roomIds.length && fetchRoomList(roomIds),
    ]);
    this.prepareMultiRoomReservationsData();
  }

  public render() {
    const {
      t,
      classes,
      errors,
      isDataLoading,
      multiRoomSegment: { confirmationNumber },
    } = this.props;
    const {
      multiRoomReservationDataStructure,
      isModalOpen,
      isLoading,
      chosenReservationData,
      error,
    } = this.state;

    const loading = isDataLoading || isLoading;

    return (
      <View
        hideCounter
        idle={{ type: 'modal' }}
        modal={{
          values: (error && [error]) || errors,
          customErrorCode: this.customErrorCode,
          defaultError: this.getDefaultError(),
          onClick: this.clearLocalErrors,
          shouldClearErrors: true,
          isLoading: loading,
        }}
      >
        <Header title={`${t('CHECK_IN')} - ${t('MULTIROOM_HEADER')}`} />
        <Body>
          <FormHeader
            className={classes.formHeader}
            title={t('MULTIROOM_TITLE')}
            subtitle={t('MULTIROOM_SUBTITLE')}
          />
          <MultiRoomReservationList
            data={multiRoomReservationDataStructure}
            onItemClick={this.onReservationChoose}
          />
          {!isLoading && (
            <Typography variant="subtitle2" component="p" align="right">
              <Box component="span" fontWeight="fontWeightBold">
                {`${t('MR_CONFIRMATION_NO')}: `}
              </Box>
              <Box component="span">{confirmationNumber}</Box>
            </Typography>
          )}
        </Body>

        {isModalOpen && (
          <CheckNameDetailsModal
            firstName={chosenReservationData?.guest.firstName}
            lastName={chosenReservationData?.guest.lastName}
            secondSurname={chosenReservationData?.guest?.secondSurname}
            onClose={this.onToggleModal}
            onChange={this.onChangeNameDetails}
            onConfirm={this.onConfirmNameDetails}
          />
        )}
      </View>
    );
  }

  private onToggleModal = () => {
    const { isModalOpen } = this.state;

    this.setState({
      isModalOpen: !isModalOpen,
      ...(isModalOpen && { chosenReservationData: undefined }),
    });
  };

  private onReservationChoose = (confirmationNumber: string) => {
    const { multiRoomReservationDataStructure } = this.state;

    const chosenReservationData = multiRoomReservationDataStructure.find(
      (reservation) => reservation.confirmationNumber === confirmationNumber
    );

    this.chooseReservation(chosenReservationData!);
  };

  private chooseReservation = (
    chosenReservationData: MultiRoomReservationsListDataModel
  ) => {
    this.setState({ chosenReservationData }, this.onToggleModal);
  };

  private onConfirmNameDetails = async () => {
    const { chosenReservationData } = this.state;
    const {
      history,
      reservations,
      chooseReservation,
      chooseProfile,
      fetchCheckInData,
    } = this.props;

    try {
      this.setState({ isModalOpen: false, isLoading: true });

      const { roomId, roomTypeId, profileId } = reservations.find(
        ({ id }) => id === chosenReservationData!.id
      )!;

      chooseReservation(chosenReservationData!.id);
      chooseProfile(profileId);

      await this.roomReassignment(roomId, roomTypeId);
      await fetchCheckInData();
      this.configureRouting();

      Router.changeStepVisibility(Path.checkIn, 'NAME_DETAILS', true);
      history.push(Router.nextStepURL);
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  };

  private onChangeNameDetails = async () => {
    const { chosenReservationData } = this.state;
    const { history, reservations, chooseReservation } = this.props;

    try {
      this.setState({ isModalOpen: false, isLoading: true });

      const { roomId, roomTypeId } = reservations.find(
        ({ id }) => id === chosenReservationData!.id
      )!;

      chooseReservation(chosenReservationData!.id);
      await this.roomReassignment(roomId, roomTypeId);

      Router.changeStepVisibility(Path.checkIn, 'NAME_DETAILS', false);
      history.push(Router.nextStepURL);
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  };

  private roomReassignment = async (
    roomId: string | undefined,
    roomTypeId: string
  ) => {
    const { fetchAvailableRooms, fetchRoomDetails, assignRoom } = this.props;

    if (roomId) await fetchAvailableRooms(roomTypeId);

    const { shouldReassignRoom, isRoomFixed } = this.props;
    const { NO_AVAILABLE_ROOMS } = Configurator.roomErrorCodes;

    if (shouldReassignRoom) {
      if (isRoomFixed) {
        throw new RoomError(NO_AVAILABLE_ROOMS);
      }

      await assignRoom();
    }

    const { suggestedRoom } = this.props;
    const room = suggestedRoom.roomId || roomId;
    await fetchRoomDetails(room as string);
  };

  private prepareMultiRoomReservationsData = () => {
    const { reservations, roomList, profileList } = this.props;

    const multiRoomReservationDataStructure = reservations.map(
      (reservation) => {
        const roomNumber = roomList.find(
          ({ id }) => id === reservation.roomId
        )?.code;
        const profileDetails = profileList.find(
          ({ id }) => id === reservation.profileId
        )!.details;
        const formattedArrivalDate = DateManager.getFormattedDate(
          reservation.arrivalDate
        );
        const formattedDepartureDate = DateManager.getFormattedDate(
          reservation.departureDate
        );

        const { firstName, lastName, titleCode, additionalNames } =
          profileDetails;

        const title = Configurator.titles.find(
          (elem) => elem.code === titleCode
        );
        const titleDescription = title
          ? Configurator.getDescription(title.description)
          : '';
        const secondSurname = findAdditionalName(additionalNames);

        return {
          id: reservation.id,
          guest: {
            title: titleDescription,
            firstName,
            lastName,
            secondSurname,
          },
          fullName: [titleDescription, firstName, lastName, secondSurname]
            .join(' ')
            .trim(),
          confirmationNumber: reservation.confirmationNumber,
          stay: `${formattedArrivalDate} - ${formattedDepartureDate}`,
          roomNumber: roomNumber || '-',
        };
      }
    );

    this.setState({ multiRoomReservationDataStructure });

    if (multiRoomReservationDataStructure.length === 1) {
      this.chooseReservation(multiRoomReservationDataStructure[0]);
    }
  };

  private clearLocalErrors = () => {
    this.setState({ error: undefined });
  };

  private getDefaultError = () => {
    const { t } = this.props;

    const roomNotReadyError =
      Configurator.getTranslation(ROOM_NOT_READY_MESSAGE) ||
      t('SOMETHING_WENT_WRONG');

    return this.areAllRoomsUnavailable
      ? roomNotReadyError
      : t('CHECK_IN_UNAVAILABLE');
  };

  private get customErrorCode() {
    const { t } = this.props;

    return this.areAllRoomsUnavailable
      ? ROOM_NOT_READY_MESSAGE || t('SOMETHING_WENT_WRONG')
      : CHECK_IN_FAILED_MESSAGE;
  }

  private get areAllRoomsUnavailable() {
    const { error } = this.state;

    return error?.message === Configurator.roomErrorCodes.NO_AVAILABLE_ROOMS;
  }

  private configureRouting() {
    const {
      addons,
      activeAuthorizations,
      outstandingDeposit,
      localPropertyDateTime,
      preAuthorizationAmount,
      billingInstructions,
      folios,
      isMultiRoomReservation,
    } = this.props;

    configureCheckInRouting(
      addons,
      activeAuthorizations,
      outstandingDeposit,
      localPropertyDateTime,
      preAuthorizationAmount,
      billingInstructions,
      folios,
      isMultiRoomReservation
    );
  }
}

const mapStateToProps = (state: Store, props: CheckInMultiRoomProps) => ({
  reservations: getAllReservations(state),
  roomList: getRoomList(state),
  profileList: getAllProfiles(state),
  isDataLoading: getIsDataLoading(state),
  isLoading: isLoading(state),
  multiRoomSegment: getMultiRoomSegment(state),
  shouldReassignRoom: shouldReassignRoom(state),
  errors: getErrors(state),
  suggestedRoom: getSuggestedRoom(state),
  isMultiRoomReservation: isMultiRoomReservation(state),
  isRoomFixed: isRoomFixed(state),
  folios: getFolios(state),
  billingInstructions: getActiveBillingInstructions(state),
  preAuthorizationAmount: getPreAuthorizationAmount(state),
  localPropertyDateTime: getLocalPropertyDateTime(state),
  outstandingDeposit: getReservationOutstandingDeposit(state),
  activeAuthorizations: activeAuthorizations(state),
  addons: getAddons(state, props),
});

const mapDispatchToProps = {
  fetchRoomList,
  fetchProfile,
  fetchRoomDetails,
  fetchMultiRoomCheckinReservationList,
  fetchAvailableRooms,
  chooseReservation,
  chooseProfile,
  assignRoom,
  fetchCheckInData,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInMultiRoom) as () => JSX.Element;
