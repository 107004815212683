import { Nationality, RawNationality } from '@ac/library-api';

import {
  BaseKioskConfigurationEntity,
  KioskDictionaryEntryDetails,
  RawBaseKioskConfigurationEntity,
  RawKioskDictionaryEntryDetails,
} from '../../shared';

import { FeatureToggleDetailsDto } from './featureToggleDetails';
import {
  KioskCommunicationType,
  RawKioskCommunicationType,
} from './kioskCommunicationType';
import {
  KioskConfigurationConsent,
  RawKioskConfigurationConsent,
} from './kioskConfigurationConsent';
import {
  KioskConfigurationProperty,
  RawKioskConfigurationProperty,
} from './kioskConfigurationProperty';
import {
  KioskLayoutSetting,
  RawKioskLayoutSetting,
} from './kioskLayoutSetting';
import {
  KioskSupportedUiLanguageDetails,
  RawKioskSupportedUiLanguageDetails,
} from './kioskSupportedUiLanguageDetails';
import {
  KioskTelephoneRegionPrefixes,
  RawKioskTelephoneRegionPrefixes,
} from './kioskTelephoneRegionPrefixes';

export interface RawKioskConfiguration {
  property: RawKioskConfigurationProperty;
  addressTypes: RawKioskDictionaryEntryDetails[];
  communicationTypes: RawKioskCommunicationType[];
  consents: RawKioskConfigurationConsent[];
  countries: RawBaseKioskConfigurationEntity[];
  documentTypes: RawKioskDictionaryEntryDetails[];
  genders: RawBaseKioskConfigurationEntity[];
  languages: RawBaseKioskConfigurationEntity[];
  layoutSettings?: RawKioskLayoutSetting[];
  nationalities: RawNationality[];
  purposesOfStay: RawKioskDictionaryEntryDetails[];
  profileSuffixes: RawBaseKioskConfigurationEntity[];
  telephoneRegionPrefixes: RawKioskTelephoneRegionPrefixes[];
  titles: RawKioskDictionaryEntryDetails[];
  supportedUiLanguages: RawKioskSupportedUiLanguageDetails[];
  features: FeatureToggleDetailsDto[];
}

export class KioskConfiguration {
  public property?: KioskConfigurationProperty;
  public layoutSettings: KioskLayoutSetting[];
  public consents: KioskConfigurationConsent[];
  public addressTypes: KioskDictionaryEntryDetails[];
  public communicationTypes: KioskCommunicationType[];
  public countries: BaseKioskConfigurationEntity[];
  public documentTypes: KioskDictionaryEntryDetails[];
  public genders: BaseKioskConfigurationEntity[];
  public languages: BaseKioskConfigurationEntity[];
  public nationalities: Nationality[];
  public purposesOfStay: KioskDictionaryEntryDetails[];
  public profileSuffixes: BaseKioskConfigurationEntity[];
  public telephoneRegionPrefixes: KioskTelephoneRegionPrefixes[];
  public titles: KioskDictionaryEntryDetails[];
  public supportedUiLanguages: KioskSupportedUiLanguageDetails[];
  public features: FeatureToggleDetailsDto[];

  constructor(raw: RawKioskConfiguration) {
    this.property =
      raw.property && new KioskConfigurationProperty(raw.property);
    this.layoutSettings = (raw.layoutSettings || []).map(
      (item) => new KioskLayoutSetting(item)
    );
    this.consents = (raw.consents || []).map(
      (item) => new KioskConfigurationConsent(item)
    );
    this.addressTypes = (raw.addressTypes || []).map(
      (item) => new KioskDictionaryEntryDetails(item)
    );
    this.communicationTypes = (raw.communicationTypes || []).map(
      (item) => new KioskCommunicationType(item)
    );
    this.countries = (raw.countries || []).map(
      (item) => new BaseKioskConfigurationEntity(item)
    );
    this.documentTypes = (raw.documentTypes || []).map(
      (item) => new KioskDictionaryEntryDetails(item)
    );
    this.genders = (raw.genders || []).map(
      (item) => new BaseKioskConfigurationEntity(item)
    );
    this.languages = (raw.languages || []).map(
      (item) => new BaseKioskConfigurationEntity(item)
    );
    this.nationalities = (raw.nationalities || []).map(
      (item) => new Nationality(item)
    );
    this.purposesOfStay = (raw.purposesOfStay || []).map(
      (item) => new KioskDictionaryEntryDetails(item)
    );
    this.profileSuffixes = (raw.profileSuffixes || []).map(
      (item) => new BaseKioskConfigurationEntity(item)
    );
    this.telephoneRegionPrefixes = (raw.telephoneRegionPrefixes || []).map(
      (item) => new KioskTelephoneRegionPrefixes(item)
    );
    this.titles = (raw.titles || []).map(
      (item) => new KioskDictionaryEntryDetails(item)
    );
    this.supportedUiLanguages = (raw.supportedUiLanguages || []).map(
      (language) => new KioskSupportedUiLanguageDetails(language)
    );
    this.features = raw.features;
  }
}
