export class CustomError extends Error {
  static errorName = 'CustomError';
  errorName = CustomError.errorName;
}

export class AppInitializationError extends CustomError {
  static errorName = 'AppInitializationError';

  constructor(...args: ConstructorParameters<typeof CustomError>) {
    super(...args);
    this.errorName = AppInitializationError.errorName;
  }
}

export class RoomError extends CustomError {
  static errorName = 'RoomError';

  constructor(...args: ConstructorParameters<typeof CustomError>) {
    super(...args);
    this.errorName = RoomError.errorName;
  }
}
