import Big from 'big.js';
import { createSelector } from 'reselect';
import { getLocalPropertyDateTime } from 'store/propertyManagement/selectors';
import { getRatePlans } from 'store/rateManager/selectors';
import { AgeQualifyingCode } from 'types/Api/RateManager';
import { Breakdown, PurchaseElementData } from 'types/Api/Reservation';
import Store from 'types/Store/ReservationStore';
import { Configurator, DateManager } from 'utils';

import { ReservationInventoryItemDto } from '@ac/library-api';

export const getAllReservations = (state: Store) =>
  state.reservation.reservations;

export const getReservationDetails = (state: Store) =>
  state.reservation.reservationDetails;

export const getPurposeOfStay = createSelector(
  getReservationDetails,
  (details) => details.purposeOfStayId
);

export const getReservation = (state: Store) =>
  state.reservation.reservations[0];

export const getIsReservationShared = (state: Store) =>
  Boolean(state.reservation.reservations[0].sharingId);

export const getSharingVersion = (state: Store) =>
  state.reservation.sharingSummary.version;

export const getAccountId = (state: Store) => state.reservation.accountId;

export const getProjectedRevenueAmount = (state: Store) =>
  state.reservation.projectedRevenueAmount;

export const getTooMuchReservations = (state: Store) =>
  state.reservation.tooMuchReservations;

export const getMultiRoomWithNoConfirmationProvided = (state: Store) =>
  state.reservation.multiRoomWithNoConfirmationProvided;

export const getNumberOfTries = (state: Store) =>
  state.reservation.numberOfTries;

export const getReservationErrors = (state: Store) => state.reservation.errors;

export const getSuggestedRoom = (state: Store) =>
  state.reservation.suggestedRoom;

export const getBreakdown = (state: Store) => state.reservation.breakdown[0];

export const getProfileId = (state: Store) => getReservation(state).profileId;

export const getReservationId = (state: Store) => getReservation(state).id;

export const getReservationVersion = (state: Store) =>
  getReservation(state).version;

export const getCutKeyOperation = (state: Store) => state.reservation.cutKey;

export const getDuplicateKeyOperation = (state: Store) =>
  state.reservation.duplicateKey;

export const getAssignRoomOperation = (state: Store) =>
  state.reservation.assignRoomOperation;

export const isCheckedIn = (state: Store) => state.reservation.isCheckedIn;

export const getMultiRoomSegment = (state: Store) =>
  state.reservation.multiRoomSegment;

export const getReservationBreakdown = (state: Store) =>
  state.reservation.reservationExtendedBreakdown;

export const getPreAddedInventoryItems = (
  state: Store
): ReservationInventoryItemDto[] => state.reservation.preAddedInventoryItems;

export const isReservationDayUse = createSelector(
  getReservation,
  ({ arrivalDate, departureDate }) => {
    return !DateManager.getDiffBetween(arrivalDate, departureDate);
  }
);

export const getMultiRoomSegmentId = createSelector(
  getReservation,
  ({ segment }) => segment?.id
);

export const isMultiRoomReservation = createSelector(
  getMultiRoomSegmentId,
  (multiRoomSegmentId) => Boolean(multiRoomSegmentId)
);

export const getDepartureHour = createSelector<Store, any, string>(
  getReservation,
  ({ etd }) => (etd ? DateManager.getHour(etd) : '')
);

export const isRatePlanConfidential = createSelector(
  getReservation,
  getReservationBreakdown,
  (reservation, breakdown) =>
    reservation.isRatePriceHidden ||
    breakdown[0]?.ratePricingDetails?.isConfidential
);

export const getNights = createSelector(getReservation, (reservation) =>
  DateManager.getDiffBetween(reservation.arrivalDate, reservation.departureDate)
);

export const isDataFetching = (state: Store) => state.reservation.dataFetching;

export const isFetching = createSelector(isDataFetching, (dataFetching) =>
  Object.values(dataFetching).includes(true)
);

export const getPurchasesElementsFromRate = (state: Store) =>
  state.reservation.purchasesElementsFromRate;

export const getPreAddedPurchaseElements = (state: Store) =>
  state.reservation.preAddedPurchaseElements;

export const getAllAddedPurchaseElements = (state: Store) => {
  const { preAddedPurchaseElements, purchasesElementsFromRate } =
    state.reservation;

  return {
    preAddedPurchaseElements,
    purchasesElementsFromRate,
  };
};

export const getAddonsPriceSum = (state: Store) => state.reservation.addonsSum;

export const isRoomFixed = createSelector(
  getReservation,
  (reservation) => reservation.isRoomFixed
);

export const getCurrency = createSelector(
  getProjectedRevenueAmount,
  (projectedRevenueAmount) => projectedRevenueAmount.currency
);

export const getRoomPrice = createSelector(
  getProjectedRevenueAmount,
  getAddonsPriceSum,
  (projectedRevenueAmount, addons) => {
    const { gross } = projectedRevenueAmount;
    const price = gross || 0;

    return new Big(price).minus(addons).toNumber();
  }
);

export const getKeyEndValidityDate = createSelector(
  getReservation,
  (reservation) => {
    const { etd, departureDate, isEtaEtdGuaranteed } = reservation;
    if (isEtaEtdGuaranteed) {
      return (
        etd ||
        DateManager.getDateWithProvidedTime(
          departureDate,
          Configurator.etGuaranteed?.options.defaultCheckOutTime as string
        )
      );
    }

    const { checkOutTime } = Configurator.propertySettings;
    const defaultETD = DateManager.getDateWithProvidedTime(
      departureDate,
      checkOutTime
    );
    const isValidETD = etd && DateManager.checkIfAfter(defaultETD, etd);

    return isValidETD ? etd : defaultETD;
  }
);

export const getKeyStartValidityDate = createSelector(
  getLocalPropertyDateTime,
  (localPropertyDateTime) => {
    return DateManager.getServerFormattedDateTime(localPropertyDateTime);
  }
);

export const getGuestsNumberByType =
  (code: AgeQualifyingCode) => (breakdown: Breakdown) => {
    if (!breakdown) return 0;

    const ageBucket = Configurator.getAgeBucket(code);
    const guests = breakdown.guests.find(
      (guest) => guest.ageBucketId === ageBucket?.id
    );

    return guests ? guests.count : 0;
  };

export const getAdults = createSelector(
  getBreakdown,
  getGuestsNumberByType(AgeQualifyingCode.Adult)
);

export const getChildren = createSelector(
  getBreakdown,
  getGuestsNumberByType(AgeQualifyingCode.Child)
);

export const getGuestsNumber = createSelector(
  getChildren,
  getAdults,
  (children, adults) => children + adults
);

export const getIdsLinkedToReservationWithRole = (state: Store) =>
  state.reservation.linkedProfiles;

export const getLinkedCompanyId = createSelector(
  getIdsLinkedToReservationWithRole,
  (linkedProfiles) =>
    linkedProfiles.linkedCompany ? linkedProfiles.linkedCompany.id : ''
);

export const getLinkedTravelAgentId = createSelector(
  getIdsLinkedToReservationWithRole,
  (linkedProfiles) =>
    linkedProfiles.linkedTravelAgent ? linkedProfiles.linkedTravelAgent.id : ''
);

export const getGuestContactProfilesId = createSelector(
  getIdsLinkedToReservationWithRole,
  (linkedProfiles) =>
    linkedProfiles.guestContactProfiles
      ? linkedProfiles.guestContactProfiles.primaryContact?.id
      : ''
);

export const getIdsLinkedToReservation = createSelector(
  getLinkedCompanyId,
  getLinkedTravelAgentId,
  getGuestContactProfilesId,
  (linkedCompanyId, linkedTravelAgentId, linkedIndividualProfileId) => {
    return {
      linkedCompanyId,
      linkedTravelAgentId,
      linkedIndividualProfileId,
    };
  }
);

export const getFirstNightRatePlan = createSelector(
  getReservationBreakdown,
  getReservation,
  getRatePlans,
  (reservationExtended, reservation, ratePlan) => {
    const { arrivalDate } = reservation;
    const firstNightRatePlan = reservationExtended.find(
      (res) => res.fromDate === arrivalDate
    );
    const ratePlanDetails = ratePlan.find((plan) => {
      return (
        firstNightRatePlan &&
        plan.id === firstNightRatePlan.ratePricingDetails.ratePlanId
      );
    });

    return ratePlanDetails;
  }
);

export const getLastNightRatePlan = createSelector(
  getReservationBreakdown,
  getReservation,
  getRatePlans,
  (reservationExtended, reservation, ratePlan) => {
    const { departureDate } = reservation;
    const lastNightRatePlan = reservationExtended.find(
      (res) => res.toDate === departureDate
    );
    const ratePlanDetails = ratePlan.find((plan) => {
      return (
        lastNightRatePlan &&
        plan.id === lastNightRatePlan.ratePricingDetails.ratePlanId
      );
    });

    return ratePlanDetails;
  }
);

const getPurchaseElementsFromRate = (
  purchasesElementsFromRate: PurchaseElementData[],
  dayToFilter: string,
  firstNight: boolean
) =>
  purchasesElementsFromRate.length &&
  purchasesElementsFromRate.filter((item) => {
    return item.breakdown.find((item) =>
      firstNight ? item.fromDate === dayToFilter : item.toDate === dayToFilter
    );
  });

export const getPurchaseElementsIdsFirstNightRate = createSelector(
  getPurchasesElementsFromRate,
  getReservation,
  (purchasesElementsFromRate, reservation) => {
    const { arrivalDate } = reservation;

    return getPurchaseElementsFromRate(
      purchasesElementsFromRate,
      arrivalDate,
      true
    );
  }
);

export const getPurchaseElementsIdsLastNightRate = createSelector(
  getPurchasesElementsFromRate,
  getReservation,
  (purchasesElementsFromRate, reservation) => {
    const { departureDate } = reservation;

    return getPurchaseElementsFromRate(
      purchasesElementsFromRate,
      departureDate,
      false
    );
  }
);

export const getLinkedMembership = createSelector(
  getReservation,
  (reservation) => {
    return reservation?.membership?.id;
  }
);

export const getIsPEChangesBeyondLimit = (state: Store) =>
  state.reservation.isPEChangesBeyondLimit;
