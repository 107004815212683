import * as SelfServiceCheckInProcess from './checkInProcess';
import * as SelfServiceConfiguration from './configuration';
import * as SelfServiceInitialization from './initialization';
import * as SelfServiceMakeKeysProcess from './makeKeysProcess';
import * as SelfServiceObsoleteCheckInProcess from './obsoleteCheckInProcess';

export const SelfServiceConfigurationApi = SelfServiceConfiguration;
export const SelfServiceInitializationApi = SelfServiceInitialization;
export const SelfServiceObsoleteCheckInProcessApi =
  SelfServiceObsoleteCheckInProcess;
export const SelfServiceMakeKeysProcessApi = SelfServiceMakeKeysProcess;
export const SelfServiceCheckInProcessApi = SelfServiceCheckInProcess;
