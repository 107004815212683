/* eslint-disable camelcase */
export interface ScannerData {
  lastName: string;
  identificationNumber: string;
  [key: string]: string;
}

export enum ScanDataType {
  NoSymbolDecoded = 0,
  IntermediateStatus = 1,
  GS1_2DigitAddOn = 2, // GS1 2-digit add-on
  GS1_5DigitAddOn = 5, // GS1 5-digit add-on
  EAN_8 = 8, // EAN-8
  UPC_E = 9, // UPC-E
  ISBN_10 = 10, // (from EAN-13)
  UPC_A = 12, // UPC-A
  EAN_13 = 13, // EAN-13
  ISBN_13 = 14, // ISBN-13 (from EAN-13)
  EAN_UPC_Composite = 15, // EAN/UPC composite
  GS1DataBar = 34, // GS1 DataBar (RSS)
  GS1DataBarExpanded = 35,
  Code39 = 39,
  PDF417 = 57,
  QRCode = 64,
  Code93 = 93,
  Code128 = 128,
}

export enum ScanningErrorType {
  CameraNotAuthorizedIniOSForApp = -1002, // camera access is not authorized in iOS settings for Kiosk Pro.
  ScanningNotEnabledInAppSettings = -1001, // scanning is not enabled in Kiosk Pro's settings.
  ScanningInProgress = -1000, // scanning is currently in progress.
  InternalErrorInLibrary = 1, // internal error in scanning library.
}
