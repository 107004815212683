import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      backgroundColor: palette.primary.main,
    },
    textWrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexDirection: 'column',
      boxSizing: 'border-box',
      height: '100%',
      margin: '0 auto',
    },
    image: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      opacity: 0.7,
      zIndex: -1,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
    text: {
      '& *': {
        width: '75vw !important',
      },
      '& p': {
        fontSize: '2rem',
        color: palette.primary.contrastText,
      },
    },
    subtitle: {
      position: 'absolute',
      bottom: spacing(3),
      color: palette.primary.contrastText,
      fontSize: '0.875rem',
    },
  });
