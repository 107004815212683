import { useTranslation } from 'react-i18next';

import { Section } from '@ac/kiosk-components';

import { Body, Header } from '@gss/components/layout';

// import { useRouter } from '@gss/router';
import { CheckInFooter, CheckInView } from '../components';

export const CheckInDocumentDetails = (): JSX.Element => {
  const { t } = useTranslation();
  // const router = useRouter();

  return (
    <CheckInView>
      <Header
        title={`${t('CHECK_IN_FLOW.TITLE')} - ${t(
          'CHECK_IN_FLOW.VIEWS.DOCUMENT_DETAILS.HEADER'
        )}`}
      />
      <Body>
        <Section>In progress...</Section>
      </Body>
      <CheckInFooter
        hasCancelButton
        hasPrimaryButton
        // TODO: Besides create new profile, we need possibility to update after goBack
        // Review goBack logic after those changes
        // hasSecondaryButton
        // onSecondaryClick={(): void => {
        //   router.goTo('CHECK_IN_NAME_DETAILS');
        // }}
      />
    </CheckInView>
  );
};
