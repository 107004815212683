import { isApiError } from '@ac/library-api';

const ENTITY_NOT_FOUND = 'ExternalEntityNotFound';
const TOO_MANY_RESERVATIONS = 'MoreThenOneResultFoundException';

export const isAuthEntityNotFoundError = (error: unknown): boolean => {
  const errorWithData =
    error && typeof error === 'object' && 'data' in error
      ? (error as Record<'data', unknown>)
      : undefined;

  return Boolean(
    errorWithData &&
      isApiError(errorWithData.data) &&
      errorWithData.data.details[0]?.code === ENTITY_NOT_FOUND
  );
};

export const isAuthTooManyReservationsError = (error: unknown): boolean => {
  const errorWithData =
    error && typeof error === 'object' && 'data' in error
      ? (error as Record<'data', unknown>)
      : undefined;

  return Boolean(
    errorWithData &&
      isApiError(errorWithData.data) &&
      errorWithData.data.details[0]?.code === TOO_MANY_RESERVATIONS
  );
};
