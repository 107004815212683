import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AlignItems,
  BasicModal,
  Box,
  Button,
  Circular,
  CircularType,
  Color,
  Flex,
  FlexDirection,
  IconTypes,
  JustifyContent,
  Text,
} from '@ac/kiosk-components';

import { getCustomMessages } from '@gss/store/settings/selectors';

interface ETDModalProps {
  etd: string;
  dataTestSelector?: string;
  onClose?: () => void;
}

export const ETDModal = ({
  etd,
  dataTestSelector,
  onClose,
}: ETDModalProps): JSX.Element => {
  const { t } = useTranslation();
  const customMessages = useSelector(getCustomMessages);

  return (
    <BasicModal
      className="with-default-kiosk-components-theme"
      dataTestSelector={dataTestSelector}
    >
      <Flex
        direction={FlexDirection.column}
        alignItems={AlignItems.center}
        justifyContent={JustifyContent.center}
        className="spacing-top-lg padding-horizontal-xxlg"
      >
        <Circular
          type={CircularType.warning}
          icon={IconTypes.exclamationMark}
          iconColor={Color.primary}
          className="spacing-bottom-xlg"
        />
        <Box className="text-center">
          <Text>
            <Trans
              i18nKey="COMPONENTS.MODALS.ETD_MODAL.DESCRIPTION"
              values={{ time: etd }}
            />
          </Text>
          <Text>{customMessages?.KIOSK_DAY_USE_MESSAGE}</Text>
          <Button
            dataTestSelector="etd-modal-close-button"
            onClick={onClose}
            className="spacing-auto spacing-top-lg"
          >
            {t('SHARED.CLOSE')}
          </Button>
        </Box>
      </Flex>
    </BasicModal>
  );
};
