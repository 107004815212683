import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  BasicModal,
  BasicModalSize,
  Button,
  ButtonPattern,
  Field,
  Link,
  Text,
  TextSize,
  TextWeight,
} from '@ac/kiosk-components';

import './TaxIDSection.scss';

interface TaxIDSectionProps {
  className?: string;
  taxId?: string;
  onTaxIdChange?: (taxId: string | undefined) => void;
}

const TaxIDSection = ({
  className,
  onTaxIdChange,
  taxId,
}: TaxIDSectionProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [savedTaxId, setSavedTaxId] = useState<string | undefined>(taxId);
  const [currentTaxId, setCurrentTaxId] = useState<string | undefined>(taxId);

  const toggleModal = useCallback(() => {
    setModalOpen((openState) => !openState);
  }, []);

  const handleTaxIdChange = useCallback(() => {
    setSavedTaxId(currentTaxId);
    onTaxIdChange?.(currentTaxId);
    toggleModal();
  }, [currentTaxId, onTaxIdChange]);

  return (
    <>
      {isModalOpen && (
        <BasicModal
          size={BasicModalSize.md}
          className="with-default-kiosk-components-theme"
        >
          <div className="checkout-charges-tax-id-modal">
            <Text size={TextSize.xlg} className="spacing-bottom-lg">
              {t('CHECK_OUT_CHARGES.TAX_ID_SECTION.TITLE')}
            </Text>
            <Field
              label={t('TAX_ID')}
              optional
              onChange={setCurrentTaxId}
              value={savedTaxId}
            />
            <div className="checkout-charges-tax-id-modal-buttons-wrapper">
              <Button onClick={toggleModal} pattern={ButtonPattern.secondary}>
                {t('CANCEL')}
              </Button>
              <Button onClick={handleTaxIdChange}>
                {savedTaxId ? t('CONFIRM') : t('ADD')}
              </Button>
            </div>
          </div>
        </BasicModal>
      )}

      <div className={classNames('checkout-charges-tax-id-section', className)}>
        <Text size={TextSize.xs} weight={TextWeight.light}>
          {t('CHECK_OUT_CHARGES.TAX_ID_SECTION.TITLE')}
        </Text>
        <Text size={TextSize.xs}>{savedTaxId}</Text>
        <Link
          className="checkout-charges-tax-id-section-link"
          size={TextSize.xs}
          weight={TextWeight.light}
          onClick={toggleModal}
        >
          {savedTaxId
            ? t('CHECK_OUT_CHARGES.TAX_ID_SECTION.EDIT_TAX_ID')
            : t('CHECK_OUT_CHARGES.TAX_ID_SECTION.ADD_TAX_ID')}
        </Link>
      </div>
    </>
  );
};

export default TaxIDSection;
