import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IconTypes } from '@ac/kiosk-components';

import { routes } from '@gss/router';
import {
  getCustomMessages,
  getFeatureToggles,
  getGeneralSettings,
} from '@gss/store/settings/selectors';
import { allSteps } from '@LEGACY/utils/Router';

export interface Route {
  icon: IconTypes;
  label: string;
  path: string;
  description?: string;
  isVisible?: boolean;
  dataTestSelector: string;
}

export const useRouteButtonsConfiguration = (): Route[] => {
  const { t } = useTranslation();
  const customMessages = useSelector(getCustomMessages);
  const generalSettings = useSelector(getGeneralSettings);
  const featureToggles = useSelector(getFeatureToggles);

  return useMemo<Route[]>(
    () => [
      {
        icon: IconTypes.checkIn,
        path: allSteps.CHECK_IN.AUTH.url,
        label: t('CHECK_IN'),
        description: customMessages?.CHECK_IN_BTN_DESC,
        isVisible: generalSettings?.SHOW_CHECK_IN,
        dataTestSelector: 'lobby-check-in-button',
      },
      // TODO: Temporary hides new flow, that is under refactor
      ...(featureToggles?.KIOSK_SECURITY_UPGRADE
        ? [
            {
              icon: IconTypes.checkIn,
              path: routes.CHECK_IN_AUTH.path,
              label: 'New Check In Flow',
              description: customMessages?.CHECK_IN_BTN_DESC,
              isVisible: generalSettings?.SHOW_CHECK_IN,
              dataTestSelector: 'lobby-new-check-in-button',
            },
          ]
        : []),
      {
        icon: IconTypes.cardKey,
        path: routes.KEY_AUTH.path,
        label: t('GENERATE_KEYS'),
        description: customMessages?.GENERATE_KEY_BTN_DESC,
        isVisible: generalSettings?.SHOW_GENERATE_KEY,
        dataTestSelector: 'lobby-make-keys-button',
      },
      {
        icon: IconTypes.checkOut,
        path: allSteps.CHECK_OUT.AUTH.url,
        label: t('CHECK_OUT'),
        description: customMessages?.CHECK_OUT_BTN_DESC,
        isVisible: generalSettings?.SHOW_CHECK_OUT,
        dataTestSelector: 'lobby-check-out-button',
      },
    ],
    [customMessages, generalSettings, featureToggles, t]
  );
};
