import { ComponentProps, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmModal } from '@ac/kiosk-components';

import { Footer } from '@gss/components/layout';
import { useRouter } from '@gss/router';
import { closeMakeKeyProcess } from '@gss/store/flows/makeKeysFlow/actions';
import { makeKeysTestSelector } from '@gss/utils/testSelectors';

const TEST_SELECTOR_PREFIX = 'footer';

export const MakeKeysFooter = (
  props: ComponentProps<typeof Footer>
): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const [cancelModalOpened, setCancelModalOpen] = useState(false);

  const toggleCancelModal = useCallback(() => {
    setCancelModalOpen((prevState) => !prevState);
  }, []);

  const cancelMakeKeysProcess = useCallback(() => {
    dispatch(
      closeMakeKeyProcess.trigger((): void => {
        router.goTo('WELCOME', { replace: true });
      })
    );
  }, [dispatch, router]);

  return (
    <>
      {cancelModalOpened && (
        <ConfirmModal
          className="with-default-kiosk-components-theme"
          header={t(
            'VIEWS.KEY_FLOW.COMPONENTS.FOOTER.CANCEL_PROCESS_MODAL.TITLE'
          )}
          description={t(
            'VIEWS.KEY_FLOW.COMPONENTS.FOOTER.CANCEL_PROCESS_MODAL.DESCRIPTION'
          )}
          secondaryButtonLabel={t('SHARED.LEAVE')}
          primaryButtonLabel={t('SHARED.STAY_AND_CONTINUE')}
          onPrimaryClick={toggleCancelModal}
          onSecondaryClick={cancelMakeKeysProcess}
        />
      )}

      <Footer
        withStepper
        onCancelClick={toggleCancelModal}
        dataTestSelector={makeKeysTestSelector(TEST_SELECTOR_PREFIX)}
        {...props}
      />
    </>
  );
};
