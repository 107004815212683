import React, { createRef, PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Body, ChargeList, Footer, View } from 'components';
import { compose } from 'redux';
import { isFetching as isFetchingCashiering } from 'store/cashiering/selectors';
import { generateCheckOutFolioPdf } from 'store/files/actions';
import { isFetching as isFetchingFiles } from 'store/files/selectors';
import { isRatePlanConfidential } from 'store/reservation/selectors';
import { ApiError, TransactionSummary } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator } from 'utils';

import { ImageMimeTypes, SignatureField } from '@ac/kiosk-components';

import { Header } from '@gss/components/layout';
import { scrollElement, ScrollPosition } from '@gss/utils';
import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import { getErrors } from './store/selectors';
import styles from './CheckOutSummary.style';

const BOTTOM_SCROLL_HEIGHT = 0.98;
const BODY_ID = 'body-wrapper-id';
const SIGNATURE_FIELD_ID = 'signature-field-id';

interface PassedProps {
  transactions: TransactionSummary[];
  totalBalance: number;
  folioId: string;
  onSubmit: () => void;
  onCancel: () => void;
  hideCurrency: boolean;
}

interface CheckOutSummaryProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  generateCheckOutFolioPdf: typeof generateCheckOutFolioPdf;
  isFetching: boolean;
  errors: ApiError[];
  isSubmitting: boolean;
  email: {
    details: string;
    [index: string]: any;
  };
  isRatePlanConfidential: boolean;
}

interface CheckOutSummaryState {
  isScrollEnabled: boolean;
  signature?: Blob;
}

class CheckOutSummary extends PureComponent<
  CheckOutSummaryProps,
  CheckOutSummaryState
> {
  public static defaultProps = {
    errors: [],
  };

  public state = {
    isScrollEnabled: true,
    signature: undefined,
  };

  public componentDidMount() {
    const scrollElement = document.getElementById(BODY_ID) as
      | HTMLDivElement
      | undefined;
    if (!scrollElement) return;
    this.updateConfirmButtonState(scrollElement);
  }

  public render() {
    const {
      classes,
      t,
      isFetching,
      errors,
      transactions,
      totalBalance,
      onCancel,
      isRatePlanConfidential,
      hideCurrency,
    } = this.props;
    const { signature, isScrollEnabled } = this.state;
    const continueButtonLabel = isScrollEnabled
      ? t('SCROLL_TO_SIGN')
      : t('CONFIRM');

    return (
      <View
        idle={{ type: 'modal' }}
        modal={{ values: errors, disableLoader: true }}
      >
        <Header title={`${t('CHECK_OUT')} - ${t('SUMMARY')}`} />
        <Body
          id={BODY_ID}
          className={classes.body}
          onScroll={this.handleScroll}
        >
          <Grid container direction="column">
            <ChargeList
              transactions={transactions}
              hideCurrency={hideCurrency}
            />
            <Grid className={classes.additionalFooterSegment}>
              <Typography className={classes.costDescription}>
                {t('TOTAL_BALANCE')}
              </Typography>
              <Typography className={classes.cost}>
                {isRatePlanConfidential
                  ? Configurator.pricePlaceholder
                  : totalBalance.toFixed(2)}
              </Typography>
            </Grid>
            <div
              id={SIGNATURE_FIELD_ID}
              className="spacing-top-xxlg padding-bottom-sm"
            >
              <SignatureField
                className={classes.checkOutSummarySignatureField}
                signatureImageMimeType={ImageMimeTypes.JPEG}
                label={t('SIGN_TO_CONFIRM')}
                placeholder={t('SIGN_HERE_WITH_YOUR_FINGER')}
                onSignatureCompleted={this.handleSignatureChange}
                onReset={this.handleSignatureChange}
              />
            </div>
          </Grid>
        </Body>
        <Footer
          hasCancelButton
          hasContinueButton
          hasBackButton
          routeName={t('CHECK_OUT')}
          isOnContinuePrior
          continueButtonLabel={continueButtonLabel}
          isContinueDisabled={isFetching || (!isScrollEnabled && !signature)}
          isScrollEnabled={isScrollEnabled}
          onContinue={this.onContinue}
          onGoBack={onCancel}
        />
      </View>
    );
  }

  private handleSignatureChange = (signatureImage?: Blob) => {
    this.setState({ signature: signatureImage });
  };

  private generatePdf = async () => {
    const { generateCheckOutFolioPdf, folioId } = this.props;
    const { signature } = this.state;

    return generateCheckOutFolioPdf(folioId, signature!);
  };

  private prepareSummary = async () => {
    const { onSubmit } = this.props;
    const { signature } = this.state;
    if (!signature) return;
    await this.generatePdf();

    return onSubmit();
  };

  private scrollToSign = () => {
    scrollElement(
      `#${BODY_ID}`,
      `#${SIGNATURE_FIELD_ID}`,
      ScrollPosition.bottom
    );
  };

  private onContinue = () => {
    const { isScrollEnabled } = this.state;

    return isScrollEnabled ? this.scrollToSign() : this.prepareSummary();
  };

  private updateConfirmButtonState = (scrollElement: HTMLDivElement) => {
    const { signature } = this.state;
    const { scrollHeight, offsetHeight, scrollTop } = scrollElement;
    const pageHeight = (scrollTop + offsetHeight) / scrollHeight;
    pageHeight < BOTTOM_SCROLL_HEIGHT
      ? this.setState({ isScrollEnabled: !signature })
      : this.setState({ isScrollEnabled: false });
  };

  private handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (!e.target) return;
    this.updateConfirmButtonState(e.target as HTMLDivElement);
  };
}

const mapStateToProps = (state: Store) => ({
  isFetching: isFetchingFiles(state),
  errors: getErrors(state),
  isSubmitting: isFetchingCashiering(state),
  isRatePlanConfidential: isRatePlanConfidential(state),
});

const mapDispatchToProps = {
  generateCheckOutFolioPdf,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckOutSummary) as (props: PassedProps) => JSX.Element;
