import { useRef } from 'react';

import { createRequiredValidator } from '@ac/react-infrastructure';

import { FormValidator } from '@gss/utils/form';

import { CreateProfileFormValues } from './types';

export const useCreateProfileFormValidator =
  (): FormValidator<CreateProfileFormValues> => {
    const validator = useRef(
      new FormValidator<CreateProfileFormValues>({
        firstName: createRequiredValidator(
          'VALIDATION_MESSAGES.FIELD_IS_MANDATORY'
        ),
        lastName: createRequiredValidator(
          'VALIDATION_MESSAGES.FIELD_IS_MANDATORY'
        ),
      })
    );

    return validator.current;
  };
