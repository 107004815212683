import {
  AlignItems,
  Color,
  Flex,
  Icon,
  IconTypes,
  JustifyContent,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import './NotificationBar.scss';

export enum NotificationBarType {
  error = 'error',
  warn = 'warn',
  success = 'success',
}

interface NotificationBarProps {
  type: NotificationBarType;
  description: string;
}

const ICON_TYPE = {
  [NotificationBarType.error]: IconTypes.cancel,
  [NotificationBarType.warn]: IconTypes.exclamationMark,
  [NotificationBarType.success]: IconTypes.check,
};

export const NotificationBar = ({
  type,
  description,
}: NotificationBarProps): JSX.Element => {
  const iconType = ICON_TYPE[type];

  return (
    <Flex
      justifyContent={JustifyContent.center}
      alignItems={AlignItems.center}
      className={`gap-xlg notification-bar-element notification-bar-type-${type}`}
    >
      <Icon
        type={iconType}
        color={Color.white}
        className="notification-bar-icon"
      />
      <Text size={TextSize.xlg}>{description}</Text>
    </Flex>
  );
};
