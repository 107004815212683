import i18n from 'i18next';
import { clearState } from 'store/actions';
import {
  fetchCreditCardAuthorizationRules,
  fetchFolioStyleCode,
  fetchFolioStyles,
  fetchIfcCashierNumber,
  fetchKioskTransactionCode,
  fetchMembershipLevels,
  fetchMembershipTypes,
  fetchProfileTypes,
  fetchPropertyFloors,
  fetchRoomAttributeTypes,
  fetchRoomLocations,
  fetchTaxRules,
  fetchTransactionCodes,
} from 'store/entities/actions';
import { fetchPaymentInterfaceAdapter } from 'store/paymentInterface/actions';
import {
  fetchCurrentDate,
  fetchPropertyLocalDateTime,
} from 'store/propertyManagement/actions';
import {
  fetchAgeBuckets,
  fetchPurchaseElements,
} from 'store/rateManager/actions';
import { refreshTheme, refreshUi } from 'store/ui/actions';
import { Dispatch, GetState } from 'store/utils/actions';
import {
  ParsedKioskCommunicationType,
  ParsedKioskConfiguration,
} from 'types/Api/ParsedKioskConfiguration';
import { Configurator } from 'utils';
import { reportReduxActionError } from 'utils/logReportPatterns';

import { CommunicationMode, LibraryApiResponse } from '@ac/library-api';
import { isDefined, repeatableCall } from '@ac/library-utils/dist/utils';

import { SelfServiceConfigurationApi } from '@gss/api/KioskApi';
import {
  KioskConfiguration,
  KioskLayoutSetting,
} from '@gss/api/KioskApi/entries';
import {
  API_HEADERS,
  DEFAULT_APP_LANGUAGE,
  LOG_TYPES,
} from '@gss/configs/constants';
import { getPropertyPermissions } from '@gss/store/configuration/selectors';
import { externalSettingsCodesMap } from '@gss/store/settings/interfaces/settings/externalSettingsState';
import { getAreSettingsInitialized } from '@gss/store/settings/selectors';
import { mapLayoutSettings } from '@gss/store/settings/utils';
import { AppInitializationError } from '@gss/utils/errors/customErrors';

import { getErrors } from './selectors';
import {
  mapConfigurationsData,
  validateEntitySetup,
  validatePropertyPermissions,
  validateSettingsSetup,
} from './utils';

const fetchSettingImagesAndReplaceMetadataByObjectUrl = async (
  settings: KioskLayoutSetting,
  wasStoreInitializedBefore: boolean
) => {
  const settingsImagesCodes = Object.values(Configurator.settingsImagesCodes);
  const kioskSettings = Object.entries(settings);
  const changedLayoutSettings = await Promise.all(
    kioskSettings.map(async ([code, value]) => {
      if (!(code && value && settingsImagesCodes.includes(code))) {
        return [code, value];
      }

      const savedImageForCode = Configurator.settingImages[code];
      const wasThisImageAlreadySaved =
        wasStoreInitializedBefore && savedImageForCode?.id === value?.fileId;

      if (wasThisImageAlreadySaved) {
        return [code, savedImageForCode];
      }

      let imageResponse;

      try {
        imageResponse = await repeatableCall(
          async () => {
            try {
              return SelfServiceConfigurationApi.getContentImage({
                pathParams: { code },
                fullResponse: true,
              }) as Promise<LibraryApiResponse<Blob>>;
            } catch (error) {
              return error;
            }
          },
          (response: LibraryApiResponse<Blob> | undefined) =>
            response?.status === 200,
          { repeatCount: 3 }
        );
      } catch (error) {
        imageResponse = error;
      }

      if (
        !imageResponse ||
        imageResponse.status !== 200 ||
        !imageResponse.data
      ) {
        reportReduxActionError(imageResponse, {
          type: 'FETCH_SETTINGS_IMAGES',
        });

        return;
      }

      URL.revokeObjectURL(savedImageForCode?.content);

      return [
        code,
        { id: value.fileId, content: URL.createObjectURL(imageResponse.data) },
      ];
    })
  );

  return Object.fromEntries(changedLayoutSettings.filter(isDefined));
};

const fetchConfiguration = async (wasStoreInitializedBefore: boolean) => {
  const kioskConfiguration: KioskConfiguration =
    await (SelfServiceConfigurationApi.getConfiguration({
      customConfig: {
        headers: {
          [API_HEADERS.acceptLanguage]: DEFAULT_APP_LANGUAGE,
        },
      },
    }) as Promise<KioskConfiguration>);

  const languageSettings = await mapLayoutSettings(
    { LANGUAGE: externalSettingsCodesMap.LANGUAGE },
    kioskConfiguration.layoutSettings
  );

  const supportedLanguageCodesToFetch =
    languageSettings?.LANGUAGE.supportedLanguages.filter((code) => {
      return code !== DEFAULT_APP_LANGUAGE;
    });

  const kioskConfigurationForEachLanguage =
    supportedLanguageCodesToFetch &&
    (await Promise.all(
      supportedLanguageCodesToFetch.map((languageCode) => {
        return Promise.all([
          languageCode.toLowerCase(),
          SelfServiceConfigurationApi.getConfiguration({
            customConfig: {
              headers: {
                [API_HEADERS.acceptLanguage]: languageCode,
              },
            },
          }) as Promise<KioskConfiguration>,
        ]);
      })
    ));

  const allConfiguration = [
    [DEFAULT_APP_LANGUAGE, kioskConfiguration],
    ...(kioskConfigurationForEachLanguage || []),
  ] as Array<[string, KioskConfiguration]>;

  /**
   * Temporary solution.
   * Fetching configuration for all supported languages, then merge them,
   * to keep old structure of data.
   *
   * TODO: To change, after configuration will be stored in redux
   * and we will be able to refresh it on language change
   */
  const preparedConfiguration: ParsedKioskConfiguration =
    mapConfigurationsData(allConfiguration);

  const layoutSettings = await fetchSettingImagesAndReplaceMetadataByObjectUrl(
    preparedConfiguration.layoutSettings,
    wasStoreInitializedBefore
  );

  const emailTypes = preparedConfiguration.communicationTypes.filter(
    (type: ParsedKioskCommunicationType) =>
      type.communicationMode?.code === CommunicationMode.Email
  );
  const mobileTypes = preparedConfiguration.communicationTypes.filter(
    (type: ParsedKioskCommunicationType) =>
      type.communicationMode?.code === CommunicationMode.Mobile
  );

  Configurator.saveSettings(layoutSettings);

  preparedConfiguration?.property &&
    Configurator.saveProperty(preparedConfiguration.property);
  Configurator.saveAddressTypes(preparedConfiguration.addressTypes);
  Configurator.saveConsents(preparedConfiguration.consents);
  Configurator.savePurposesOfStay(preparedConfiguration.purposesOfStay);
  Configurator.saveTitles(preparedConfiguration.titles);
  Configurator.saveDocumentTypes(preparedConfiguration.documentTypes);
  Configurator.saveGenders(preparedConfiguration.genders);
  Configurator.saveEmailTypes(emailTypes);
  Configurator.saveMobileTypes(mobileTypes);

  allConfiguration.forEach(([languageCode, kioskConfiguration]) => {
    Configurator.saveMobilePrefixes(
      languageCode,
      kioskConfiguration.telephoneRegionPrefixes
    );
    Configurator.saveCountries(languageCode, kioskConfiguration.countries);
    Configurator.saveNationalities(
      languageCode,
      kioskConfiguration.nationalities
    );
  });
};

export const resetLegacyAppSetup = () => async (dispatch: Dispatch) => {
  const authLogs = localStorage.getItem(LOG_TYPES.auth);
  const appLogs = localStorage.getItem(LOG_TYPES.app);

  localStorage.clear();

  authLogs && localStorage.setItem(LOG_TYPES.auth, authLogs);
  appLogs && localStorage.setItem(LOG_TYPES.app, appLogs);

  await dispatch(clearState());
};

export const checkNecessarySetup =
  () => (dispatch: Dispatch, getState: GetState) => {
    const propertyPermissions = getPropertyPermissions(getState());
    const missingEntitiesErrors = validateEntitySetup();
    const missingSettingsErrors = validateSettingsSetup();
    const missingPermissionsErrors =
      validatePropertyPermissions(propertyPermissions);

    const isCashierConfigured = Configurator.cashieringSettings.cashierNumber;

    const setupErrors = [
      missingEntitiesErrors,
      missingSettingsErrors,
      missingPermissionsErrors,
      isCashierConfigured ? undefined : i18n.t('CASHIER_NOT_CONFIGURED'),
    ].filter(isDefined);

    if (setupErrors.length) {
      throw new AppInitializationError(setupErrors.join('\n\n'));
    }
  };

export const fetchLegacySetup =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const wasStoreInitializedBefore = getAreSettingsInitialized(getState());

    await fetchConfiguration(wasStoreInitializedBefore);

    await Promise.all([
      dispatch(fetchTransactionCodes()),
      dispatch(fetchMembershipLevels()),
      dispatch(fetchMembershipTypes()),
      dispatch(fetchRoomAttributeTypes()),
      dispatch(fetchPropertyFloors()),
      dispatch(fetchRoomLocations()),
      dispatch(fetchProfileTypes()),
      dispatch(fetchCreditCardAuthorizationRules()),
      dispatch(fetchTaxRules()),
      dispatch(fetchPropertyLocalDateTime()),
      dispatch(fetchCurrentDate()),
      dispatch(fetchIfcCashierNumber()),
      dispatch(fetchFolioStyles()),
    ]);

    await dispatch(fetchPurchaseElements(wasStoreInitializedBefore));
    await dispatch(fetchAgeBuckets());
    await dispatch(fetchPaymentInterfaceAdapter());
    await dispatch(fetchFolioStyleCode());
    await dispatch(fetchKioskTransactionCode());
    await dispatch(refreshTheme());
    await dispatch(refreshUi());

    await dispatch(checkNecessarySetup());

    const setupErrors = getErrors(getState());

    if (setupErrors?.length) {
      throw setupErrors;
    }
  };
