import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { DateManager } from 'utils';

import { DateTimePickerModal } from '@ac/kiosk-components';

import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './MobileDatePicker.style';

interface PassedProps {
  value?: Date;
  onSelect?: (date: Date) => void;
  onCancel?: () => void;
  dateConfig?: object;
  confirmText?: string;
  cancelText?: string;
  showHeader?: boolean;
  customHeader?: JSX.Element;
  input: {
    value?: any;
    onChange?: (date: string) => {};
  };
  label: string;
  isOpen: boolean;
  closeModal: () => void;
}

interface MobileDatePickerProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class MobileDatePicker extends PureComponent<MobileDatePickerProps> {
  public render() {
    const { classes, t, isOpen, closeModal, input } = this.props;

    const monthMap: any = {
      1: t('JAN'),
      2: t('FEB'),
      3: t('MAR'),
      4: t('APR'),
      5: t('MAY'),
      6: t('JUN'),
      7: t('JUL'),
      8: t('AUG'),
      9: t('SEP'),
      10: t('OCT'),
      11: t('NOV'),
      12: t('DEC'),
    };
    const dateConfig = {
      date: {
        format: 'DD',
        step: 1,
      },
      month: {
        format: (value: Date) => {
          const month = value.getMonth();
          const monthIndex = month + 1;

          return monthMap[monthIndex];
        },
        step: 1,
      },
      year: {
        format: 'YYYY',
        step: 1,
      },
    };

    return isOpen ? (
      <DateTimePickerModal
        value={input.value}
        onChange={this.handleSelect}
        closeModal={closeModal}
        dateConfig={dateConfig}
        header={t('SELECT_DATE')}
        className="with-default-kiosk-components-theme"
      />
    ) : null;
  }

  private handleSelect = (date: Date | undefined) => {
    const {
      input: { onChange },
      closeModal,
    } = this.props;
    if (!date) return;
    if (onChange) onChange(DateManager.getServerFormattedDate(date));
    closeModal();
  };
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(MobileDatePicker) as (props: PassedProps) => JSX.Element;
