const FULL_CHECKOUT_INVOICE_SUBMIT_REQUEST =
  'FULL_CHECKOUT_INVOICE_SUBMIT_REQUEST';
const FULL_CHECKOUT_INVOICE_SUBMIT_SUCCESS =
  'FULL_CHECKOUT_INVOICE_SUBMIT_SUCCESS';
const FULL_CHECKOUT_INVOICE_SUBMIT_FAILURE =
  'FULL_CHECKOUT_INVOICE_SUBMIT_FAILURE';

const FOLIO_PROFILE_UPDATE_REQUEST = 'FOLIO_PROFILE_UPDATE_REQUEST';
const FOLIO_PROFILE_UPDATE_SUCCESS = 'FOLIO_PROFILE_UPDATE_SUCCESS';
const FOLIO_PROFILE_UPDATE_FAILURE = 'FOLIO_PROFILE_UPDATE_FAILURE';

const COMPANY_INVOICE_FOLIO_ADD_REQUEST = 'COMPANY_INVOICE_FOLIO_ADD_REQUEST';
const COMPANY_INVOICE_FOLIO_ADD_SUCCESS = 'COMPANY_INVOICE_FOLIO_ADD_SUCCESS';
const COMPANY_INVOICE_FOLIO_ADD_FAILURE = 'COMPANY_INVOICE_FOLIO_ADD_FAILURE';

const ON_FOLIO_PROFILE_UPDATE_REQUEST = 'ON_FOLIO_PROFILE_UPDATE_REQUEST';
const ON_FOLIO_PROFILE_UPDATE_SUCCESS = 'ON_FOLIO_PROFILE_UPDATE_SUCCESS';
const ON_FOLIO_PROFILE_UPDATE_FAILURE = 'ON_FOLIO_PROFILE_UPDATE_FAILURE';

const ON_BILLING_INSTRUCTIONS_ADD_REQUEST =
  'ON_BILLING_INSTRUCTIONS_ADD_REQUEST';
const ON_BILLING_INSTRUCTIONS_ADD_SUCCESS =
  'ON_BILLING_INSTRUCTIONS_ADD_SUCCESS';
const ON_BILLING_INSTRUCTIONS_ADD_FAILURE =
  'ON_BILLING_INSTRUCTIONS_ADD_FAILURE';

const ON_TRANSACTIONS_TRANSFER_ADD_REQUEST =
  'ON_TRANSACTIONS_TRANSFER_ADD_REQUEST';
const ON_TRANSACTIONS_TRANSFER_ADD_SUCCESS =
  'ON_TRANSACTIONS_TRANSFER_ADD_SUCCESS';
const ON_TRANSACTIONS_TRANSFER_ADD_FAILURE =
  'ON_TRANSACTIONS_TRANSFER_ADD_FAILURE';

const FETCH_ADDITIONAL_FOLIOS_INFO_REQUEST =
  'FETCH_ADDITIONAL_FOLIOS_INFO_REQUEST';
const FETCH_ADDITIONAL_FOLIOS_INFO_SUCCESS =
  'FETCH_ADDITIONAL_FOLIOS_INFO_SUCCESS';
const FETCH_ADDITIONAL_FOLIOS_INFO_FAILURE =
  'FETCH_ADDITIONAL_FOLIOS_INFO_FAILURE';

const LINK_PAYMENT_TO_NEW_FOLIO_REQUEST = 'LINK_PAYMENT_TO_NEW_FOLIO_REQUEST';
const LINK_PAYMENT_TO_NEW_FOLIO_SUCCESS = 'LINK_PAYMENT_TO_NEW_FOLIO_SUCCESS';
const LINK_PAYMENT_TO_NEW_FOLIO_FAILURE = 'LINK_PAYMENT_TO_NEW_FOLIO_FAILURE';

const UNLINK_PAYMENT_FROM_FOLIO_REQUEST = 'UNLINK_PAYMENT_FROM_FOLIO_REQUEST';
const UNLINK_PAYMENT_FROM_FOLIO_SUCCESS = 'UNLINK_PAYMENT_FROM_FOLIO_SUCCESS';
const UNLINK_PAYMENT_FROM_FOLIO_FAILURE = 'UNLINK_PAYMENT_FROM_FOLIO_FAILURE';

const SELECT_COMPANY = 'SELECT_COMPANY';

const TOGGLE_COMPANY_SELECT = 'TOGGLE_COMPANY_SELECT';

export default {
  FULL_CHECKOUT_INVOICE_SUBMIT_REQUEST,
  FULL_CHECKOUT_INVOICE_SUBMIT_SUCCESS,
  FULL_CHECKOUT_INVOICE_SUBMIT_FAILURE,
  COMPANY_INVOICE_FOLIO_ADD_REQUEST,
  COMPANY_INVOICE_FOLIO_ADD_SUCCESS,
  COMPANY_INVOICE_FOLIO_ADD_FAILURE,
  FOLIO_PROFILE_UPDATE_REQUEST,
  FOLIO_PROFILE_UPDATE_SUCCESS,
  FOLIO_PROFILE_UPDATE_FAILURE,
  ON_FOLIO_PROFILE_UPDATE_REQUEST,
  ON_FOLIO_PROFILE_UPDATE_SUCCESS,
  ON_FOLIO_PROFILE_UPDATE_FAILURE,
  ON_BILLING_INSTRUCTIONS_ADD_REQUEST,
  ON_BILLING_INSTRUCTIONS_ADD_SUCCESS,
  ON_BILLING_INSTRUCTIONS_ADD_FAILURE,
  ON_TRANSACTIONS_TRANSFER_ADD_REQUEST,
  ON_TRANSACTIONS_TRANSFER_ADD_SUCCESS,
  ON_TRANSACTIONS_TRANSFER_ADD_FAILURE,
  FETCH_ADDITIONAL_FOLIOS_INFO_REQUEST,
  FETCH_ADDITIONAL_FOLIOS_INFO_SUCCESS,
  FETCH_ADDITIONAL_FOLIOS_INFO_FAILURE,
  LINK_PAYMENT_TO_NEW_FOLIO_REQUEST,
  LINK_PAYMENT_TO_NEW_FOLIO_SUCCESS,
  LINK_PAYMENT_TO_NEW_FOLIO_FAILURE,
  UNLINK_PAYMENT_FROM_FOLIO_REQUEST,
  UNLINK_PAYMENT_FROM_FOLIO_SUCCESS,
  UNLINK_PAYMENT_FROM_FOLIO_FAILURE,
  SELECT_COMPANY,
  TOGGLE_COMPANY_SELECT,
};
