import { useEffect } from 'react';

import { NotAllowedError, QRCodeInvalidError, UnknownError } from './errors';
import { ScanDataType } from './types';

interface PassedProps {
  onLoad?: () => void;
  onError?: (scanError: Error) => void;
  onScan: (data: string | null) => void;
}

export const KioskProQRScanner = ({
  onScan,
  onLoad,
  onError,
}: PassedProps): null => {
  const startScan = (): void => {
    window.kp_ZBarScanner_startScan?.();
  };

  const onScanFinish = (data: string, codeType: ScanDataType): void => {
    if (codeType !== ScanDataType.QRCode) {
      onError?.(new QRCodeInvalidError());

      return;
    }

    onScan(data);
  };

  const onScanCancel = (): null => null;

  const onScanError = (errorCode: number): void => {
    let scanError: Error = new UnknownError();

    if (errorCode === -1002 || errorCode === -1001) {
      scanError = new NotAllowedError();
    }

    onError?.(scanError);
  };

  const cleanUpCallbacks = (): void => {
    window.kp_ZBarScanner_scanDidFinishWithDataEx = undefined;
    window.kp_ZBarScanner_scanDidCancel = undefined;
    window.kp_ZBarScanner_scanDidFinishWithTimeout = undefined;
    window.kp_ZBarScanner_scanDidFailWithError = undefined;
  };

  const assignCallbacks = (): void => {
    window.kp_ZBarScanner_scanDidFinishWithDataEx = onScanFinish;
    window.kp_ZBarScanner_scanDidCancel = onScanCancel;
    window.kp_ZBarScanner_scanDidFinishWithTimeout = onScanCancel;
    window.kp_ZBarScanner_scanDidFailWithError = onScanError;
  };

  useEffect(() => {
    onLoad?.();
    assignCallbacks();
    startScan();

    return (): void => {
      cleanUpCallbacks();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
