import { TFunction } from 'i18next';

import { ApiError, isApiError } from '@ac/library-api';

import { knownErrorCodes } from '@gss/configs/knownErrorCodes';

import { CustomError } from './customErrors';

export const getErrorMessage = (
  errors: Array<ApiError | Error>,
  t: TFunction
): string | undefined => {
  const customError = (errors as CustomError[]).find(
    (error) => error instanceof CustomError
  );

  if (customError) return customError.message;

  const knownErrors = Object.values(knownErrorCodes);

  let errorMessage: string | undefined;

  const apiErrors = (errors as Array<{ data?: unknown }>)
    .map((error) => error?.data)
    .filter(isApiError);

  apiErrors?.forEach((error) =>
    error.details?.some((element) => {
      const recognizedError = knownErrors.find(
        (item) => item.code === element.code
      );

      if (recognizedError) {
        return (errorMessage = recognizedError.message
          ? t(recognizedError.message)
          : undefined);
      }
    })
  );

  return errorMessage;
};
