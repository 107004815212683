import { useTranslation } from 'react-i18next';

import {
  AlignItems,
  Flex,
  FlexDirection,
  IconTypes,
  Loader,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { PropsTypes } from '../propsTypes';

export const Pending = ({ keyNumber, amount }: PropsTypes): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Flex
      direction={FlexDirection.column}
      alignItems={AlignItems.center}
      className="spacing-top-md spacing-bottom-lg gap-lg"
    >
      <Loader icon={IconTypes.cardKey} />
      <Text size={TextSize.xlg}>
        {t('VIEWS.KEY_FLOW.COMPONENTS.KEY_GENERATION_MODAL.MAKE_KEY_COUNTER', {
          current: keyNumber,
          max: amount,
        })}
      </Text>
      <Text>
        {t('VIEWS.KEY_FLOW.COMPONENTS.KEY_GENERATION_MODAL.PLEASE_WAIT_KEYS')}
      </Text>
    </Flex>
  );
};
