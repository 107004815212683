import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Body, Footer, FormHeader, ReservationHeader, View } from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import { destroy, getFormValues, initialize, submit } from 'redux-form';
import { isFetching as isFetchingAvailabilityState } from 'store/availability/selectors';
import { fetchReservationOutstandingDeposit } from 'store/cashiering/reservationOutstandingDeposit/actions';
import { getReservationOutstandingDeposit } from 'store/cashiering/reservationOutstandingDeposit/selectors';
import { getAddons, getPurchaseItems } from 'store/cashiering/selectors';
import {
  getIsPEChangesBeyondLimit,
  getReservation,
  isFetching,
} from 'store/reservation/selectors';
import { PurchaseItem } from 'types/Api/Availability';
import { ApiError, Money } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';
import {
  configurePrepaymentSteps,
  isPrepaymentAvailable,
} from 'views/CheckInPrepayment/routing';

import { Button, ButtonPattern, LoadingView } from '@ac/kiosk-components';

import { Header } from '@gss/components/layout';
import { fetchInventoryItemsAvailability } from '@LEGACY/store/availability/actions';
import { resetUpdatePurchaseElementLimitStatus } from '@LEGACY/store/reservation/actions';
import { getErrors } from '@LEGACY/store/selectors';
import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import { AddonsUpdateBeyondLimitModal } from './AddonsUpdateBeyondLimitModal/AddonsUpdateBeyondLimitModal';
import {
  getOnlyPreAdded,
  getPurchaseElementsFromFirstNightRate,
  getPurchaseElementsFromLastNightRate,
} from './store/selectors';
import AddonsMenu from './AddonsMenu';
import styles from './CheckInAddons.style';
import SkipModal from './SkipModal';

const { SHOW_PREPAYMENT } = Configurator.switchCodes;

interface PassedProps {}

interface CheckInAddonsProps
  extends PassedProps,
    WithTranslation,
    RouteComponentProps,
    WithStyles<typeof styles> {
  submit: (form: string) => void;
  destroy: (form: string) => void;
  isSubmitting: boolean;
  isFetching: boolean;
  addons: PurchaseItem[];
  purchaseItems: PurchaseItem[];
  nights: any;
  guestNumber: any;
  orderedAddons?: {
    [key: string]: number;
  };
  onlyPreAdded: PurchaseItem[];
  purchaseElementsFromFirstNightRate: PurchaseItem[];
  purchaseElementsFromLastNightRate: PurchaseItem[];
  initialize: typeof initialize;
  fetchReservationOutstandingDeposit: typeof fetchReservationOutstandingDeposit;
  fetchInventoryItemsAvailability: typeof fetchInventoryItemsAvailability;
  resetUpdatePurchaseElementLimitStatus: typeof resetUpdatePurchaseElementLimitStatus;
  outstandingDeposit: Money;
  isFetchingAvailability: boolean;
  isPEChangesBeyondLimit: boolean;
  errors: Array<Error | ApiError>;
}

interface CheckInAddonsState {
  isSubmitDisabled: boolean;
  isSkipModalOpen: boolean;
}

class CheckInAddons extends PureComponent<
  CheckInAddonsProps,
  CheckInAddonsState
> {
  public state = {
    isLoadingData: false,
    isSubmitDisabled: true,
    isSkipModalOpen: false,
  };

  public componentDidMount() {
    this.props.fetchInventoryItemsAvailability();
  }

  public render() {
    const {
      t,
      addons,
      isFetching,
      purchaseItems,
      classes,
      errors,
      orderedAddons,
      onlyPreAdded,
      purchaseElementsFromFirstNightRate,
      purchaseElementsFromLastNightRate,
      isPEChangesBeyondLimit,
      isFetchingAvailability,
    } = this.props;
    const { isSkipModalOpen } = this.state;
    const showAlreadyAdded =
      onlyPreAdded.length ||
      purchaseElementsFromFirstNightRate.length ||
      purchaseElementsFromLastNightRate.length;
    const isContinueDisabled =
      isFetching || isFetchingAvailability || !orderedAddons;

    return (
      <View modal={{ isLoading: isFetching, values: errors }}>
        <SkipModal
          isOpen={isSkipModalOpen}
          onContinue={this.onSkip}
          onCancel={this.toggleSkipModal}
        />
        {isPEChangesBeyondLimit && (
          <AddonsUpdateBeyondLimitModal
            onClick={this.resetUpdatePELimitStatus}
          />
        )}
        <Grid className={classes.headerWrapper}>
          <Header title={`${t('CHECK_IN')} - ${t('ENHANCE_YOUR_STAY')}`} />
          <ReservationHeader />
        </Grid>
        <Body className={classes.body}>
          {!isFetchingAvailability && (
            <>
              {showAlreadyAdded ? (
                <>
                  <FormHeader
                    className={classes.formHeader}
                    title={t('ENHANCE_YOUR_STAY')}
                  />
                  <AddonsMenu
                    showAlreadyAdded
                    addons={addons}
                    purchaseItems={purchaseItems}
                  />
                </>
              ) : null}
              <Grid className={classes.addonsWrapper}>
                <Typography className={classes.categoryTitle}>
                  {t(
                    showAlreadyAdded
                      ? 'AVAILABLE_ENHANCEMENTS'
                      : 'ENHANCE_YOUR_STAY'
                  )}
                </Typography>
                {this.renderCurrency()}
              </Grid>
              <AddonsMenu addons={addons} purchaseItems={purchaseItems} />
            </>
          )}
        </Body>
        <Footer
          hasCancelButton
          hasContinueButton
          continueButtonLabel={t('CONFIRM')}
          isContinueDisabled={isContinueDisabled}
          routeName={t('CHECK_IN')}
          onContinue={this.submit}
        >
          <div className={classes.footerPanel}>
            <Button pattern={ButtonPattern.secondary} onClick={this.goBack}>
              {t('BACK')}
            </Button>
            <Button
              pattern={ButtonPattern.secondary}
              onClick={this.handleSkipClick}
              className="spacing-left-sm"
            >
              {t('NO_THANK_YOU')}
            </Button>
          </div>
        </Footer>
      </View>
    );
  }

  private submit = () => {
    const { submit } = this.props;
    submit(ids.ADDONS_FORM);
  };

  private resetUpdatePELimitStatus = async () => {
    const {
      initialize,
      fetchInventoryItemsAvailability,
      resetUpdatePurchaseElementLimitStatus,
      orderedAddons,
    } = this.props;

    resetUpdatePurchaseElementLimitStatus();

    await fetchInventoryItemsAvailability();

    if (orderedAddons) {
      const newFormData = Object.fromEntries(
        Object.entries(orderedAddons).filter(([key]) => {
          return this.props.addons.some((item) => item.id === key);
        })
      );

      initialize(ids.ADDONS_FORM, newFormData);
    }
  };
  private changeRoute = (route: string) => {
    const { history } = this.props;

    return history.push(route);
  };

  private goNext = async () => {
    await this.configureRouting();

    return this.changeRoute(Router.nextStepURL);
  };

  private goBack = () => this.changeRoute(Router.prevStepURL);

  private handleSkipClick = async () => {
    if (this.shouldSkipModalOpen()) return this.toggleSkipModal();

    return this.goNext();
  };

  private shouldSkipModalOpen = () => {
    const { orderedAddons } = this.props;
    if (!orderedAddons) return false;
    const values = Object.values(orderedAddons);
    if (!values.length) return false;

    return values.some((item) => !!item);
  };

  private onSkip = async () => {
    const { destroy } = this.props;
    await destroy(ids.ADDONS_FORM);

    return this.goNext();
  };

  private toggleSkipModal = () => {
    const { isSkipModalOpen } = this.state;

    return this.setState({ isSkipModalOpen: !isSkipModalOpen });
  };

  private renderCurrency = () => {
    const { t, classes } = this.props;

    return (
      <Grid className={classes.currencyWrapper}>
        <Typography className={classes.currencyTitle}>{`${t(
          'CURRENCY'
        )}:`}</Typography>
        <Typography className={classes.title}>
          {Configurator.propertySettings.currencyCode}
        </Typography>
      </Grid>
    );
  };

  private configureRouting = async () => {
    const { fetchReservationOutstandingDeposit, outstandingDeposit } =
      this.props;
    await fetchReservationOutstandingDeposit();
    const showPrepayment =
      isPrepaymentAvailable(outstandingDeposit.amount) &&
      Configurator.getSwitch(SHOW_PREPAYMENT);
    configurePrepaymentSteps(showPrepayment);
  };
}

const mapStateToProps = (state: Store, props: CheckInAddonsProps) => ({
  errors: getErrors(state),
  isFetching: isFetching(state),
  reservation: getReservation(state),
  orderedAddons: getFormValues(ids.ADDONS_FORM)(state),
  purchaseItems: getPurchaseItems(state),
  addons: getAddons(state, props),
  onlyPreAdded: getOnlyPreAdded(state),
  purchaseElementsFromFirstNightRate:
    getPurchaseElementsFromFirstNightRate(state),
  purchaseElementsFromLastNightRate:
    getPurchaseElementsFromLastNightRate(state),
  outstandingDeposit: getReservationOutstandingDeposit(state),
  isFetchingAvailability: isFetchingAvailabilityState(state),
  isPEChangesBeyondLimit: getIsPEChangesBeyondLimit(state),
});

const mapDispatchToProps = {
  submit,
  destroy,
  initialize,
  fetchReservationOutstandingDeposit,
  fetchInventoryItemsAvailability,
  resetUpdatePurchaseElementLimitStatus,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInAddons) as (props: PassedProps) => JSX.Element;
