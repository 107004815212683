import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { View } from '@gss/components/layout';
import { useRouter } from '@gss/router';
import { closeCheckInProcess } from '@gss/store/flows/checkInFlow/actions';
import { getCustomMessages } from '@gss/store/settings/selectors';

type Props = React.ComponentProps<typeof View>;

export const CheckInView = ({
  children,
  withInactivityCheck = true,
  onInactivity,
  onErrorModalSubmit,
  ...props
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const router = useRouter();

  const customMessages = useSelector(getCustomMessages);

  const cancelMakeKeysProcess = useCallback(() => {
    dispatch(
      closeCheckInProcess.trigger((): void => {
        router.goTo('WELCOME', { replace: true });
      })
    );
  }, [dispatch, router]);

  return (
    <View
      {...props}
      onErrorModalSubmit={onErrorModalSubmit || cancelMakeKeysProcess}
      withInactivityCheck={withInactivityCheck}
      onInactivity={onInactivity || cancelMakeKeysProcess}
      errorMessage={customMessages?.CHECK_IN_FAILED_MESSAGE}
    >
      {children}
    </View>
  );
};
