/**
 * Handler that will be called if application works in Kiosk Prop environment only
 * @param callback provide callback that will be called for KPE environment
 */
export const handleKioskProDevice = (callback: () => void): void => {
  window.kp_VersionAPI_requestProductName_callback = callback;
  window.kp_VersionAPI_requestProductName?.(
    'kp_VersionAPI_requestProductName_callback'
  );
};
