import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Field,
  FormHeader,
  Grid,
  Section,
  SelectField,
} from '@ac/kiosk-components';
import { Form, formFieldFactory } from '@ac/react-infrastructure';

import { Body, Header } from '@gss/components/layout';
import { useRouter } from '@gss/router';
import { changeGuestProfile } from '@gss/store/flows/checkInFlow/actions';
import { getTitles } from '@gss/store/settings/selectors';
import { mapSelectOptions } from '@gss/utils';
import { mapFieldRenderProps } from '@gss/utils/form';

import { CheckInFooter, CheckInView } from '../components';

import {
  CreateProfileFormValues,
  useCreateProfileFormValidator,
} from './formConfig';

const FormField = formFieldFactory<CreateProfileFormValues>();

export const CheckInNameDetails = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const validator = useCreateProfileFormValidator();

  const titles = useSelector(getTitles);

  const titleOptions = useMemo(() => {
    return mapSelectOptions(titles, 'description', 'id');
  }, [titles]);

  const handleFormSubmit = useCallback(
    (formData: CreateProfileFormValues): void => {
      dispatch(
        changeGuestProfile.trigger({
          details: formData,
          callback: router.goToNextStep,
        })
      );
    },
    [dispatch, router]
  );

  return (
    <CheckInView>
      <Form
        initialValues={{}}
        validate={validator.validate}
        destroyOnUnregister
        onSubmit={handleFormSubmit}
      >
        {(formRenderProp): JSX.Element => (
          <>
            <Header
              title={[
                t('CHECK_IN_FLOW.TITLE'),
                t('CHECK_IN_FLOW.VIEWS.NAME_DETAILS.HEADER'),
              ].join(' - ')}
            />
            <Body>
              <Section>
                <FormHeader
                  className="spacing-bottom-xxlg spacing-top-sm"
                  title={t(
                    'CHECK_IN_FLOW.VIEWS.NAME_DETAILS.FORM_SECTION.TITLE'
                  )}
                  hint={t('CHECK_IN_FLOW.VIEWS.NAME_DETAILS.FORM_SECTION.HINT')}
                />
                <Grid
                  gridTemplateColumnsSm="1fr 1fr"
                  gridTemplateRowsSm="auto"
                  className="gap-lg"
                >
                  <Grid.Item>
                    <FormField valuePath="titleId">
                      {(fieldRenderProps): JSX.Element => (
                        <SelectField
                          {...mapFieldRenderProps(fieldRenderProps)}
                          optional
                          label={t(
                            'CHECK_IN_FLOW.VIEWS.NAME_DETAILS.FORM_SECTION.FIELDS.TITLE'
                          )}
                          placeholder={t(
                            'CHECK_IN_FLOW.VIEWS.NAME_DETAILS.FORM_SECTION.FIELDS.TITLE'
                          )}
                          options={titleOptions}
                        />
                      )}
                    </FormField>
                  </Grid.Item>
                  <Grid.Item />
                  <Grid.Item>
                    <FormField valuePath="firstName">
                      {(fieldRenderProps): JSX.Element => (
                        <Field
                          {...mapFieldRenderProps(fieldRenderProps)}
                          label={t(
                            'CHECK_IN_FLOW.VIEWS.NAME_DETAILS.FORM_SECTION.FIELDS.FIRST_NAME'
                          )}
                          placeholder={t(
                            'CHECK_IN_FLOW.VIEWS.NAME_DETAILS.FORM_SECTION.FIELDS.FIRST_NAME'
                          )}
                        />
                      )}
                    </FormField>
                  </Grid.Item>
                  <Grid.Item>
                    <FormField valuePath="lastName">
                      {(fieldRenderProps): JSX.Element => (
                        <Field
                          {...mapFieldRenderProps(fieldRenderProps)}
                          label={t(
                            'CHECK_IN_FLOW.VIEWS.NAME_DETAILS.FORM_SECTION.FIELDS.LAST_NAME'
                          )}
                          placeholder={t(
                            'CHECK_IN_FLOW.VIEWS.NAME_DETAILS.FORM_SECTION.FIELDS.LAST_NAME'
                          )}
                        />
                      )}
                    </FormField>
                  </Grid.Item>
                </Grid>
              </Section>
            </Body>
            <CheckInFooter
              hasCancelButton
              hasPrimaryButton
              isPrimaryDisabled={formRenderProp.invalid}
              onPrimaryClick={formRenderProp.handleSubmit}
            />
          </>
        )}
      </Form>
    </CheckInView>
  );
};
