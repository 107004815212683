import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  BasicModalSize,
  ErrorModal,
  Field,
  FormHeader,
  Grid,
  IconTypes,
  LargeButton,
  Section,
  Text,
} from '@ac/kiosk-components';
import { Form, FormApi, formFieldFactory } from '@ac/react-infrastructure';

import { Body, Header } from '@gss/components/layout';
import { QRScannerModal, ScannerData } from '@gss/components/modals';
import { useRouter } from '@gss/router';
import {
  resetAuthProcess,
  startCheckInProcess,
} from '@gss/store/flows/checkInFlow/actions';
import {
  getCheckInAuthProcessStatus,
  getIsCheckInProcessReady,
  getIsCheckInSecondFactorNeeded,
  getIsMultiRoomReservation,
  getIsReservationWithAccompanyGuests,
} from '@gss/store/flows/checkInFlow/selectors';
import { getGeneralSettings } from '@gss/store/settings/selectors';
import {
  AuthProcessDataSource,
  AuthProcessStatus,
} from '@gss/types/authProcess';
import { mapFieldRenderProps } from '@gss/utils/form';

import { CheckInFooter, CheckInView } from '../components';

import { CheckInAuthFormValues, useValidator } from './formConfig';

const FormField = formFieldFactory<CheckInAuthFormValues>();

export const CheckInAuth = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const validator = useValidator();

  const [isQRScannerOpen, setQRScannerOpen] = useState(false);

  const isSecondFactorVisible = useSelector(getIsCheckInSecondFactorNeeded);
  const authenticationStatus = useSelector(getCheckInAuthProcessStatus);
  const generalSettings = useSelector(getGeneralSettings);
  const isCheckInProcessReady = useSelector(getIsCheckInProcessReady);
  const isMultiRoomReservation = useSelector(getIsMultiRoomReservation);
  const isReservationHasAccompanyGuests = useSelector(
    getIsReservationWithAccompanyGuests
  );

  const clearProcess = (): void => {
    dispatch(resetAuthProcess());
  };

  const toggleQRScanner = (): void => {
    setQRScannerOpen((isOpen) => !isOpen);
  };

  const handleQRScan = (formData: ScannerData): void => {
    toggleQRScanner();
    handleFormSubmit(formData);
  };

  const handleFormSubmit = (
    formData: CheckInAuthFormValues | ScannerData,
    formApi?: FormApi<CheckInAuthFormValues>
  ): void => {
    dispatch(
      startCheckInProcess({
        guestLastName: formData.lastName,
        confirmationNumber: formData.identificationNumber,
        dataSource: formApi
          ? AuthProcessDataSource.form
          : AuthProcessDataSource.qrCode,
      })
    );
  };

  useEffect(() => {
    if (isCheckInProcessReady) {
      router.goToNextStep();
    } else if (isMultiRoomReservation) {
      router.goTo('CHECK_IN_MULTI_ROOM');
    } else if (isReservationHasAccompanyGuests) {
      router.goTo('CHECK_IN_ACCOMPANYING_GUESTS');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCheckInProcessReady,
    isMultiRoomReservation,
    isReservationHasAccompanyGuests,
  ]);

  return (
    <CheckInView>
      {isQRScannerOpen && (
        <QRScannerModal
          onClose={toggleQRScanner}
          onScanComplete={handleQRScan}
        />
      )}
      <Form initialValues={{}} destroyOnUnregister onSubmit={handleFormSubmit}>
        {(formRenderProp): JSX.Element => (
          <>
            {authenticationStatus === AuthProcessStatus.failed && (
              <ErrorModal
                className="with-default-kiosk-components-theme"
                description={t('CHECK_IN_FLOW.VIEWS.AUTH.CHECK_IN_UNAVAILABLE')}
                size={BasicModalSize.sm}
                onConfirm={(): void => {
                  clearProcess();
                  formRenderProp.form.reset();
                }}
              />
            )}
            <Header title={t('CHECK_IN_FLOW.TITLE')} />
            <Body>
              <Section>
                <FormHeader
                  className="spacing-bottom-xxlg"
                  title={t('CHECK_IN_FLOW.VIEWS.AUTH.QR_CODE_SECTION.TITLE')}
                  hint={t('CHECK_IN_FLOW.VIEWS.AUTH.QR_CODE_SECTION.HINT')}
                />
                <LargeButton
                  icon={IconTypes.qrCode}
                  className="spacing-bottom-xxlg"
                  onClick={toggleQRScanner}
                >
                  {t('CHECK_IN_FLOW.VIEWS.AUTH.QR_CODE_SECTION.SCAN')}
                </LargeButton>
                <FormHeader
                  className="spacing-bottom-xxlg"
                  title={t('CHECK_IN_FLOW.VIEWS.AUTH.FORM_SECTION.TITLE')}
                  hint={t('CHECK_IN_FLOW.VIEWS.AUTH.FORM_SECTION.HINT')}
                />
                <Grid
                  gridTemplateColumnsSm={
                    generalSettings?.CHECK_IN_BY_SURNAME_ONLY
                      ? '1fr'
                      : '1fr 1fr'
                  }
                  gridTemplateRowsSm="auto"
                  className="gap-lg"
                >
                  <Grid.Item>
                    <FormField
                      valuePath="lastName"
                      validate={validator.validateSingleField}
                    >
                      {(fieldRenderProps): JSX.Element => (
                        <Field
                          {...mapFieldRenderProps(fieldRenderProps)}
                          label={t(
                            'CHECK_IN_FLOW.VIEWS.AUTH.FORM_SECTION.LAST_NAME'
                          )}
                          placeholder={t(
                            'CHECK_IN_FLOW.VIEWS.AUTH.FORM_SECTION.LAST_NAME'
                          )}
                        />
                      )}
                    </FormField>
                  </Grid.Item>
                  {!generalSettings?.CHECK_IN_BY_SURNAME_ONLY ||
                    (isSecondFactorVisible && (
                      <Grid.Item>
                        <Text hint className="spacing-bottom-lg">
                          {t(
                            'CHECK_IN_FLOW.VIEWS.AUTH.FORM_SECTION.ADDITIONAL_FIELD_HINT'
                          )}
                        </Text>
                        <FormField
                          valuePath="identificationNumber"
                          validate={validator.validateSingleField}
                        >
                          {(fieldRenderProps): JSX.Element => (
                            <Field
                              {...mapFieldRenderProps(fieldRenderProps)}
                              label={t(
                                'CHECK_IN_FLOW.VIEWS.AUTH.FORM_SECTION.CONFIRMATION_NO'
                              )}
                              placeholder={t(
                                'CHECK_IN_FLOW.VIEWS.AUTH.FORM_SECTION.CONFIRMATION_NO'
                              )}
                            />
                          )}
                        </FormField>
                      </Grid.Item>
                    ))}
                </Grid>
              </Section>
            </Body>
            <CheckInFooter
              hasCancelButton
              hasPrimaryButton
              isPrimaryDisabled={formRenderProp.invalid}
              onPrimaryClick={formRenderProp.handleSubmit}
              primaryLabel={t('CHECK_IN_FLOW.VIEWS.AUTH.CONTINUE_BUTTON')}
            />
          </>
        )}
      </Form>
    </CheckInView>
  );
};
