/* eslint-disable max-len */
const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

const FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_REQUEST =
  'FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_REQUEST';
const FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_SUCCESS =
  'FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_SUCCESS';
const FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_FAILURE =
  'FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_FAILURE';

const FETCH_COMPANY_COMMUNICATION_CHANNELS_REQUEST =
  'FETCH_COMPANY_COMMUNICATION_CHANNELS_REQUEST';
const FETCH_COMPANY_COMMUNICATION_CHANNELS_SUCCESS =
  'FETCH_COMPANY_COMMUNICATION_CHANNELS_SUCCESS';
const FETCH_COMPANY_COMMUNICATION_CHANNELS_FAILURE =
  'FETCH_COMPANY_COMMUNICATION_CHANNELS_FAILURE';

const FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_REQUEST =
  'FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_REQUEST';
const FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_SUCCESS =
  'FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_SUCCESS';
const FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_FAILURE =
  'FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_FAILURE';

const UPDATE_GUEST_COMMUNICATION_CHANNELS_REQUEST =
  'UPDATE_GUEST_COMMUNICATION_CHANNELS_REQUEST';
const UPDATE_GUEST_COMMUNICATION_CHANNELS_SUCCESS =
  'UPDATE_GUEST_COMMUNICATION_CHANNELS_SUCCESS';
const UPDATE_GUEST_COMMUNICATION_CHANNELS_FAILURE =
  'UPDATE_GUEST_COMMUNICATION_CHANNELS_FAILURE';

const UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_REQUEST =
  'UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_REQUEST';
const UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_SUCCESS =
  'UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_SUCCESS';
const UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_FAILURE =
  'UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_FAILURE';

const UPDATE_COMPANY_COMMUNICATION_CHANNELS_REQUEST =
  'UPDATE_COMPANY_COMMUNICATION_CHANNELS_REQUEST';
const UPDATE_COMPANY_COMMUNICATION_CHANNELS_SUCCESS =
  'UPDATE_COMPANY_COMMUNICATION_CHANNELS_SUCCESS';
const UPDATE_COMPANY_COMMUNICATION_CHANNELS_FAILURE =
  'UPDATE_COMPANY_COMMUNICATION_CHANNELS_FAILURE';

const UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_REQUEST =
  'UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_REQUEST';
const UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_SUCCESS =
  'UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_SUCCESS';
const UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_FAILURE =
  'UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_FAILURE';

const UPDATE_COMPANY_ADDRESSES_REQUEST = 'UPDATE_COMPANY_ADDRESSES_REQUEST';
const UPDATE_COMPANY_ADDRESSES_SUCCESS = 'UPDATE_COMPANY_ADDRESSES_SUCCESS';
const UPDATE_COMPANY_ADDRESSES_FAILURE = 'UPDATE_COMPANY_ADDRESSES_FAILURE';

const UPDATE_COMPANY_DETAILS_REQUEST = 'UPDATE_COMPANY_DETAILS_REQUEST';
const UPDATE_COMPANY_DETAILS_SUCCESS = 'UPDATE_COMPANY_DETAILS_SUCCESS';
const UPDATE_COMPANY_DETAILS_FAILURE = 'UPDATE_COMPANY_DETAILS_FAILURE';

const FETCH_MEMBERSHIPS_REQUEST = 'FETCH_MEMBERSHIPS_REQUEST';
const FETCH_MEMBERSHIPS_SUCCESS = 'FETCH_MEMBERSHIPS_SUCCESS';
const FETCH_MEMBERSHIPS_FAILURE = 'FETCH_MEMBERSHIPS_FAILURE';

const ADD_CONSENTS_REQUEST = 'ADD_CONSENTS_REQUEST';
const ADD_CONSENTS_SUCCESS = 'ADD_CONSENTS_SUCCESS';
const ADD_CONSENTS_FAILURE = 'ADD_CONSENTS_FAILURE';

const REVOKE_CONSENTS_REQUEST = 'REVOKE_CONSENTS_REQUEST';
const REVOKE_CONSENTS_SUCCESS = 'REVOKE_CONSENTS_SUCCESS';
const REVOKE_CONSENTS_FAILURE = 'REVOKE_CONSENTS_FAILURE';

const FETCH_COMPANY_REQUEST = 'FETCH_COMPANY_REQUEST';
const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
const FETCH_COMPANY_FAILURE = 'FETCH_COMPANY_FAILURE';

const CREATE_COMPANY_PROFILE_REQUEST = 'CREATE_COMPANY_PROFILE_REQUEST';
const CREATE_COMPANY_PROFILE_SUCCESS = 'CREATE_COMPANY_PROFILE_SUCCESS';
const CREATE_COMPANY_PROFILE_FAILURE = 'CREATE_COMPANY_PROFILE_FAILURE';

const CREATE_INDIVIDUAL_PROFILE_REQUEST = 'CREATE_INDIVIDUAL_PROFILE_REQUEST';
const CREATE_INDIVIDUAL_PROFILE_SUCCESS = 'CREATE_INDIVIDUAL_PROFILE_SUCCESS';
const CREATE_INDIVIDUAL_PROFILE_FAILURE = 'CREATE_INDIVIDUAL_PROFILE_FAILURE';

const LINK_COMPANY_PROFILE_REQUEST = 'LINK_COMPANY_PROFILE_REQUEST';
const LINK_COMPANY_PROFILE_SUCCESS = 'LINK_COMPANY_PROFILE_SUCCESS';
const LINK_COMPANY_PROFILE_FAILURE = 'LINK_COMPANY_PROFILE_FAILURE';

const FETCH_CREATE_COMPANY_STATUS_REQUEST =
  'FETCH_CREATE_COMPANY_STATUS_REQUEST';
const FETCH_CREATE_COMPANY_STATUS_SUCCESS =
  'FETCH_CREATE_COMPANY_STATUS_SUCCESS';
const FETCH_CREATE_COMPANY_STATUS_FAILURE =
  'FETCH_CREATE_COMPANY_STATUS_FAILURE';

const FETCH_CREATE_INDIVIDUAL_STATUS_REQUEST =
  'FETCH_CREATE_INDIVIDUAL_STATUS_REQUEST';
const FETCH_CREATE_INDIVIDUAL_STATUS_SUCCESS =
  'FETCH_CREATE_INDIVIDUAL_STATUS_SUCCESS';
const FETCH_CREATE_INDIVIDUAL_STATUS_FAILURE =
  'FETCH_CREATE_INDIVIDUAL_STATUS_FAILURE';

const CLEAR_PROFILE_ERRORS = 'CLEAR_PROFILE_ERRORS';

const CHOOSE_PROFILE = 'CHOOSE_PROFILE';

const FETCH_TRAVEL_AGENTS_REQUEST = 'FETCH_TRAVEL_AGENTS_REQUEST';
const FETCH_TRAVEL_AGENTS_SUCCESS = 'FETCH_TRAVEL_AGENTS_SUCCESS';
const FETCH_TRAVEL_AGENTS_FAILURE = 'FETCH_TRAVEL_AGENTS_FAILURE';

const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';

const FETCH_PROFILE_FROM_FOLIO_REQUEST = 'FETCH_PROFILE_FROM_FOLIO_REQUEST';
const FETCH_PROFILE_FROM_FOLIO_SUCCESS = 'FETCH_PROFILE_FROM_FOLIO_SUCCESS';
const FETCH_PROFILE_FROM_FOLIO_FAILURE = 'FETCH_PROFILE_FROM_FOLIO_FAILURE';

const FETCH_PROFILE_VERSION_REQUEST = 'FETCH_PROFILE_VERSION_REQUEST';
const FETCH_PROFILE_VERSION_SUCCESS = 'FETCH_PROFILE_VERSION_SUCCESS';
const FETCH_PROFILE_VERSION_FAILURE = 'FETCH_PROFILE_VERSION_FAILURE';

const FETCH_PROFILES_VIEWS_REQUEST = 'FETCH_PROFILES_VIEWS_REQUEST';
const FETCH_PROFILES_VIEWS_SUCCESS = 'FETCH_PROFILES_VIEWS_SUCCESS';
const FETCH_PROFILES_VIEWS_FAILURE = 'FETCH_PROFILES_VIEWS_FAILURE';

const CHECK_EDIT_EMAIL_FORM = 'CHECK_EDIT_EMAIL_FORM';

const FETCH_PROFILE_LIST_REQUEST = 'FETCH_PROFILE_LIST_REQUEST';
const FETCH_PROFILE_LIST_SUCCESS = 'FETCH_PROFILE_LIST_SUCCESS';
const FETCH_PROFILE_LIST_FAILURE = 'FETCH_PROFILE_LIST_FAILURE';

const UPDATE_PROFILE_DETAILS_REQUEST = 'UPDATE_PROFILE_DETAILS_REQUEST';
const UPDATE_PROFILE_DETAILS_SUCCESS = 'UPDATE_PROFILE_DETAILS_SUCCESS';
const UPDATE_PROFILE_DETAILS_FAILURE = 'UPDATE_PROFILE_DETAILS_FAILURE';

const CHECK_PROFILE_DUPLICATES_REQUEST = 'CHECK_PROFILE_DUPLICATES_REQUEST';
const CHECK_PROFILE_DUPLICATES_SUCCESS = 'CHECK_PROFILE_DUPLICATES_SUCCESS';
const CHECK_PROFILE_DUPLICATES_FAILURE = 'CHECK_PROFILE_DUPLICATES_FAILURE';

const FETCH_INTERNAL_MEMBERSHIPS_REQUEST = 'FETCH_INTERNAL_MEMBERSHIPS_REQUEST';
const FETCH_INTERNAL_MEMBERSHIPS_SUCCESS = 'FETCH_INTERNAL_MEMBERSHIPS_SUCCESS';
const FETCH_INTERNAL_MEMBERSHIPS_FAILURE = 'FETCH_INTERNAL_MEMBERSHIPS_FAILURE';

const SET_GUEST_ACCEPTED_PROFILE_DUPLICATE_RISK =
  'SET_GUEST_ACCEPTED_PROFILE_DUPLICATE_RISK';

export default {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,
  FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_REQUEST,
  FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_SUCCESS,
  FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_FAILURE,
  FETCH_COMPANY_COMMUNICATION_CHANNELS_REQUEST,
  FETCH_COMPANY_COMMUNICATION_CHANNELS_SUCCESS,
  FETCH_COMPANY_COMMUNICATION_CHANNELS_FAILURE,
  FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_REQUEST,
  FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_SUCCESS,
  FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_FAILURE,
  UPDATE_GUEST_COMMUNICATION_CHANNELS_REQUEST,
  UPDATE_GUEST_COMMUNICATION_CHANNELS_SUCCESS,
  UPDATE_GUEST_COMMUNICATION_CHANNELS_FAILURE,
  UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_REQUEST,
  UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_SUCCESS,
  UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_FAILURE,
  UPDATE_COMPANY_COMMUNICATION_CHANNELS_REQUEST,
  UPDATE_COMPANY_COMMUNICATION_CHANNELS_SUCCESS,
  UPDATE_COMPANY_COMMUNICATION_CHANNELS_FAILURE,
  UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_REQUEST,
  UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_SUCCESS,
  UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_FAILURE,
  UPDATE_COMPANY_ADDRESSES_REQUEST,
  UPDATE_COMPANY_ADDRESSES_SUCCESS,
  UPDATE_COMPANY_ADDRESSES_FAILURE,
  UPDATE_COMPANY_DETAILS_REQUEST,
  UPDATE_COMPANY_DETAILS_SUCCESS,
  UPDATE_COMPANY_DETAILS_FAILURE,
  FETCH_MEMBERSHIPS_REQUEST,
  FETCH_MEMBERSHIPS_SUCCESS,
  FETCH_MEMBERSHIPS_FAILURE,
  ADD_CONSENTS_REQUEST,
  ADD_CONSENTS_SUCCESS,
  ADD_CONSENTS_FAILURE,
  REVOKE_CONSENTS_REQUEST,
  REVOKE_CONSENTS_SUCCESS,
  REVOKE_CONSENTS_FAILURE,
  LINK_COMPANY_PROFILE_REQUEST,
  LINK_COMPANY_PROFILE_SUCCESS,
  LINK_COMPANY_PROFILE_FAILURE,
  CREATE_COMPANY_PROFILE_REQUEST,
  CREATE_COMPANY_PROFILE_SUCCESS,
  CREATE_COMPANY_PROFILE_FAILURE,
  CREATE_INDIVIDUAL_PROFILE_REQUEST,
  CREATE_INDIVIDUAL_PROFILE_SUCCESS,
  CREATE_INDIVIDUAL_PROFILE_FAILURE,
  FETCH_CREATE_COMPANY_STATUS_REQUEST,
  FETCH_CREATE_COMPANY_STATUS_SUCCESS,
  FETCH_CREATE_COMPANY_STATUS_FAILURE,
  FETCH_CREATE_INDIVIDUAL_STATUS_REQUEST,
  FETCH_CREATE_INDIVIDUAL_STATUS_SUCCESS,
  FETCH_CREATE_INDIVIDUAL_STATUS_FAILURE,
  CLEAR_PROFILE_ERRORS,
  CHOOSE_PROFILE,
  FETCH_TRAVEL_AGENTS_REQUEST,
  FETCH_TRAVEL_AGENTS_SUCCESS,
  FETCH_TRAVEL_AGENTS_FAILURE,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_PROFILE_FROM_FOLIO_REQUEST,
  FETCH_PROFILE_FROM_FOLIO_SUCCESS,
  FETCH_PROFILE_FROM_FOLIO_FAILURE,
  FETCH_PROFILE_VERSION_REQUEST,
  FETCH_PROFILE_VERSION_SUCCESS,
  FETCH_PROFILE_VERSION_FAILURE,
  FETCH_PROFILES_VIEWS_REQUEST,
  FETCH_PROFILES_VIEWS_SUCCESS,
  FETCH_PROFILES_VIEWS_FAILURE,
  CHECK_EDIT_EMAIL_FORM,
  FETCH_PROFILE_LIST_REQUEST,
  FETCH_PROFILE_LIST_SUCCESS,
  FETCH_PROFILE_LIST_FAILURE,
  UPDATE_PROFILE_DETAILS_REQUEST,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS_FAILURE,
  CHECK_PROFILE_DUPLICATES_REQUEST,
  CHECK_PROFILE_DUPLICATES_SUCCESS,
  CHECK_PROFILE_DUPLICATES_FAILURE,
  FETCH_INTERNAL_MEMBERSHIPS_REQUEST,
  FETCH_INTERNAL_MEMBERSHIPS_SUCCESS,
  FETCH_INTERNAL_MEMBERSHIPS_FAILURE,
  SET_GUEST_ACCEPTED_PROFILE_DUPLICATE_RISK,
};
