import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  KioskComponentsConfigurator,
  LogReporter,
  SelectField,
  SelectFieldType,
  SelectOption,
  ServiceModal,
} from '@ac/kiosk-components';

import { LinkedDevices, LinkedDevicesType } from '@gss/components/utility';
import { EXPORTED_FILES_NAMES, LOG_TYPES } from '@gss/configs/constants';
import { useRouter } from '@gss/router';
import {
  fetchDeviceList,
  setUsedDeviceId,
} from '@gss/store/configuration/actions';
import {
  getFetchingDeviceState,
  getLoggedUser,
  getUsedDeviceDetails,
  getValidDevices,
} from '@gss/store/configuration/selectors';
import { getPropertyConfiguration } from '@gss/store/settings/selectors';
import { mapSelectOptions } from '@gss/utils';

interface EnvironmentSettingsModalProps {
  dataTestSelector?: string;
  onCancelClick: () => void;
}

export const EnvironmentSettingsModal = ({
  dataTestSelector,
  onCancelClick,
}: EnvironmentSettingsModalProps): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const loggedUser = useSelector(getLoggedUser);
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const usedDeviceDetails = useSelector(getUsedDeviceDetails);
  const isFetchingDevices = useSelector(getFetchingDeviceState);
  const devices = useSelector(getValidDevices);
  const isEditWorkStationVisible = router.isCurrentStep('WELCOME');

  const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>(
    usedDeviceDetails?.deviceId
  );

  const deviceOptions = useMemo((): Array<SelectOption<string>> => {
    const availableDevices = devices?.filter(
      (device) =>
        !device.isOnline || device.deviceId === usedDeviceDetails?.deviceId
    );

    return mapSelectOptions(availableDevices, 'deviceName', 'deviceId');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  const propertyName = useMemo((): string | undefined => {
    if (!propertyConfiguration) return;
    const { code, name } = propertyConfiguration;

    return name && code ? `${name} (${code})` : '';
  }, [propertyConfiguration]);

  const handleSaveClick = (): void => {
    if (!selectedDeviceId) return;
    dispatch(setUsedDeviceId(selectedDeviceId));
  };

  const handleLogoutClick = (): void => {
    onCancelClick();
    router.goTo('LOGOUT', { replace: true });
  };

  const handleDebugModeClick = (): void => {
    KioskComponentsConfigurator.openConsoleSimulator();
  };

  useEffect(() => {
    if (isEditWorkStationVisible) {
      dispatch(fetchDeviceList.trigger());
    }
  }, [isEditWorkStationVisible, dispatch]);

  return (
    <ServiceModal
      dataTestSelector={dataTestSelector}
      authLogsEnabled
      authLogs={LogReporter.getLogs(LOG_TYPES.auth)}
      errorLogsEnabled
      errorLogs={LogReporter.getLogs(LOG_TYPES.app)}
      onCancelClick={onCancelClick}
      onDebugModeClick={handleDebugModeClick}
      onLogoutClick={handleLogoutClick}
      isSaveButtonDisabled={
        !!selectedDeviceId && usedDeviceDetails?.deviceId === selectedDeviceId
      }
      onSaveClick={handleSaveClick}
      systemInfoForKpeEnabled
      exportAuthLogsFileName={EXPORTED_FILES_NAMES.auth}
      exportErrorLogsFileName={EXPORTED_FILES_NAMES.app}
      propertyName={propertyName}
      userName={loggedUser?.userName}
      className="with-default-kiosk-components-theme"
    >
      {isEditWorkStationVisible && (
        <>
          <SelectField
            className="spacing-top-lg"
            label={t('VIEWS.DEVICE.TITLE')}
            placeholder={t('SHARED.SELECT')}
            value={selectedDeviceId}
            optionsLoading={isFetchingDevices}
            options={deviceOptions}
            onChange={setSelectedDeviceId}
            type={SelectFieldType.inline}
          />

          {usedDeviceDetails && (
            <LinkedDevices
              mainDevice={usedDeviceDetails}
              type={LinkedDevicesType.short}
            />
          )}
        </>
      )}
    </ServiceModal>
  );
};
