import {
  createAction,
  createAsyncAction,
  getListAction,
} from '@ac/library-utils/dist/redux-utils';

import {
  KioskLoggedUserDto,
  SelfServiceDeviceDto,
  SelfServicePropertyDto,
} from '@gss/api/KioskApi/entries';

import { ChangeLanguagePayload, PropertyPermissionsState } from './interfaces';

const ACTION_PREFIX = '@configuration/';

export const prepareApplication = createAsyncAction<undefined, undefined>(
  `${ACTION_PREFIX}prepareApplication`
);

export const preparePropertyConfiguration = createAsyncAction<
  string,
  PropertyPermissionsState
>(`${ACTION_PREFIX}preparePropertyConfiguration`);

export const setCurrentUser = createAction<KioskLoggedUserDto>(
  `${ACTION_PREFIX}setCurrentUser`
);

export const setCurrentProperty = createAction<null | string>(
  `${ACTION_PREFIX}setCurrentProperty`
);

export const setIsKioskProEnv = createAction<boolean>(
  `${ACTION_PREFIX}setIsKioskProEnv`
);

export const rebootApplication = createAction(
  `${ACTION_PREFIX}rebootApplication`
);

export const resetApplication = createAction(
  `${ACTION_PREFIX}resetApplication`
);

export const logoutApplication = createAction(
  `${ACTION_PREFIX}logoutApplication`
);

export const fetchPropertyList = getListAction<
  undefined,
  SelfServicePropertyDto[]
>(`${ACTION_PREFIX}fetchPropertyList`);

export const fetchDeviceList = getListAction<undefined, SelfServiceDeviceDto[]>(
  `${ACTION_PREFIX}fetchDeviceList`
);

export const setUsedDeviceId = createAction<null | string>(
  `${ACTION_PREFIX}setUsedDeviceId`
);

export const setPreselectedDeviceId = createAction<null | string>(
  `${ACTION_PREFIX}setPreselectedDeviceId`
);

export const changeAppLanguage = createAsyncAction<
  ChangeLanguagePayload,
  ChangeLanguagePayload
>(`${ACTION_PREFIX}changeAppLanguage`);
