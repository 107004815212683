import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';
import { useSelector } from 'react-redux';

import {
  BasicModal,
  BasicModalSize,
  Box,
  Button,
  ErrorModal,
  Flex,
  FlexDirection,
  Loader,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { getIsKioskProEnv } from '@gss/store/configuration/selectors';
import { BaseObject } from '@gss/types/shared';

import { QRCodeInvalidError } from './errors';
import { KioskProQRScanner } from './KioskProQRScanner';
import { ScannerData } from './types';

import './QRScannerModal.scss';

interface QRScannerModalProps {
  onScanComplete?: (data: ScannerData) => void;
  onClose?: () => void;
}

export const QRScannerModal = ({
  onScanComplete,
  onClose,
}: QRScannerModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorData, setDataError] = useState<string>();
  const isKioskPro = useSelector(getIsKioskProEnv);

  const errorTypes = useRef<BaseObject<string>>({
    NotFoundError: t('COMPONENTS.MODALS.QRSCANNER.ERRORS.NO_VIDEO_DEVICES'),
    NoVideoInputDevicesError: t(
      'COMPONENTS.MODALS.QRSCANNER.ERRORS.NO_VIDEO_DEVICES'
    ),
    NotAllowedError: t('COMPONENTS.MODALS.QRSCANNER.ERRORS.PERMISSION_DENIED'),
    QRCodeInvalidError: t(
      'COMPONENTS.MODALS.QRSCANNER.ERRORS.QR_CODE_IS_INVALID'
    ),
    UnknownError: t('COMPONENTS.MODALS.QRSCANNER.ERRORS.UNKNOWN_ERROR'),
  });

  const disableLoader = (): void => {
    setIsLoading(false);
  };

  const clearErrors = (): void => {
    setDataError(undefined);
  };

  const handleError = (error: Error): void => {
    const errorOutput: string | undefined = errorTypes.current[error.name];
    setDataError(errorOutput || errorTypes.current.UnknownError);
  };

  const handleScan = (data: string | null): void => {
    try {
      if (!data || errorData) return;
      const values = JSON.parse(data) as ScannerData;

      if (!values.lastName || !values.identificationNumber) {
        throw new QRCodeInvalidError();
      }

      onScanComplete?.(values);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <BasicModal
        size={BasicModalSize.md}
        className="with-default-kiosk-components-theme qrscanner-modal-container"
      >
        <Flex
          direction={FlexDirection.column}
          className="gap-lg spacing-top-sm"
        >
          <Text className="text-center" size={TextSize.xlg}>
            {t('COMPONENTS.MODALS.QRSCANNER.TITLE')}
          </Text>
          <Text className="text-center">
            {t('COMPONENTS.MODALS.QRSCANNER.DESCRIPTION')}
          </Text>
          <Box className="qrscanner-scanner-preview-container">
            {isLoading && (
              <Loader
                className="qrscanner-scanner-loader"
                description={t('COMPONENTS.MODALS.QRSCANNER.LOADING_SCANNER')}
              />
            )}

            <Box className={isLoading ? 'hidden' : undefined}>
              {isKioskPro ? (
                <KioskProQRScanner
                  onLoad={disableLoader}
                  onScan={handleScan}
                  onError={handleError}
                />
              ) : (
                <QrReader
                  facingMode="user"
                  showViewFinder={false}
                  className="qrscanner-scanner-preview"
                  onLoad={disableLoader}
                  onScan={handleScan}
                  onError={handleError}
                />
              )}
            </Box>
          </Box>
          <Button onClick={onClose} className="spacing-auto">
            {t('SHARED.CLOSE')}
          </Button>
        </Flex>
      </BasicModal>

      {errorData && (
        <ErrorModal
          className="with-default-kiosk-components-theme"
          description={errorData}
          onConfirm={clearErrors}
        />
      )}
    </>
  );
};
