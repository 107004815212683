import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Body,
  Button,
  ButtonPattern,
  FormHeader,
  Header,
  LoadingView,
  Section,
  SectionType,
  SelectField,
  SelectOption,
  TextWeight,
  View,
} from '@ac/kiosk-components';

import { ShijiWhiteLogo } from '@gss/assets/images';
import { Footer } from '@gss/components/layout';
import { useRouter } from '@gss/router';
import { Storage } from '@gss/services';
import {
  fetchPropertyList,
  preparePropertyConfiguration,
} from '@gss/store/configuration/actions';
import {
  getFetchingPropertiesState,
  getProperties,
  getUsedPropertyId,
} from '@gss/store/configuration/selectors';
import { mapSelectOptions } from '@gss/utils';

export const Login = (): JSX.Element => {
  const router = useRouter();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentPropertyId = useSelector(getUsedPropertyId);
  const isFetchingProperties = useSelector(getFetchingPropertiesState);
  const properties = useSelector(getProperties);

  const [settingUp, setSettingUp] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState<string>();

  const propertyOptions = useMemo((): Array<SelectOption<string>> => {
    return mapSelectOptions(properties, 'name', 'id');
  }, [properties]);

  const handleContinueClick = (): void => {
    if (!selectedProperty) return;
    dispatch(preparePropertyConfiguration.trigger(selectedProperty));
  };

  const handleLogoutClick = (): void => {
    router?.goTo('LOGOUT', { replace: true });
  };

  useEffect(() => {
    if (!currentPropertyId) {
      dispatch(fetchPropertyList.trigger());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!properties) return;

    if (currentPropertyId) {
      return router?.goToNextStep();
    }

    const cachedPropertyId = Storage.getPropertyId();
    const isCachedPropertyValid = properties.some(
      (property) => property.id === cachedPropertyId
    );

    if (cachedPropertyId && isCachedPropertyValid) {
      dispatch(preparePropertyConfiguration.trigger(cachedPropertyId));
    } else {
      setSettingUp(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPropertyId, properties]);

  return settingUp ? (
    <LoadingView
      className="with-default-kiosk-components-theme"
      description={t('SHARED.AUTHORIZING')}
    />
  ) : (
    <View className="with-default-kiosk-components-theme">
      <Header logo={ShijiWhiteLogo} title={t('VIEWS.LOGIN.TITLE')} />
      <Body className="padding-vertical-lg">
        <Section type={SectionType.wide}>
          <FormHeader
            titleWeight={TextWeight.regular}
            title={t('VIEWS.LOGIN.FORM_HEADER')}
          />
          <SelectField
            className="spacing-top-lg"
            label={t('VIEWS.LOGIN.TITLE')}
            placeholder={t('SHARED.SELECT')}
            optionsLoading={isFetchingProperties}
            options={propertyOptions}
            onChange={setSelectedProperty}
          />
        </Section>
      </Body>
      <Footer
        hasPrimaryButton
        isPrimaryDisabled={!selectedProperty}
        onPrimaryClick={handleContinueClick}
      >
        <Button onClick={handleLogoutClick} pattern={ButtonPattern.secondary}>
          {t('VIEWS.LOGIN.LOGOUT_BUTTON')}
        </Button>
      </Footer>
    </View>
  );
};
