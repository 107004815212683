import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { View } from '@gss/components/layout';
import { useRouter } from '@gss/router';
import { closeMakeKeyProcess } from '@gss/store/flows/makeKeysFlow/actions';

type MakeKeysViewProps = React.ComponentProps<typeof View>;

export const MakeKeysView = ({
  children,
  withInactivityCheck = true,
  onInactivity,
  onErrorModalSubmit,
  ...props
}: MakeKeysViewProps): JSX.Element => {
  const dispatch = useDispatch();
  const router = useRouter();

  const cancelMakeKeysProcess = useCallback(() => {
    dispatch(
      closeMakeKeyProcess.trigger((): void => {
        router.goTo('WELCOME', { replace: true });
      })
    );
  }, [dispatch, router]);

  return (
    <View
      {...props}
      onErrorModalSubmit={onErrorModalSubmit || cancelMakeKeysProcess}
      withInactivityCheck={withInactivityCheck}
      onInactivity={onInactivity || cancelMakeKeysProcess}
    >
      {children}
    </View>
  );
};
