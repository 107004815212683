export class NotAllowedError extends Error {
  name = 'NotAllowedError';
}

export class QRCodeInvalidError extends Error {
  name = 'QRCodeInvalidError';
}

export class UnknownError extends Error {
  name = 'UnknownError';
}
