import { createSelector } from 'reselect';

import { ApiError, DeviceType } from '@ac/library-api';

import {
  KioskLoggedUserDto,
  SelfServiceDeviceDto,
  SelfServiceDeviceTypeDto,
  SelfServicePropertyDto,
} from '@gss/api/KioskApi/entries';
import { Store } from '@gss/store';

import { PropertyPermissionsState } from './interfaces';

export const getIsAppInitialized = (state: Store): boolean =>
  state.configuration.isAppInitialized;

export const getIsKioskProEnv = (state: Store): boolean =>
  state.configuration.isKioskProEnv;

export const getConfigurationErrors = (state: Store): Array<ApiError | Error> =>
  state.configuration.errors;

export const getFetchingPropertiesState = (state: Store): boolean =>
  state.configuration.fetching.properties;

export const getFetchingDeviceState = (state: Store): boolean =>
  state.configuration.fetching.devices;

export const getRestartApplicationState = (state: Store): boolean =>
  state.configuration.fetching.restartApplication;

export const getChangeAppLanguageState = (state: Store): boolean =>
  state.configuration.fetching.changeAppLanguage;

export const getLoggingOutState = (state: Store): boolean =>
  state.configuration.fetching.loggingOut;

export const getProperties = (
  state: Store
): SelfServicePropertyDto[] | undefined => state.configuration.properties;

export const getDevices = (state: Store): SelfServiceDeviceDto[] | undefined =>
  state.configuration.devices;

export const getValidDevices = createSelector(
  [getDevices],
  (
    devices: SelfServiceDeviceDto[] | undefined
  ): SelfServiceDeviceDto[] | undefined => {
    return devices?.filter((device) => device.isValid);
  }
);

export const getOfflineValidDevices = createSelector(
  [getValidDevices],
  (
    devices: SelfServiceDeviceDto[] | undefined
  ): SelfServiceDeviceDto[] | undefined => {
    return devices?.filter((device) => !device.isOnline);
  }
);

export const getLoggedUser = (state: Store): KioskLoggedUserDto | undefined =>
  state.configuration.loggedUser;

export const getUsedPropertyId = (state: Store): string | undefined =>
  state.configuration.usedPropertyId;

export const getUsedDeviceId = (state: Store): string | undefined =>
  state.configuration.usedDeviceId;

export const getPreselectedDeviceId = (state: Store): string | undefined =>
  state.configuration.preselectedDeviceId;

export const getValidatedPreselectedDeviceId = createSelector(
  [getPreselectedDeviceId, getOfflineValidDevices],
  (
    preselectedDeviceId: string | undefined,
    devices: SelfServiceDeviceDto[] | undefined
  ) => {
    return devices?.find(({ deviceId }) => deviceId === preselectedDeviceId)
      ?.deviceId;
  }
);

export const getPropertyPermissions = (
  state: Store
): PropertyPermissionsState | undefined =>
  state.configuration.propertyPermissions;

export const getUsedDeviceDetails = createSelector(
  [getUsedDeviceId, getDevices],
  (
    usedDeviceId: string | undefined,
    devices: SelfServiceDeviceDto[] | undefined
  ): SelfServiceDeviceDto | undefined => {
    return devices?.find(({ deviceId }) => deviceId === usedDeviceId);
  }
);

export const getWorkstationId = createSelector(
  [getUsedDeviceDetails],
  (device: SelfServiceDeviceDto | undefined): string | undefined => {
    return device?.workstationId;
  }
);

export const getSelfServiceDeviceId = createSelector(
  [getUsedDeviceDetails],
  (device: SelfServiceDeviceDto | undefined): string | undefined => {
    return device?.deviceId;
  }
);

export const getPaymentDevice = createSelector(
  [getUsedDeviceDetails],
  (
    device: SelfServiceDeviceDto | undefined
  ): SelfServiceDeviceTypeDto | undefined => {
    return device?.workstationDevices?.find(
      (linkedDevice) => linkedDevice.type?.code === DeviceType.PaymentDevice
    );
  }
);

export const getScanningDevice = createSelector(
  [getUsedDeviceDetails],
  (
    device: SelfServiceDeviceDto | undefined
  ): SelfServiceDeviceTypeDto | undefined => {
    return device?.workstationDevices?.find(
      (linkedDevice) => linkedDevice.type?.code === DeviceType.PassportScanner
    );
  }
);
