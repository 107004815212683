import {
  DefaultErrorType,
  FieldRenderProps,
  RadioButtonFieldRenderProps,
  SingleCheckboxFieldRenderProps,
} from '@ac/react-infrastructure';

import { translateValidationResult } from './translateValidationResult';

interface KioskComponentFieldRenderProps {
  name: string;
  onChange: (value: string | Event | undefined) => void;
  onFocus: () => void;
  onBlur: () => void;
  value: string | undefined;
  validationStatus: DefaultErrorType;
}

interface KioskComponentRadioFieldRenderProps {
  name: string;
  checked: boolean;
  onChange: () => void;
  onFocus: () => void;
  onBlur: () => void;
  value: string | undefined;
}

interface KioskComponentCheckboxFieldRenderProps {
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  onBlur: () => void;
}

export const mapFieldRenderProps = ({
  input: { name, validationStatus, value, onChange, onFocusIn, onFocusOut },
}: FieldRenderProps<string | undefined>): KioskComponentFieldRenderProps => {
  return {
    name,
    onChange,
    onFocus: onFocusIn,
    onBlur: onFocusOut,
    value,
    validationStatus: translateValidationResult(validationStatus),
  };
};

export const mapRadioFieldRenderProps = ({
  input: { name, checked, value, onChange, onFocusIn, onFocusOut },
}: RadioButtonFieldRenderProps<
  string | undefined
>): KioskComponentRadioFieldRenderProps => {
  return {
    name,
    checked,
    onChange,
    onFocus: onFocusIn,
    onBlur: onFocusOut,
    value,
  };
};

export const mapCheckboxFieldRenderProps = ({
  input: { name, checked, onChange, onFocusIn, onFocusOut },
}: SingleCheckboxFieldRenderProps): KioskComponentCheckboxFieldRenderProps => {
  return {
    name,
    checked,
    onChange: (e: React.ChangeEvent<HTMLInputElement>): void =>
      onChange(e.target.checked),
    onFocus: onFocusIn,
    onBlur: onFocusOut,
  };
};
