import { Action } from 'redux-actions';

import { handleLocalFlowReducer } from '@gss/store/storeConfiguration/utils';
import { ReduxReducerMap } from '@gss/types/shared';
import { CustomError } from '@gss/utils/errors';

import * as actions from './actions';
import { KeyGenerationStatus, KeyGeneratorStepState } from './interfaces';
import { GenerateKeyError } from './utils';

export const initialState: KeyGeneratorStepState = {
  generateKeyStatus: KeyGenerationStatus.initial,
  amountOfCreatedKeys: 0,
  errors: [],
};

const reducerMap: ReduxReducerMap<KeyGeneratorStepState> = {
  [actions.generateKey.trigger]: (state): KeyGeneratorStepState => {
    return {
      ...state,
      generateKeyStatus: KeyGenerationStatus.pending,
    };
  },

  [actions.generateKey.success]: (state): KeyGeneratorStepState => {
    return {
      ...state,
      generateKeyStatus: KeyGenerationStatus.success,
      amountOfCreatedKeys: state.amountOfCreatedKeys + 1,
    };
  },

  [actions.generateKey.failure]: (
    state,
    action: Action<Error & CustomError>
  ): KeyGeneratorStepState => {
    const newState = {
      ...state,
      generateKeyStatus: KeyGenerationStatus.failed,
    };

    if (action.payload.errorName !== GenerateKeyError.errorName) {
      newState.errors = [...state.errors, action.payload];
    }

    return newState;
  },

  [actions.resetGenerateKeyStatus]: (state): KeyGeneratorStepState => {
    return {
      ...state,
      generateKeyStatus: KeyGenerationStatus.initial,
    };
  },
};

export const keyGeneratorStep = handleLocalFlowReducer<KeyGeneratorStepState>(
  reducerMap,
  initialState
);
