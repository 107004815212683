import { useRef } from 'react';

import { createRequiredValidator } from '@ac/react-infrastructure';

import { FormValidator } from '@gss/utils/form';

import { CheckInAuthFormValues } from './types';

export const useValidator = (): FormValidator<CheckInAuthFormValues> => {
  const validator = useRef(
    new FormValidator<CheckInAuthFormValues>({
      identificationNumber: createRequiredValidator(
        'VALIDATION_MESSAGES.FIELD_IS_MANDATORY'
      ),
      lastName: createRequiredValidator(
        'VALIDATION_MESSAGES.FIELD_IS_MANDATORY'
      ),
    })
  );

  return validator.current;
};
