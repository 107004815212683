import { createSelector } from 'reselect';

import { ApiError } from '@ac/library-api';

import { MakeKeysReservationDetailsDto } from '@gss/api/KioskApi/entries';
import { Store } from '@gss/store';
import { getMakeKeysReservationDetails } from '@gss/store/flows/makeKeysFlow/selectors';

import { KeyGenerationStatus } from './interfaces';

export const getKeyGeneratorStepErrors = (
  state: Store
): Array<ApiError | Error> => state.keyGeneratorStep.errors;

export const getPossibleKeysAmountToGenerate = createSelector(
  [getMakeKeysReservationDetails],
  (reservationDetails: MakeKeysReservationDetailsDto | undefined): number => {
    const firstDayBreakdown = reservationDetails?.breakdown[0];
    const adultCount = firstDayBreakdown?.adultCount || 0;
    const childCount = firstDayBreakdown?.childCount || 0;

    return adultCount < 2 ? 2 : adultCount + childCount;
  }
);

export const getGenerateKeyStatus = (state: Store): KeyGenerationStatus =>
  state.keyGeneratorStep.generateKeyStatus;

export const getGenerateKeysAmount = (state: Store): number =>
  state.keyGeneratorStep.amountOfCreatedKeys;
