import {
  all,
  call,
  put,
  select,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import {
  EmptyInterface,
  LibraryApiResponse,
  LibraryApiResponseHeader,
  PageResponseDto,
} from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';

import { SelfServiceCheckInProcessApi } from '@gss/api/KioskApi';
import {
  CheckInAvailableReservationDto,
  CheckInProfileDetailsDto,
  CheckInReservationDetailsDto,
} from '@gss/api/KioskApi/entries';
import { API_HEADERS } from '@gss/configs/constants';
import { getSelfServiceDeviceId } from '@gss/store/configuration/selectors';
import { AuthProcessDataSource } from '@gss/types/authProcess';
import { SagasGenerator } from '@gss/types/shared';
import {
  AuthFailedError,
  AuthTooManyReservationsFoundError,
  isAuthEntityNotFoundError,
  isAuthTooManyReservationsError,
} from '@gss/utils/errors';

import { changeAppLanguage } from '../../configuration/actions';

import * as actions from './actions';
import {
  ChangeGuestProfilePayload,
  StartCheckInProcessPayload,
  StartCheckInSessionPayload,
} from './interfaces';
import {
  getCheckInAuthData,
  getCheckInAvailableReservations,
  getCheckInProcessId,
  getCheckInReservationDetails,
  getIsCheckInGuestAuthenticated,
  getIsCheckInProcessReady,
  getIsMultiRoomReservation,
  getIsReservationWithAccompanyGuests,
} from './selectors';

const AUTH_REQUEST_PAGING_SETUP = {
  pageNumber: '1',
  pageSize: '200',
};

export function* authenticateGuestSaga(
  action: Action<StartCheckInProcessPayload>
): SagasGenerator {
  try {
    const { confirmationNumber, guestLastName } = action.payload;

    const response: LibraryApiResponse<
      PageResponseDto<CheckInAvailableReservationDto>
    > = yield SelfServiceCheckInProcessApi.getAvailableReservations({
      data: {
        ...AUTH_REQUEST_PAGING_SETUP,
        confirmationNumber,
        guestLastName,
      },
      customConfig: {
        iterations: 0,
      },
    });

    if (response.data.results.length > 1 && !confirmationNumber) {
      throw new AuthTooManyReservationsFoundError();
    }

    yield put(actions.authenticateGuest.success(response.data.results));
  } catch (error) {
    if (isAuthEntityNotFoundError(error)) {
      yield put(actions.authenticateGuest.failure(new AuthFailedError()));
    } else if (isAuthTooManyReservationsError(error)) {
      yield put(
        actions.authenticateGuest.failure(
          action.payload.dataSource === AuthProcessDataSource.form
            ? new AuthTooManyReservationsFoundError()
            : new AuthFailedError()
        )
      );
    } else {
      yield put(actions.authenticateGuest.failure(error));
    }
  }
}

export function* startCheckInProcessSessionSaga(
  data: Action<StartCheckInSessionPayload>
): SagasGenerator {
  try {
    const { confirmationNumber, guestLastName } = yield select(
      getCheckInAuthData
    );
    const { profileId, reservationId } = data.payload;

    const deviceId: string = yield select(getSelfServiceDeviceId);

    const response: LibraryApiResponse<EmptyInterface> =
      yield SelfServiceCheckInProcessApi.stateCheckInProcess({
        data: {
          deviceId,
          profileId,
          reservationId,
          confirmationNumber,
          guestLastName,
        },
      });

    const sessionId = response.headers[
      API_HEADERS.kioskSessionId.toLowerCase() as LibraryApiResponseHeader
    ] as string;

    yield put(actions.startCheckInSession.success(sessionId));
    yield put(actions.fetchCheckInReservationDetails.trigger());
    yield put(actions.fetchCheckInProfileDetails.trigger());
  } catch (error) {
    yield put(actions.startCheckInSession.failure(error));
  }
}

export function* closeCheckInProcessSaga(
  action: Action<() => void>
): SagasGenerator {
  try {
    const processId: string | undefined = yield select(getCheckInProcessId);

    if (processId) {
      yield SelfServiceCheckInProcessApi.finishCheckInProcess({
        customConfig: {
          headers: {
            [API_HEADERS.kioskSessionId]: processId,
          },
        },
      });
    }
  } finally {
    yield put(actions.closeCheckInProcess.success());
    action.payload?.();
  }
}

export function* fetchCheckInReservationDetailsSaga(): SagasGenerator {
  try {
    const processId: string = yield select(getCheckInProcessId);

    const reservationDetails: LibraryApiResponse<CheckInReservationDetailsDto> =
      yield SelfServiceCheckInProcessApi.getCheckInReservationDetails({
        customConfig: {
          headers: {
            [API_HEADERS.kioskSessionId]: processId,
          },
        },
      });

    yield put(
      actions.fetchCheckInReservationDetails.success(reservationDetails.data)
    );
  } catch (e) {
    yield put(actions.fetchCheckInReservationDetails.failure(e));
  }
}

export function* fetchCheckInProfileDetailsSaga(): SagasGenerator {
  try {
    const processId: string = yield select(getCheckInProcessId);

    const profileDetails: LibraryApiResponse<CheckInProfileDetailsDto> =
      yield SelfServiceCheckInProcessApi.getCheckInProfileDetails({
        customConfig: {
          headers: {
            [API_HEADERS.kioskSessionId]: processId,
          },
        },
      });

    yield put(actions.fetchCheckInProfileDetails.success(profileDetails.data));
  } catch (e) {
    yield put(actions.fetchCheckInProfileDetails.failure(e));
  }
}

export function* changeGuestProfileSaga(
  action: Action<ChangeGuestProfilePayload>
): SagasGenerator {
  try {
    const data = action.payload.details;
    const processId: string = yield select(getCheckInProcessId);
    const reservation: CheckInReservationDetailsDto = yield select(
      getCheckInReservationDetails
    );

    yield SelfServiceCheckInProcessApi.changeCheckInProfile({
      data,
      customConfig: {
        headers: {
          [API_HEADERS.kioskSessionId]: processId,
          [API_HEADERS.ifMatch]: reservation.version,
        },
      },
    });

    yield put(actions.changeGuestProfile.success());
    yield call(refreshSessionDetails, action.payload.callback);
  } catch (e) {
    yield put(actions.changeGuestProfile.failure(e));
  }
}

export function* prepareCheckInProcessSaga(
  action: Action<StartCheckInProcessPayload>
): SagasGenerator {
  yield put(actions.authenticateGuest.trigger(action.payload));
  yield take([
    actions.authenticateGuest.success,
    actions.authenticateGuest.failure,
  ]);

  const isGuestAuthenticated: boolean = yield select(
    getIsCheckInGuestAuthenticated
  );
  const isMultiRoomReservation: boolean = yield select(
    getIsMultiRoomReservation
  );
  const availableReservations: CheckInAvailableReservationDto[] | undefined =
    yield select(getCheckInAvailableReservations);

  const singleReservation = availableReservations?.[0];

  if (!isGuestAuthenticated || isMultiRoomReservation || !singleReservation) {
    return;
  }

  yield put(actions.selectReservation(singleReservation.id));

  const isReservationWithAccompanyGuests: boolean = yield select(
    getIsReservationWithAccompanyGuests
  );

  if (isReservationWithAccompanyGuests || !singleReservation.guest?.id) {
    return;
  }

  yield put(actions.selectProfile(singleReservation.guest.id));

  yield put(
    actions.startCheckInSession.trigger({
      profileId: singleReservation.guest.id,
      reservationId: singleReservation.id,
    })
  );
}

export function* refreshSessionDetails(
  onComplete?: () => void
): SagasGenerator {
  const isCheckInProcessReady = yield select(getIsCheckInProcessReady);

  if (isCheckInProcessReady) {
    yield put(actions.fetchCheckInReservationDetails.trigger());
    yield put(actions.fetchCheckInProfileDetails.trigger());
  }

  yield all([
    take([
      actions.fetchCheckInReservationDetails.success,
      actions.fetchCheckInReservationDetails.failure,
    ]),
    take([
      actions.fetchCheckInProfileDetails.success,
      actions.fetchCheckInProfileDetails.failure,
    ]),
  ]);

  onComplete?.();
}

export function* checkInFlowSagas(): SagasGenerator {
  yield takeLatest(changeAppLanguage.success, refreshSessionDetails);
  yield takeLatest(
    actions.fetchCheckInReservationDetails.trigger,
    fetchCheckInReservationDetailsSaga
  );
  yield takeLatest(
    actions.fetchCheckInProfileDetails.trigger,
    fetchCheckInProfileDetailsSaga
  );
  yield takeLatest(actions.changeGuestProfile.trigger, changeGuestProfileSaga);
  yield takeLatest(actions.authenticateGuest.trigger, authenticateGuestSaga);
  yield takeLatest(
    actions.startCheckInSession.trigger,
    startCheckInProcessSessionSaga
  );
  yield takeLeading(actions.startCheckInProcess, prepareCheckInProcessSaga);
  yield takeLeading(
    actions.closeCheckInProcess.trigger,
    closeCheckInProcessSaga
  );
}
