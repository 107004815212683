import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal } from 'components';
import { ScannerData } from 'types/Api/Reservation';
import { TypographyVariant } from 'types/MaterialUI';

import { Button } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import Scanner from './Scanner';
import styles from './ScannerModal.style';

const GRID_SPACING = 2;

interface ScannerModalProps extends WithTranslation, WithStyles<typeof styles> {
  onScan: (values: ScannerData) => void;
  onToggle: () => void;
  isOpen: boolean;
}

interface ScannerModalState {
  scanError: string | null;
  isLoading: boolean;
}

class ScannerModal extends PureComponent<ScannerModalProps, ScannerModalState> {
  public state = {
    scanError: null,
    isLoading: true,
  };

  public render() {
    const { t, classes, isOpen, onToggle, onScan } = this.props;

    return (
      <Modal isOpen={isOpen} onClick={onToggle} size="wide">
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
          spacing={GRID_SPACING}
        >
          <Grid item>
            <Typography
              className={classes.title}
              variant={TypographyVariant.h5}
              align="center"
            >
              {t('SCAN_YOUR_QR_CODE')}
            </Typography>
            <Typography
              className={classes.bodyText}
              variant={TypographyVariant.body1}
              align="center"
            >
              {t('PLACE_YOUR_RESERVATION_CODE')}
            </Typography>
          </Grid>
          <Grid item>
            <Scanner onScan={onScan} />
          </Grid>
          <Grid item>
            <Button
              onClick={onToggle}
              dataTestSelector="check-in-authorzation-scan-qr-modal-close-button"
            >
              {t('CLOSE')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

export default withStyles(styles)(withTranslation()(ScannerModal));
