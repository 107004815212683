import { Code } from 'types/Api/Shared';

export enum ProfileAdditionalNameTypes {
  FathersName = 'FathersName',
  MaidenName = 'MaidenName',
  MothersName = 'MothersName',
  SecondSurname = 'SecondSurname',
}

export interface ProfileAdditionalName {
  type: ProfileAdditionalNameTypes;
  value?: string;
}

export interface Details {
  titleCode: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  nationalityCode?: string;
  vipCode: string;
  greeting?: string;
  honoraryTitleId?: string;
  gender?: string;
  birthday?: {
    day: number;
    month: number;
    year: number;
  };
  preferredCommunicationLanguageCode?: string;
  preferredLanguageDetails?: {
    languageCode: string;
    firstName?: string;
    lastName?: string;
    greeting?: string;
  };
  suffixId?: string;
  taxId?: string;
  anniversaries?: Array<{
    date: {
      day: number;
      month: number;
      year: number;
    };
    description: string;
  }>;
  company?: string;
  position?: string;
  additionalNames?: ProfileAdditionalName[];
}

export interface CompanyDetails {
  fullName: string;
  taxId?: string;
}

export interface CommunicationChannel {
  id?: string;
  typeCode: string;
  mode: string;
  details: string;
  primary: boolean;
}

export interface CommunicationChannelToSend {
  id?: string;
  typeCode: string;
  modeCode: string;
  details: string;
  isPrimary: boolean;
}

export interface CommunicationChannelUpdateData {
  add?: CommunicationChannelToSend[];
  update?: CommunicationChannelToSend[];
  remove?: string[];
}

export interface Document {
  identificationDocumentId: string;
  countryCode?: string;
  countryOfBirthCode?: string;
  dateOfBirth?: string;
  expiryDate: string;
  imageRefs?: string;
  issueDate?: string;
  issuingAuthority?: string;
  nationalityCode: string;
  number: string;
  placeOfBirth?: string;
  placeOfIssue?: string;
  typeCode: string;
}

export interface Address {
  id?: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  typeCode: string;
  postCode: string;
  stateCode: string;
  districtId?: string;
  countryCode: string;
  languageCode: string;
  primary: boolean;
  arPrimary: boolean;
}

export interface AddressApiDataModel {
  addressLine1: string;
  addressLine2?: string;
  addressTypeCode: string;
  stateCode?: string;
  districtId?: string;
  city: string;
  countryCode: string;
  isPrimary: boolean;
  isArPrimary: boolean;
  postalCode: string;
  languageCode: string;
}

export interface AddressUpdateData {
  parentVersion: string;
  addresses: AddressApiDataModel[];
}

export interface Membership {
  membershipId: string;
  levelCode?: string;
  isActive: boolean;
  number: string;
  memberSinceDate?: string;
  membershipExpiryDate?: string;
  typeCode?: string;
}

export interface ProfileConsent {
  consentId: string;
  isGranted: boolean;
  type: string;
  source: string;
  description: string;
  expiryDate?: string;
}

export interface LinkedProfileIds {
  profileRole: string;
  profileId: string;
  profilesLinkId: string;
  version: number;
}

export interface Profile {
  id: string;
  addresses: Address[];
  communicationDetails: CommunicationChannel[];
  links: LinkedProfileIds[];
  typeCode: string;
  version: number;
  consents?: ProfileConsent[];
  details: Details;
  identificationDocuments: Document[];
  memberships: Membership[];
}

export interface ProfileViews {
  id: string;
  roleCode: Code;
  typeCode: Code;
  communicationChannels: CommunicationChannel[];
  addresses: Address[];
  companyDetails?: CompanyDetails;
  travelAgentDetails?: {
    fullName: string;
  };
  individualDetails?: {
    firstName: string;
    lastName: string;
    titleCode: Code;
  };
}

export interface TravelAgent {
  id: string;
  details: Details;
  version: string;
  communicationChannels: CommunicationChannel[];
  addresses: Address[];
}

export interface CompanyListItemDetails {
  companyName: string;
  taxId: string;
  address: string;
  id?: string;
}

export interface CompanyAddressToCreate {
  addressLine1: string;
  addressLine2?: string;
  addressTypeCode: string;
  city: string;
  countryCode: string;
  districtId?: string;
  isArPrimary: boolean;
  isPrimary: true;
  languageCode?: string;
  postalCode: string;
  stateCode: null | string;
}

export interface CompanyToCreate {
  communicationChannels: CommunicationChannel[];
  addresses: CompanyAddressToCreate[];
  customFieldContainerJson: string;
  details: {
    businessSegmentCodes: any[];
    corporateId: string;
    fullName: string;
    industryCodes: any[];
    taxId: string;
  };
  externalSystemIdentifiers: any[];
  typeCode: string;
}

export interface ProfileDetailsDataModel {
  firstName: string;
  lastName: string;
  greeting?: string;
  nationalityCode?: {
    code: string;
    description: string;
  };
  titleCode?: {
    code: string;
    description: string;
  };
}

export interface BasicIndividualCreateData {
  typeCode: string;
  details: ProfileDetailsDataModel;
}
export interface LinkCompanyDataModel {
  profileId: string;
  profileRoleCode: string;
}

export interface LinkedProfile {
  id: string;
  communicationDetails?: CommunicationChannel[];
  details: CompanyDetails;
  version: number;
  addresses: Address[];
}
export interface Company {
  id: string;
  version: string;
  typeCode: Code;
  status: {
    isActive: boolean;
    isRestricted: boolean;
    isLocked: boolean;
    lockedReason: string;
    forReview: boolean;
  };
  communicationChannels?: CommunicationChannel[];
  details: CompanyDetails;
  addresses: Address[];
}

export interface LinkedProfileViews {
  id: string;
  roleCode: Code;
  typeCode: Code;
  status: {
    isActive: boolean;
    isRestricted: boolean;
    isLocked: boolean;
    lockedReason: string;
    forReview: boolean;
  };
  companyDetails?: CompanyDetails;
  travelAgentDetails?: {
    fullName: string;
  };
  individualDetails?: {
    firstName: string;
    lastName: string;
  };
  addresses: Address[];
  communicationChannels?: CommunicationChannel[];
  linkedProfilesCount: number;
  contactProfiles: ContactProfile[];
}

export interface ContactProfile {
  contactProfileId: string;
  contactProfileRole: string;
  isPrimary: boolean;
  roles: string[];
}

export interface ProfilesDataModel {
  details: string;
  email: string;
  description: string;
}

export interface DataFetchingModel {
  profile: boolean;
  company: boolean;
  individual: boolean;
  communicationChannels: boolean;
  addresses: boolean;
  consent: boolean;
  travelAgent: boolean;
  profileDetailsUpdate: boolean;
}

export enum SeverityOfMatch {
  UpperThresholdReached = 'UpperThresholdReached',
  AutoMergeLimitReached = 'AutoMergeLimitReached',
  CloseToMergeLimitReached = 'CloseToMergeLimitReached',
  LowerThresholdReached = 'LowerThresholdReached',
  BelowLowerThreshold = 'BelowLowerThreshold',
}

export interface ProfileMatchingResponseModel {
  id: string;
  highlights: Array<{
    field: string;
    value: string;
  }>;
  score: number;
  severity: SeverityOfMatch;
}

export interface IndividualDetailsMatchModel {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  nationalityCode?: string;
  birthdayAnniversaryDate?: string;
  preferredLanguageProfileDetails?: {
    localizedIndividualProfileDetails: {
      firstName?: string;
      lastName?: string;
    };
  };
}

export interface AddressesMatchModel {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  countryCode?: string;
  postalCode?: string;
  stateCode?: string;
}

export interface CommunicationChannelsMatchModel {
  email?: string;
  mobile?: string;
  socialmedia?: string;
  website?: string;
  phone?: string;
  fax?: string;
}

export interface DocumentsMatchModel {
  number: string;
}

export interface MembershipsMatchModel {
  number: string;
}

export interface ProfileMatchParamModel {
  details?: IndividualDetailsMatchModel;
  addresses?: AddressesMatchModel[];
  communicationChannels?: CommunicationChannelsMatchModel[];
  documents?: DocumentsMatchModel[];
  externalSystemIdentifiers?: string[];
  memberships?: MembershipsMatchModel[];
}
