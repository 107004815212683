import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { initialStepsRoutes } from '../routesConfigurations/initialStepsRoutes';
import { FlowType } from '../types';

const CHECK_IN_PATH = '/check-in';
const CHECK_OUT_PATH = '/check-out';
const MAKE_KEYS_PATH = '/make-keys';
const INITIAL_STEPS_PATHS = Object.values(initialStepsRoutes).map(
  ({ path }) => path
);

export const useCurrentFlowType = (): FlowType | undefined => {
  const isInitialFlow = useRouteMatch(INITIAL_STEPS_PATHS);
  const isCheckInFlow = useRouteMatch({
    path: CHECK_IN_PATH,
    exact: false,
  });
  const isCheckOutFlow = useRouteMatch({
    path: CHECK_OUT_PATH,
    exact: false,
  });
  const isMakeKeysFlow = useRouteMatch({
    path: MAKE_KEYS_PATH,
    exact: false,
  });

  return useMemo(() => {
    if (isInitialFlow) {
      return FlowType.initial;
    } else if (isCheckInFlow) {
      return FlowType.checkIn;
    } else if (isCheckOutFlow) {
      return FlowType.checkOut;
    } else if (isMakeKeysFlow) {
      return FlowType.makeKeys;
    }
  }, [isInitialFlow, isCheckInFlow, isCheckOutFlow, isMakeKeysFlow]);
};
