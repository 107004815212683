import { FeatureTogglesCodeMap } from '../../utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeatureTogglesState {
  /**
   * Add feature toggles that have to be stored from configuration response.
   */
  KIOSK_SECURITY_UPGRADE: boolean;
}

export const featureTogglesCodesMap: FeatureTogglesCodeMap<FeatureTogglesState> =
  {
    KIOSK_SECURITY_UPGRADE: {
      key: 'INIT-611_Kiosk_v2',
    },
  };
