import { Action } from 'redux-actions';

import { ApiError } from '@ac/library-api';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

import {
  KioskLoggedUserDto,
  SelfServiceDeviceDto,
  SelfServicePropertyDto,
} from '@gss/api/KioskApi/entries';
import { ReduxReducerMap } from '@gss/types/shared';

import { clearAllGlobalErrors } from '../globalActions';

import * as actions from './actions';
import { ConfigurationState, PropertyPermissionsState } from './interfaces';

export const initialState: ConfigurationState = {
  fetching: {
    prepareApplication: false,
    preparePropertyConfiguration: false,
    restartApplication: false,
    properties: false,
    devices: false,
    changeAppLanguage: false,
    loggingOut: false,
  },
  errors: [],
  isAppInitialized: false,
  isKioskProEnv: false,
  properties: undefined,
  devices: undefined,
  loggedUser: undefined,
  usedPropertyId: undefined,
  usedDeviceId: undefined,
  preselectedDeviceId: undefined,
};

const reducerMap: ReduxReducerMap<ConfigurationState> = {
  [actions.prepareApplication.trigger]: (state): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        prepareApplication: true,
      },
    };
  },

  [actions.prepareApplication.success]: (state): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        prepareApplication: false,
      },
      isAppInitialized: true,
    };
  },

  [actions.prepareApplication.failure]: (
    state,
    action: Action<ApiError | Error>
  ): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        prepareApplication: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.setIsKioskProEnv]: (
    state,
    action: Action<boolean>
  ): ConfigurationState => {
    return {
      ...state,
      isKioskProEnv: action.payload,
    };
  },

  [actions.logoutApplication]: (state): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        loggingOut: true,
      },
    };
  },

  [actions.setCurrentProperty]: (
    state,
    action: Action<string>
  ): ConfigurationState => {
    return {
      ...state,
      usedPropertyId: action.payload,
    };
  },

  [actions.setUsedDeviceId]: (
    state,
    action: Action<string>
  ): ConfigurationState => {
    return {
      ...state,
      usedDeviceId: action.payload,
      preselectedDeviceId: undefined,
    };
  },

  [actions.setPreselectedDeviceId]: (
    state,
    action: Action<string>
  ): ConfigurationState => {
    return {
      ...state,
      preselectedDeviceId: action.payload,
    };
  },

  [actions.setCurrentUser]: (
    state,
    action: Action<KioskLoggedUserDto>
  ): ConfigurationState => {
    return {
      ...state,
      loggedUser: action.payload,
    };
  },

  [actions.fetchPropertyList.trigger]: (state): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        properties: true,
      },
    };
  },

  [actions.fetchPropertyList.success]: (
    state,
    action: Action<SelfServicePropertyDto[]>
  ): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        properties: false,
      },
      properties: action.payload,
    };
  },

  [actions.fetchPropertyList.failure]: (
    state,
    action: Action<ApiError | Error>
  ): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        properties: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.preparePropertyConfiguration.trigger]: (
    state
  ): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        preparePropertyConfiguration: true,
      },
    };
  },

  [actions.preparePropertyConfiguration.success]: (
    state,
    action: Action<PropertyPermissionsState>
  ): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        preparePropertyConfiguration: false,
      },
      propertyPermissions: action.payload,
    };
  },

  [actions.preparePropertyConfiguration.failure]: (
    state,
    action: Action<ApiError | Error>
  ): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        preparePropertyConfiguration: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.fetchDeviceList.trigger]: (state): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        devices: true,
      },
    };
  },

  [actions.fetchDeviceList.success]: (
    state,
    action: Action<SelfServiceDeviceDto[]>
  ): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        devices: false,
      },
      devices: action.payload,
    };
  },

  [actions.fetchDeviceList.failure]: (
    state,
    action: Action<ApiError | Error>
  ): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        devices: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.rebootApplication]: (state): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        restartApplication: true,
      },
    };
  },

  [actions.changeAppLanguage.trigger]: (state): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        changeAppLanguage: true,
      },
    };
  },

  [actions.changeAppLanguage.success]: (state): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        changeAppLanguage: false,
      },
    };
  },

  [actions.changeAppLanguage.failure]: (
    state,
    action: Action<ApiError | Error>
  ): ConfigurationState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        changeAppLanguage: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [clearAllGlobalErrors]: (state): ConfigurationState => {
    return {
      ...state,
      errors: [],
    };
  },
};

export const configuration = handleActions<ConfigurationState>(
  reducerMap,
  initialState
);
