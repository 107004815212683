import { MembershipLevel, MembershipType } from 'types/Api/Entities';
import { Membership } from 'types/Api/Profile';

import { isDefined } from '@ac/library-utils/dist/utils';

import Configurator from './Configurator';

const getMembershipLevel = (
  membership?: Membership
): MembershipLevel | undefined =>
  membership
    ? Configurator.membershipLevels.find(
        (level) => level.code === membership.levelCode
      )
    : undefined;

const getMembershipType = (
  membershipLevel?: Membership
): MembershipType | undefined =>
  membershipLevel
    ? Configurator.membershipTypes.find(
        (type) => type.code === membershipLevel.typeCode
      )
    : undefined;

export const getMembershipDescription = (
  membership?: Membership
): string | undefined => {
  const membershipLevel = getMembershipLevel(membership);
  const membershipType = getMembershipType(membership);

  const typeDescription = membershipType
    ? Configurator.getDescription(membershipType.description)
    : undefined;
  const levelDescription = membershipLevel
    ? Configurator.getDescription(membershipLevel.description)
    : undefined;

  return [typeDescription, levelDescription].filter(isDefined).join(' ');
};
