import {
  getRequester,
  postPagedListRequester,
  postRequester,
} from '@ac/library-api/dist/api/requester';

import {
  CheckInAvailableReservationDto,
  CheckInAvailableReservationsQuery,
  CheckInChangeGuestProfileQuery,
  CheckInProfileDetailsDto,
  CheckInReservationDetailsDto,
  StateCheckInProcessQuery,
} from '../entries';

const path = {
  getAvailableReservations:
    '/kiosk/v0/self-service/check-in-process/get-available-reservations',
  startCheckInProcess: '/kiosk/v0/self-service/check-in-process/start',
  finishCheckInProcess: '/kiosk/v0/self-service/check-in-process/finish',
  getCheckInProfileDetails: '/kiosk/v0/self-service/check-in-process/profile',
  getCheckInReservationDetails:
    '/kiosk/v0/self-service/check-in-process/reservation',
  changeCheckInProfile:
    '/kiosk/v0/self-service/check-in-process/multi-room-reservation/change-guest-profile',
};

const ApiCacheCheckInApi = 'ApiCacheCheckInApi';
export const ApiCacheCheckInGetAvailableReservationsKey = `${ApiCacheCheckInApi}AvailableReservations`;
export const ApiCacheCheckInProfileDetailsKey = `${ApiCacheCheckInApi}CheckInProfileDetails`;
export const ApiCacheCheckInReservationDetailsKey = `${ApiCacheCheckInApi}CheckInReservationDetails`;

export const stateCheckInProcess = postRequester<
  undefined,
  StateCheckInProcessQuery
>(path.startCheckInProcess);

export const finishCheckInProcess = postRequester(path.finishCheckInProcess);

export const getAvailableReservations = postPagedListRequester<
  undefined,
  undefined,
  CheckInAvailableReservationsQuery,
  CheckInAvailableReservationDto
>({
  cacheKey: ApiCacheCheckInGetAvailableReservationsKey,
  path: path.getAvailableReservations,
});

export const getCheckInProfileDetails = getRequester<
  undefined,
  CheckInProfileDetailsDto
>({
  path: path.getCheckInProfileDetails,
  cacheKey: ApiCacheCheckInProfileDetailsKey,
});

export const getCheckInReservationDetails = getRequester<
  undefined,
  CheckInReservationDetailsDto
>({
  path: path.getCheckInReservationDetails,
  cacheKey: ApiCacheCheckInReservationDetailsKey,
});

export const changeCheckInProfile = postRequester<
  undefined,
  CheckInChangeGuestProfileQuery
>(path.changeCheckInProfile);
