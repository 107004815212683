import { asKeyedRoutes } from '../utils/asKeyedRoutes';

export const makeKeysRoutes = asKeyedRoutes({
  KEY_AUTH: {
    path: '/make-keys/auth',
  },
  KEY_GENERATE: {
    path: '/make-keys/generate',
  },
  KEY_COMPLETE: {
    path: '/make-keys/complete',
  },
});
