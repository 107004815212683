import moment, { unitOfTime } from 'moment';

import { DateTimeFormat, TimeFormat } from '@ac/library-api';
import { DateUtils, SupportedDateType } from '@ac/library-utils/dist/utils';

import {
  DEFAULT_LONG_DATE_FORMAT,
  DEFAULT_SHORT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT_CODE,
  TIME_12H_FORMAT,
  TIME_24H_FORMAT,
} from '@gss/configs/constants';

export enum ComparisonType {
  isBefore = 'isBefore',
  isAfter = 'isAfter',
  isSame = 'isSame',
  isSameOrAfter = 'isSameOrAfter',
  isSameOrBefore = 'isSameOrBefore',
  isBetween = 'isBetween',
}

interface ComparisonOptions {
  type: ComparisonType;
  granularity?: unitOfTime.StartOf;
}

interface DateTimeFormats {
  timeFormatCode: TimeFormat;
  timeFormat: string;
  longDateFormat: string;
  shortDateFormat: string;
}

export class DateTimeManager {
  private static storedDateTimeFormat: DateTimeFormat | undefined;

  private static get dateTimeSettings(): DateTimeFormats {
    const timeFormatCode =
      DateTimeManager.storedDateTimeFormat?.timeFormat ||
      DEFAULT_TIME_FORMAT_CODE;

    return {
      timeFormatCode,
      timeFormat:
        timeFormatCode === TimeFormat.H12 ? TIME_12H_FORMAT : TIME_24H_FORMAT,
      longDateFormat:
        DateTimeManager.storedDateTimeFormat?.longDateFormat ||
        DEFAULT_LONG_DATE_FORMAT,
      shortDateFormat:
        DateTimeManager.storedDateTimeFormat?.shortDateFormat ||
        DEFAULT_SHORT_DATE_FORMAT,
    };
  }

  public static saveDateTimeSettings(settings: DateTimeFormat): void {
    DateTimeManager.storedDateTimeFormat = settings;
  }

  public static getFormattedDateTime(
    date: SupportedDateType | undefined,
    format: string
  ): string {
    return DateUtils.serialization.format(date, format);
  }

  public static getFormattedShortDate(
    date: SupportedDateType | undefined
  ): string {
    return DateTimeManager.getFormattedDateTime(
      date,
      DateTimeManager.dateTimeSettings.shortDateFormat
    );
  }

  public static getFormattedShortDateWithWeekDayAbbr(
    date: SupportedDateType | undefined
  ): string {
    return DateUtils.serialization.formatWithWeekDayAbbr(
      date,
      DateTimeManager.dateTimeSettings.shortDateFormat
    );
  }

  public static getFormattedLongDate(
    date: SupportedDateType | undefined
  ): string {
    return DateTimeManager.getFormattedDateTime(
      date,
      DateTimeManager.dateTimeSettings.longDateFormat
    );
  }

  public static getFormattedTime(date: SupportedDateType | undefined): string {
    return DateTimeManager.getFormattedDateTime(
      date,
      DateTimeManager.dateTimeSettings.timeFormat
    );
  }

  public static comparison(
    dateTime: SupportedDateType | undefined,
    comparisonDateTime: SupportedDateType | undefined,
    options: ComparisonOptions
  ): boolean {
    const comparisonMethod = options.type;

    return moment(dateTime)[comparisonMethod](
      moment(comparisonDateTime),
      options.granularity
    );
  }

  public static getFormattedPeriodDate(
    dateFrom: SupportedDateType | undefined,
    dateTo: SupportedDateType | undefined
  ): string {
    if (dateFrom === dateTo) {
      return DateTimeManager.getFormattedShortDateWithWeekDayAbbr(dateFrom);
    }

    return [
      DateTimeManager.getFormattedShortDateWithWeekDayAbbr(dateFrom),
      DateTimeManager.getFormattedShortDateWithWeekDayAbbr(dateTo),
    ].join(' - ');
  }
}
