import { checkInRoutes, RoutesViews } from '@gss/router';
import {
  requireCheckInGuestAuthenticated,
  requireCheckInProcessReady,
  requireMainProcessInitialized,
} from '@gss/utils/hoc';

import { CheckInAccompanyingGuests } from './CheckInAccompanyingGuests';
import { CheckInAuth } from './CheckInAuth';
import { CheckInDocumentDetails } from './CheckInDocumentDetails';
import { CheckInMultiRoom } from './CheckInMultiRoom';
import { CheckInNameDetails } from './CheckInNameDetails';

export const checkInStepsBinds: RoutesViews<typeof checkInRoutes> = {
  CHECK_IN_AUTH: requireMainProcessInitialized(CheckInAuth),
  CHECK_IN_MULTI_ROOM: requireCheckInGuestAuthenticated(CheckInMultiRoom),
  CHECK_IN_ACCOMPANYING_GUESTS: requireCheckInGuestAuthenticated(
    CheckInAccompanyingGuests
  ),
  CHECK_IN_NAME_DETAILS: requireCheckInProcessReady(CheckInNameDetails),
  CHECK_IN_DOCUMENT_DETAILS: requireCheckInProcessReady(CheckInDocumentDetails),
};
