import { asKeyedRoutes } from '../utils/asKeyedRoutes';

export const checkInRoutes = asKeyedRoutes({
  CHECK_IN_AUTH: {
    path: '/check-in/auth',
  },
  CHECK_IN_MULTI_ROOM: {
    path: '/check-in/multi-room',
    optionalStep: true,
  },
  CHECK_IN_NAME_DETAILS: {
    path: '/check-in/name-details',
    optionalStep: true,
  },
  CHECK_IN_ACCOMPANYING_GUESTS: {
    path: '/check-in/accompanying-guests',
    optionalStep: true,
  },
  // TODO: Temporary this way, for testing purposes
  CHECK_IN_DOCUMENT_DETAILS: {
    path: '/check-in/document-details',
  },
});
