import { Action } from 'redux-actions';

import {
  CheckInAvailableReservationDto,
  CheckInProfileDetailsDto,
  CheckInReservationDetailsDto,
} from '@gss/api/KioskApi/entries';
import { AuthProcessStatus } from '@gss/types/authProcess';
import { ReduxReducerMap } from '@gss/types/shared';
import {
  AuthFailedError,
  AuthTooManyReservationsFoundError,
  CustomError,
} from '@gss/utils/errors';

import { handleGlobalFlowReducer } from '../../storeConfiguration/utils';

import * as actions from './actions';
import { CheckInFlowState, StartCheckInProcessPayload } from './interfaces';

export const initialState: CheckInFlowState = {
  fetching: {
    authentication: false,
    initializingCheckInProcess: false,
    reservationDetails: false,
    profileDetails: false,
    closeProcess: false,
    startCheckInSession: false,
    changeGuest: false,
  },
  errors: [],
  authData: undefined,
  isSecondFactorNeeded: false,
  authenticationStatus: undefined,
  processId: undefined,
  reservationId: undefined,
  profileId: undefined,
  availableReservations: [],
  reservationDetails: undefined,
  profileDetails: undefined,
};

const reducerMap: ReduxReducerMap<CheckInFlowState> = {
  [actions.authenticateGuest.trigger]: (
    state,
    action: Action<StartCheckInProcessPayload>
  ): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        authentication: true,
      },
      authData: action.payload,
    };
  },

  [actions.authenticateGuest.success]: (
    state,
    action: Action<CheckInAvailableReservationDto[]>
  ): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        authentication: false,
      },
      authenticationStatus: AuthProcessStatus.success,
      availableReservations: action.payload,
    };
  },

  [actions.authenticateGuest.failure]: (
    state,
    action: Action<CustomError & Error>
  ): CheckInFlowState => {
    const newState = {
      ...state,
      fetching: {
        ...state.fetching,
        authentication: false,
      },
      authData: undefined,
    };

    if (action.payload.errorName === AuthFailedError.errorName) {
      newState.authenticationStatus = AuthProcessStatus.failed;
    } else if (
      action.payload.errorName === AuthTooManyReservationsFoundError.errorName
    ) {
      if (state.isSecondFactorNeeded) {
        newState.authenticationStatus = AuthProcessStatus.failed;
      } else {
        newState.isSecondFactorNeeded = true;
      }
    } else {
      newState.errors = [...state.errors, action.payload];
    }

    return newState;
  },

  [actions.startCheckInSession.trigger]: (state): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        startCheckInSession: true,
      },
    };
  },

  [actions.startCheckInSession.success]: (
    state,
    action: Action<string>
  ): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        startCheckInSession: false,
      },
      processId: action.payload,
    };
  },

  [actions.startCheckInSession.failure]: (
    state,
    action: Action<CustomError & Error>
  ): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        startCheckInSession: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.closeCheckInProcess.trigger]: (state): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        closeProcess: true,
      },
    };
  },

  [actions.closeCheckInProcess.success]: (state): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        closeProcess: false,
      },
    };
  },

  [actions.fetchCheckInReservationDetails.trigger]: (
    state
  ): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        reservationDetails: true,
      },
    };
  },

  [actions.fetchCheckInReservationDetails.success]: (
    state,
    action: Action<CheckInReservationDetailsDto>
  ): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        reservationDetails: false,
      },
      reservationDetails: action.payload,
    };
  },

  [actions.fetchCheckInReservationDetails.failure]: (
    state,
    action: Action<CustomError & Error>
  ): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        reservationDetails: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.fetchCheckInProfileDetails.trigger]: (state): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        profileDetails: true,
      },
    };
  },

  [actions.fetchCheckInProfileDetails.success]: (
    state,
    action: Action<CheckInProfileDetailsDto>
  ): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        profileDetails: false,
      },
      profileDetails: action.payload,
    };
  },

  [actions.fetchCheckInProfileDetails.failure]: (
    state,
    action: Action<CustomError & Error>
  ): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        profileDetails: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.changeGuestProfile.trigger]: (state): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        changeGuest: true,
      },
    };
  },

  [actions.changeGuestProfile.success]: (state): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        changeGuest: false,
      },
    };
  },

  [actions.changeGuestProfile.failure]: (
    state,
    action: Action<CustomError & Error>
  ): CheckInFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        changeGuest: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.selectReservation]: (
    state,
    action: Action<string>
  ): CheckInFlowState => {
    return {
      ...state,
      reservationId: action.payload,
    };
  },

  [actions.selectProfile]: (
    state,
    action: Action<string>
  ): CheckInFlowState => {
    return {
      ...state,
      profileId: action.payload,
    };
  },

  [actions.resetAuthProcess]: (): CheckInFlowState => {
    return {
      ...initialState,
    };
  },
};

export const checkInFlow = handleGlobalFlowReducer<CheckInFlowState>(
  reducerMap,
  initialState
);
