import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Section } from '@ac/kiosk-components';

import { getStyles } from '@gss/store/settings/selectors';

import './ReservationHeader.scss';

interface ReservationHeaderProps {
  className?: string;
  dataTestSelector?: string;
}

export const ReservationHeader = ({
  className,
  dataTestSelector,
  children,
}: PropsWithChildren<ReservationHeaderProps>): JSX.Element => {
  const styleSettings = useSelector(getStyles);

  return (
    <Section
      dataTestSelector={dataTestSelector}
      className={classNames(
        'reservation-header-section',
        'reservation-header-section-with-border',
        {
          ['reservation-header-transparent']:
            !styleSettings?.SEPARATE_RESERVATION_HEADER_BG,
        },
        className
      )}
    >
      {children}
    </Section>
  );
};
