import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ApiError } from '@ac/library-api';

import { FlowType, useRouter } from '@gss/router';
import { getBackgroundTasksErrors } from '@gss/store/backgroundTasks/selectors';
import { getConfigurationErrors } from '@gss/store/configuration/selectors';
import { getCheckInProcessErrors } from '@gss/store/flows/checkInFlow/selectors';
import { getMakeKeysProcessErrors } from '@gss/store/flows/makeKeysFlow/selectors';
import { getMainProcessErrors } from '@gss/store/mainProcess/selectors';
import {
  getCustomMessages,
  getSettingsErrors,
} from '@gss/store/settings/selectors';

interface SubscribedErrorState {
  errors: Array<ApiError | Error>;
  isError: boolean;
  defaultErrorMessage?: string;
}

export const useSubscribedErrorState = (): SubscribedErrorState => {
  const router = useRouter();

  const backgroundTasksErrors = useSelector(getBackgroundTasksErrors);
  const configurationErrors = useSelector(getConfigurationErrors);
  const settingsErrors = useSelector(getSettingsErrors);
  const mainProcessErrors = useSelector(getMainProcessErrors);
  const makeKeysProcessErrors = useSelector(getMakeKeysProcessErrors);
  const checkInProcessErrors = useSelector(getCheckInProcessErrors);
  const customMessages = useSelector(getCustomMessages);

  const defaultErrorMessage = useMemo((): string | undefined => {
    switch (router.flowType) {
      case FlowType.checkIn:
        return customMessages?.CHECK_IN_FAILED_MESSAGE;
    }
  }, [customMessages?.CHECK_IN_FAILED_MESSAGE, router.flowType]);

  return useMemo((): SubscribedErrorState => {
    const errors = [
      backgroundTasksErrors,
      configurationErrors,
      settingsErrors,
      mainProcessErrors,
      makeKeysProcessErrors,
      checkInProcessErrors,
    ].flat();

    return {
      errors,
      isError: !!errors?.length,
      defaultErrorMessage,
    };
  }, [
    backgroundTasksErrors,
    configurationErrors,
    settingsErrors,
    mainProcessErrors,
    makeKeysProcessErrors,
    checkInProcessErrors,
    defaultErrorMessage,
  ]);
};
