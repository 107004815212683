import { makeKeysRoutes, RoutesViews } from '@gss/router';
import { requireMakeKeysProcessReady } from '@gss/utils/hoc';
import { requireMainProcessInitialized } from '@gss/utils/hoc/requireMainProcessInitialized';

import { KeyAuth } from './KeyAuth';
import { KeyComplete } from './KeyComplete';
import { KeyGenerator } from './KeyGenerator';

export const makeKeysStepsBinds: RoutesViews<typeof makeKeysRoutes> = {
  KEY_AUTH: requireMainProcessInitialized(KeyAuth),
  KEY_GENERATE: requireMakeKeysProcessReady(KeyGenerator),
  KEY_COMPLETE: requireMakeKeysProcessReady(KeyComplete),
};
