import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  AlignItems,
  Color,
  Flex,
  FormHeader,
  Icon,
  IconTypes,
  Section,
  SectionType,
  Size,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { CheckInAvailableReservationDto } from '@gss/api/KioskApi/entries';
import { Body, Header } from '@gss/components/layout';
import { useRouter } from '@gss/router';
import {
  selectProfile,
  selectReservation,
  startCheckInSession,
} from '@gss/store/flows/checkInFlow/actions';
import {
  getCheckInAuthData,
  getCheckInAvailableReservations,
  getIsCheckInProcessReady,
} from '@gss/store/flows/checkInFlow/selectors';
import { useLargeScreenMatchMedia } from '@gss/utils/hooks';

import {
  CheckInFooter,
  CheckInView,
  CheckNameDetailsModal,
} from '../components';

import { MultiRoomTable } from './components/MultiRoomTable';

export const CheckInMultiRoom = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const isLargeScreen = useLargeScreenMatchMedia();

  const [isCheckNameModalOpen, setCheckNameModalOpen] = useState(false);
  const [isChangeProfileRequested, setChangeProfileRequested] = useState(false);
  const [selectedReservation, setSelectedReservation] =
    useState<CheckInAvailableReservationDto>();

  const availableReservations = useSelector(getCheckInAvailableReservations);
  const checkInAuthData = useSelector(getCheckInAuthData);
  const isCheckInProcessReady = useSelector(getIsCheckInProcessReady);

  const isSomeReservationAlreadyFullyCheckedIn = useMemo(
    () =>
      availableReservations?.some((reservation) => {
        return (
          reservation.guest?.checkedIn &&
          reservation.accompanyingGuests?.every((guest) => guest.checkedIn)
        );
      }),
    [availableReservations]
  );

  const startStartSession = useCallback(() => {
    if (selectedReservation?.id && selectedReservation?.guest?.id) {
      dispatch(selectReservation(selectedReservation.id));
      dispatch(selectProfile(selectedReservation.guest.id));
      dispatch(
        startCheckInSession.trigger({
          reservationId: selectedReservation.id,
          profileId: selectedReservation.guest.id,
        })
      );
    }
  }, [dispatch, selectedReservation]);

  const toggleCheckNameModal = useCallback(() => {
    setCheckNameModalOpen((isOpen) => !isOpen);
  }, []);

  const handleCheckInClick = useCallback(
    (reservationId: string): void => {
      const reservation = availableReservations?.find(
        (data) => data.id === reservationId
      );

      if (reservation?.accompanyingGuests?.length) {
        dispatch(selectReservation(reservation.id));
        router.goTo('CHECK_IN_ACCOMPANYING_GUESTS');
      } else {
        setSelectedReservation(reservation);
        toggleCheckNameModal();
      }
    },
    [availableReservations, dispatch, router, toggleCheckNameModal]
  );

  const handleConfirmNameDetails = useCallback(() => {
    toggleCheckNameModal();
    startStartSession();
  }, [toggleCheckNameModal, startStartSession]);

  const handleChangeNameDetails = useCallback(() => {
    toggleCheckNameModal();
    startStartSession();
    setChangeProfileRequested(true);
  }, [toggleCheckNameModal, startStartSession]);

  useEffect(() => {
    if (isCheckInProcessReady) {
      if (isChangeProfileRequested) {
        router.goTo('CHECK_IN_NAME_DETAILS');
      } else {
        router.goToNextStep();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckInProcessReady]);

  return (
    <>
      {isCheckNameModalOpen && (
        <CheckNameDetailsModal
          guest={selectedReservation?.guest}
          onClose={toggleCheckNameModal}
          onChange={handleChangeNameDetails}
          onConfirm={handleConfirmNameDetails}
        />
      )}
      <CheckInView>
        <Header
          title={[
            t('CHECK_IN_FLOW.TITLE'),
            t('CHECK_IN_FLOW.VIEWS.MULTI_ROOM.HEADER'),
          ].join(' - ')}
        />
        <Body>
          <Section type={isLargeScreen ? SectionType.narrow : SectionType.wide}>
            <FormHeader
              className="spacing-bottom-xxlg spacing-top-md"
              title={t(
                'CHECK_IN_FLOW.VIEWS.MULTI_ROOM.SELECTION_SECTION.TITLE'
              )}
            />
            {availableReservations && (
              <MultiRoomTable
                multiRoomConfirmationNumber={
                  checkInAuthData?.confirmationNumber
                }
                availableReservations={availableReservations}
                onCheckInClick={handleCheckInClick}
              />
            )}
          </Section>
        </Body>
        <CheckInFooter hasCancelButton>
          {isSomeReservationAlreadyFullyCheckedIn && (
            <Flex
              className="gap-sm spacing-horizontal-lg"
              alignItems={AlignItems.center}
            >
              <Icon
                size={Size.sm}
                type={IconTypes.checkFilled}
                color={Color.success}
              />
              <Text size={TextSize.xs}>
                {t('CHECK_IN_FLOW.VIEWS.MULTI_ROOM.STATUSES.CHECKED_IN')}
              </Text>
            </Flex>
          )}
        </CheckInFooter>
      </CheckInView>
    </>
  );
};
