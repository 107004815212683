import { createSelector } from 'reselect';

import { ApiError } from '@ac/library-api';

import { MakeKeysReservationDetailsDto } from '@gss/api/KioskApi/entries';
import { ComparisonType, DateTimeManager } from '@gss/services/DateTimeManager';
import { Store } from '@gss/store';
import { AuthProcessStatus } from '@gss/types/authProcess';

export const getMakeKeysProcessFetchState = (state: Store): boolean =>
  Object.values(state.makeKeysFlow.fetching).includes(true);

export const getMakeKeysProcessErrors = (
  state: Store
): Array<ApiError | Error> => state.makeKeysFlow.errors;

export const getIsMakeKeysGuestAuthenticated = (state: Store): boolean =>
  state.makeKeysFlow.authenticationStatus === AuthProcessStatus.success;

export const getMakeKeysAuthenticationStatus = (
  state: Store
): AuthProcessStatus | undefined => state.makeKeysFlow.authenticationStatus;

export const getMakeKeysProcessId = (state: Store): string | undefined =>
  state.makeKeysFlow.processId;

export const getIsMakeKeysSecondFactorNeeded = (state: Store): boolean =>
  state.makeKeysFlow.isSecondFactorNeeded;

export const getIsMakeKeysProcessReady = (state: Store): boolean =>
  state.makeKeysFlow.isMakeKeyProcessReady;

export const getMakeKeysReservationDetails = (
  state: Store
): MakeKeysReservationDetailsDto | undefined =>
  state.makeKeysFlow.reservationDetails;

export const getIsDayUseReservation = createSelector(
  [getMakeKeysReservationDetails],
  (reservationDetails: MakeKeysReservationDetailsDto | undefined): boolean => {
    return reservationDetails
      ? DateTimeManager.comparison(
          reservationDetails.arrivalDate,
          reservationDetails.departureDate,
          { type: ComparisonType.isSame }
        )
      : false;
  }
);
