import { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { routes } from '@gss/router';
import { getIsCheckInProcessReady } from '@gss/store/flows/checkInFlow/selectors';

export const requireCheckInProcessReady = <
  Props extends Record<string, unknown>
>(
  WrappedComponent: ComponentType<Props>
): typeof WrappedComponent => {
  const MakeKeysProcessReadyValidator = (props: Props): JSX.Element => {
    const isCheckInProcessReady = useSelector(getIsCheckInProcessReady);

    if (!isCheckInProcessReady) {
      return <Redirect to={routes.CHECK_IN_AUTH.path} />;
    }

    return <WrappedComponent {...props} />;
  };

  return MakeKeysProcessReadyValidator;
};
