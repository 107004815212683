import { LocalizedStringDto, RawSettingFileRelation } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import { SelfServiceConfigurationApi } from '@gss/api/KioskApi';
import {
  KioskLayoutSetting,
  KioskLayoutSettingNestedValue,
  KioskLayoutSettingValue,
} from '@gss/api/KioskApi/entries';
import { BaseObject } from '@gss/types/shared';
import { getLocalizedContent } from '@gss/utils';

export interface SettingMapSetup<ValueType = unknown> {
  key: string;
  switch?: string;
  default?: ValueType;
}

export type SettingsCodeMap<Type> = {
  [key in keyof Type]: SettingMapSetup<Type[key]>;
};

export type MappedSettingsType<Type> = {
  [key in keyof Type]: Type[key];
};

const isLocalizedString = (data: unknown): data is LocalizedStringDto => {
  return Boolean(
    data &&
      typeof data === 'object' &&
      'languageCode' in data &&
      'content' in data
  );
};

const isSettingFileRelation = (
  data: unknown
): data is RawSettingFileRelation => {
  return Boolean(
    data &&
      typeof data === 'object' &&
      'fileName' in data &&
      'fileId' in data &&
      'contentType' in data
  );
};

const getSettingValue = (
  element?: KioskLayoutSetting
): KioskLayoutSettingNestedValue | KioskLayoutSettingValue | undefined => {
  return element?.value &&
    typeof element.value === 'object' &&
    'value' in element.value
    ? element.value?.value
    : element?.value;
};

const getImageObjectUrl = async (
  settings: SettingMapSetup
): Promise<string> => {
  const imageBlob = await SelfServiceConfigurationApi.getContentImage({
    pathParams: { code: settings.key },
  });

  return URL.createObjectURL(imageBlob as Blob);
};

export const mapLayoutSettings = async <DataInputType extends BaseObject>(
  objToMap: SettingsCodeMap<DataInputType>,
  settings?: KioskLayoutSetting[]
): Promise<MappedSettingsType<DataInputType> | undefined> => {
  if (!settings) return undefined;

  const mappedSettings = await Promise.all(
    Object.entries(objToMap).map(
      async ([key, settingMap]: [string, SettingMapSetup]) => {
        const matchedSettingKey = settings.find(
          (item) => item.code === settingMap?.key
        );

        if (!matchedSettingKey) {
          if (isDefined(settingMap?.default)) {
            return [key, settingMap?.default];
          } else {
            return undefined;
          }
        }

        const matchedSettingSwitch = settings.find(
          (item) => item.code === settingMap?.switch
        );

        if (settingMap?.switch && !getSettingValue(matchedSettingSwitch)) {
          return undefined;
        }

        let newValue = getSettingValue(matchedSettingKey);

        if (Array.isArray(newValue) && isLocalizedString(newValue[0])) {
          newValue = getLocalizedContent(newValue as LocalizedStringDto[]);
        }

        if (isSettingFileRelation(newValue)) {
          newValue = await getImageObjectUrl(settingMap);
        }

        return [key, newValue ?? settingMap?.default];
      }
    )
  );

  const filteredSettings = mappedSettings
    .filter(isDefined)
    .filter(([key, value]) => key && (value || value === false));

  return Object.fromEntries(
    filteredSettings
  ) as MappedSettingsType<DataInputType>;
};
