import { useTranslation } from 'react-i18next';

import { ErrorModal } from '@ac/kiosk-components';

interface Props {
  onClick: () => void;
}

export const AddonsUpdateBeyondLimitModal = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <ErrorModal
      onConfirm={onClick}
      className="with-default-kiosk-components-theme"
      description={t('ADDONS_UPDATE_BEYOND_LIMIT')}
    />
  );
};
