import { ComponentProps, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmModal } from '@ac/kiosk-components';

import { Footer } from '@gss/components/layout';
import { useRouter } from '@gss/router';
import { closeCheckInProcess } from '@gss/store/flows/checkInFlow/actions';

export const CheckInFooter = (
  props: ComponentProps<typeof Footer>
): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const [cancelModalOpened, setCancelModalOpen] = useState(false);

  const toggleCancelModal = useCallback(() => {
    setCancelModalOpen((prevState) => !prevState);
  }, []);

  const cancelMakeKeysProcess = useCallback(() => {
    dispatch(
      closeCheckInProcess.trigger((): void => {
        router.goTo('WELCOME', { replace: true });
      })
    );
  }, [dispatch, router]);

  return (
    <>
      {cancelModalOpened && (
        <ConfirmModal
          className="with-default-kiosk-components-theme"
          header={t(
            'CHECK_IN_FLOW.COMPONENTS.FOOTER.CANCEL_PROCESS_MODAL.TITLE'
          )}
          description={t(
            'CHECK_IN_FLOW.COMPONENTS.FOOTER.CANCEL_PROCESS_MODAL.DESCRIPTION'
          )}
          secondaryButtonLabel={t('SHARED.LEAVE')}
          primaryButtonLabel={t('SHARED.STAY_AND_CONTINUE')}
          onPrimaryClick={toggleCancelModal}
          onSecondaryClick={cancelMakeKeysProcess}
        />
      )}

      <Footer withStepper onCancelClick={toggleCancelModal} {...props} />
    </>
  );
};
