import { ReducerMap, ReduxCompatibleReducer } from 'redux-actions';

import { handleActions } from '@ac/library-utils/dist/redux-utils';

import { resetFlowsData } from '@gss/store/globalActions';

export const handleLocalFlowReducer = <ReducerStateType>(
  reducerMap: ReducerMap<ReducerStateType, unknown>,
  initialState: ReducerStateType
): ReduxCompatibleReducer<ReducerStateType, unknown> => {
  const reducer = {
    [resetFlowsData]: (): ReducerStateType => {
      return {
        ...initialState,
      };
    },

    ...reducerMap,
  };

  return handleActions(reducer, initialState);
};
