import { CallApiResponseAction } from 'store/middleware/api';
import { State } from 'types/Store/CheckOutPaymentStore';

import types from './types';

const initialState: State = {
  selectedCompanyId: '',
  isCompanySelectOpen: false,
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.SELECT_COMPANY: {
      const { payload: selectedCompanyId } = action;

      return {
        ...state,
        selectedCompanyId,
      };
    }
    case types.TOGGLE_COMPANY_SELECT: {
      const { isCompanySelectOpen } = state;

      return {
        ...state,
        isCompanySelectOpen: !isCompanySelectOpen,
      };
    }

    default:
      return state;
  }
};
