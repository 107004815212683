import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Configurator } from 'utils';

import {
  Field,
  FieldMode,
  SelectField,
  SelectOption,
  Text,
  TextSize,
  ValidationHeader,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  composeValidators,
  createEmailValidator,
  createRequiredValidator,
  formNestedFieldFactory,
  FormRenderProps,
} from '@ac/react-infrastructure';

import { generateRandomString } from '@gss/utils';
import { FormValidator, mapFieldRenderProps } from '@gss/utils/form';

import {
  CheckInCommunicationDetailsFormValues,
  CommunicationDetailsFormSection,
  ContactFormValues,
} from '../types';

import './ContactForm.scss';

interface ContactFormProps {
  className?: string;
  formRenderProps: FormRenderProps<CheckInCommunicationDetailsFormValues>;
  nestedPropertyName: CommunicationDetailsFormSection;
}

const FormField =
  formNestedFieldFactory<
    Record<CommunicationDetailsFormSection, ContactFormValues>
  >();

const ContactForm = ({
  className,
  formRenderProps,
  nestedPropertyName,
}: ContactFormProps) => {
  const { t, i18n } = useTranslation();
  const prefix = useRef<string>();
  const [isPhoneCountryCodeFieldHidden, setPhoneCountryCodeFieldHidden] =
    useState<boolean>(true);

  const { phoneFieldTitle, emailFieldTitle } = useMemo(() => {
    return {
      phoneFieldTitle: Configurator.getTranslation(
        Configurator.getTranslationCodes()?.PHONE_FREE_TEXT
      ),
      emailFieldTitle: Configurator.getTranslation(
        Configurator.getTranslationCodes()?.EMAIL_FREE_TEXT
      ),
    };
  }, [i18n.language]);

  const validator = useMemo(() => {
    return new FormValidator<
      Record<CommunicationDetailsFormSection, ContactFormValues>
    >({
      [nestedPropertyName]: {
        mobile: createRequiredValidator(t('FIELD_IS_MANDATORY')),
        email: composeValidators(
          createRequiredValidator(t('FIELD_IS_MANDATORY')),
          createEmailValidator(t('EMAIL_IS_INCORRECT'))
        ),
      },
    });
  }, [i18n.language]);

  const mobilePrefixFieldId = useMemo(() => generateRandomString(), []);
  const mobileNumberFieldId = useMemo(() => generateRandomString(), []);

  const prefixes = useMemo(() => {
    return Configurator.getMobilePrefixes(i18n.language);
  }, [i18n.language]);

  const prefixExtendedOptions = useMemo(
    () =>
      prefixes
        .map((prefix) => ({
          title: `${prefix?.prefix} (${prefix.regionName})`,
          value: prefix.code,
        }))
        .filter((option) => {
          return option.title && option.value;
        }) as SelectOption[],
    [prefixes]
  );

  const getSuggestedOptions = useMemo(() => {
    const suggestedPrefixes = Configurator.suggestedPhonePrefixes;
    if (!suggestedPrefixes) {
      return;
    }

    return prefixes
      ?.filter((element) => suggestedPrefixes?.includes(element.code || ''))
      .sort((a, b) => a.regionName?.localeCompare(b.regionName || '') || 0)
      .map((element) => element.code)
      .filter(isDefined);
  }, [prefixes]);

  const focusField = useCallback((elementId: string) => {
    const countryPrefixSelectElement = document.getElementById(elementId);
    countryPrefixSelectElement?.focus();
  }, []);

  useEffect(() => {
    if (isPhoneCountryCodeFieldHidden || prefix.current) return;
    focusField(mobilePrefixFieldId);
  }, [isPhoneCountryCodeFieldHidden, mobilePrefixFieldId]);

  return (
    <div className={classNames('contact-form', className)}>
      <ValidationHeader
        isValid={validator.isValid}
        title={t('CONTACT')}
        className="spacing-bottom-lg contact-form-header"
      />

      {(phoneFieldTitle || emailFieldTitle) && (
        <>
          <Text
            size={TextSize.xs}
            className="contact-phone-custom-message spacing-bottom-sm"
          >
            {phoneFieldTitle}
          </Text>
          <Text
            size={TextSize.xs}
            className="contact-email-custom-message spacing-bottom-sm"
          >
            {emailFieldTitle}
          </Text>
        </>
      )}

      {Boolean(
        (formRenderProps.values[nestedPropertyName] as ContactFormValues)
          ?.prefix ||
          prefix.current ||
          !isPhoneCountryCodeFieldHidden ||
          !(formRenderProps.values[nestedPropertyName] as ContactFormValues)
            ?.mobile
      ) && (
        <FormField
          valuePath={[nestedPropertyName, 'prefix']}
          validateFields={[[nestedPropertyName, 'prefix'].join('.')]}
          validate={validator.validateSingleField}
        >
          {(fieldRenderProps) => {
            const mappedFieldRenderProps =
              mapFieldRenderProps(fieldRenderProps);

            return (
              <SelectField
                {...mappedFieldRenderProps}
                id={mobilePrefixFieldId}
                label={t('PREFIX')}
                placeholder={t('SELECT')}
                className="contact-form-prefix"
                modalClassName="with-default-kiosk-components-theme"
                options={prefixExtendedOptions}
                suggestedOptionValues={getSuggestedOptions}
                dataTestSelector="check-in-contact-phone-prefix-select"
                onChange={(value) => {
                  prefix.current = value;
                  mappedFieldRenderProps.onChange(value);
                  formRenderProps.form.change(nestedPropertyName, {
                    ...formRenderProps.values[nestedPropertyName],
                    prefix: value,
                    mobile: undefined,
                  });
                  focusField(mobileNumberFieldId);
                }}
                onBlur={() => {
                  if (!prefix.current) {
                    setPhoneCountryCodeFieldHidden(true);
                  }
                }}
              />
            );
          }}
        </FormField>
      )}

      <FormField
        valuePath={[nestedPropertyName, 'mobile']}
        validateFields={[[nestedPropertyName, 'mobile'].join('.')]}
        validate={validator.validateSingleField}
      >
        {(fieldRenderProps) => {
          const mappedFieldRenderProps = mapFieldRenderProps(fieldRenderProps);

          return (
            <Field
              {...mappedFieldRenderProps}
              id={mobileNumberFieldId}
              label={t('PHONE_NUMBER')}
              placeholder={t('FILL')}
              mode={FieldMode.tel}
              className="contact-form-mobile"
              dataTestSelector="check-in-contact-phone-number-field"
              onFocus={() => {
                if (
                  !prefix.current ||
                  !(
                    formRenderProps.values[
                      nestedPropertyName
                    ] as ContactFormValues
                  )?.prefix
                ) {
                  setPhoneCountryCodeFieldHidden(false);
                } else {
                  mappedFieldRenderProps.onFocus();
                }
              }}
            />
          );
        }}
      </FormField>

      <FormField
        valuePath={[nestedPropertyName, 'email']}
        validateFields={[[nestedPropertyName, 'email'].join('.')]}
        validate={validator.validateSingleField}
      >
        {(fieldRenderProps) => (
          <Field
            {...mapFieldRenderProps(fieldRenderProps)}
            label={t('EMAIL')}
            placeholder={t('FILL')}
            className="contact-form-email"
            dataTestSelector="check-in-contact-email-field"
            mode={FieldMode.email}
          />
        )}
      </FormField>
    </div>
  );
};

export default ContactForm;
