import { useTranslation } from 'react-i18next';

import {
  AlignItems,
  Button,
  ButtonPattern,
  Circular,
  CircularType,
  Color,
  Flex,
  FlexDirection,
  IconTypes,
  JustifyContent,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { makeKeysTestSelector } from '@gss/utils/testSelectors';

import { PropsTypes } from '../propsTypes';

const TEST_SELECTOR_PREFIX = 'generation-modal-success';

export const Success = ({
  keyNumber,
  amount,
  onCancel,
  onConfirm,
}: PropsTypes): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        direction={FlexDirection.column}
        alignItems={AlignItems.center}
        className="spacing-top-md spacing-bottom-lg gap-lg"
      >
        <Circular
          size={88}
          iconColor={Color.primary}
          icon={IconTypes.check}
          type={CircularType.success}
        />
        <Text size={TextSize.xlg}>
          {t(
            'VIEWS.KEY_FLOW.COMPONENTS.KEY_GENERATION_MODAL.YOUR_KEY_IS_READY',
            {
              current: keyNumber,
              max: amount,
            }
          )}
        </Text>
      </Flex>

      <Flex justifyContent={JustifyContent.spaceBetween}>
        <Button
          pattern={ButtonPattern.secondary}
          onClick={onCancel}
          dataTestSelector={makeKeysTestSelector(
            TEST_SELECTOR_PREFIX,
            'cancel-button'
          )}
        >
          {t('SHARED.CANCEL')}
        </Button>
        <Button
          onClick={onConfirm}
          dataTestSelector={makeKeysTestSelector(
            TEST_SELECTOR_PREFIX,
            'next-key-button'
          )}
        >
          {t('VIEWS.KEY_FLOW.COMPONENTS.KEY_GENERATION_MODAL.ENCODE_NEXT_KEY')}
        </Button>
      </Flex>
    </>
  );
};
