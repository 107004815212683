import { BillingInstruction, Folio } from 'types/Api/Cashiering';
import { Configurator, Router } from 'utils';
import { Path } from 'utils/Router';

export function configureCheckInPaymentStep(
  folios: Folio[],
  billingInstructions: BillingInstruction[]
) {
  if (
    isPaymentStepHidden(
      folios,
      billingInstructions,
      Configurator.profileRoles.COMPANY
    )
  ) {
    // eslint-disable-next-line no-console
    console.warn('Company invoice step is going to be skipped');
    Router.removeStep(Path.checkIn, 'PAYMENT');
  }
}

export function isPaymentStepHidden(
  folios: Folio[],
  billingInstructions: BillingInstruction[],
  companyRoleCode: string
) {
  const folioTwo = folios.find((folio) => folio.number === '2');
  const isHidden =
    folios.length > 2 ||
    billingInstructions.length > 0 ||
    (folioTwo && folioTwo?.profileRoleCode.code !== companyRoleCode);

  return isHidden;
}
