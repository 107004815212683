import { BaseObject } from '@gss/types/shared';

import { StylesSettingsState } from '../interfaces/settings/stylesSate';

const fontFamilyCodesMap: BaseObject<string> = {
  ARVO: 'Arvo',
  CABIN: 'Cabin',
  CABINCONDENSED: 'Cabin Condensed',
  PTSANS: 'PT Sans',
  LORA: 'Lora',
  MERRIWEATHER: 'Merriweather',
  NOTOSANS: 'NotoSans',
  OPENSANSCONDENSED: 'Open Sans Condensed',
  PLAYFAIRDISPLAY: 'Playfair Display',
  RALEWAY: 'Raleway',
  ROBOTO: 'Roboto',
  ROBOTOMONO: 'Roboto Mono',
  OPENSANS: 'Open Sans',
  MONSERRAT: 'Montserrat',
  BOING: 'Montserrat',
};

const getFontFamily = (fontCode?: string): string => {
  const fontFamilyName = fontCode && fontFamilyCodesMap[fontCode];

  return [
    `"${fontFamilyName || fontFamilyCodesMap.OPENSANS}"`,
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'sans-serif',
    'Arial',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(',');
};

export const mapCustomTheme = (
  stylesSettings: StylesSettingsState
): BaseObject<string | undefined> => {
  return {
    '--primary-color': stylesSettings.THEME_COLOR,
    '--main-font': getFontFamily(stylesSettings?.THEME_FONT),
    '--body-background-color': stylesSettings.BODY_BACKGROUND,
    '--body-font-color': stylesSettings.BODY_FONT_COLOR,
    '--footer-background-color': stylesSettings.FOOTER_BACKGROUND,
    '--footer-font-color': stylesSettings.FOOTER_FONT_COLOR,
    '--header-background-color': stylesSettings.HEADER_BACKGROUND,
    '--header-font-color': stylesSettings.HEADER_FONT_COLOR,
    '--reservation-header-background-color':
      stylesSettings.RESERVATION_HEADER_BACKGROUND,
    '--reservation-header-font-color':
      stylesSettings.RESERVATION_HEADER_FONT_COLOR,
    '--hint-font-color': stylesSettings.HINT_FONT_COLOR,
    '--primary-button-active-background-color':
      stylesSettings.BUTTON_PRIMARY_BACKGROUND_ACTIVE,
    '--primary-button-active-border-color':
      stylesSettings.BUTTON_PRIMARY_BORDER_ACTIVE,
    '--primary-button-active-text-color':
      stylesSettings.BUTTON_PRIMARY_TEXT_ACTIVE,
    '--primary-button-clicked-background-color':
      stylesSettings.BUTTON_PRIMARY_BACKGROUND_CLICKED,
    '--primary-button-clicked-border-color':
      stylesSettings.BUTTON_PRIMARY_BORDER_CLICKED,
    '--primary-button-clicked-text-color':
      stylesSettings.BUTTON_PRIMARY_TEXT_CLICKED,
    '--primary-button-disabled-background-color':
      stylesSettings.BUTTON_PRIMARY_BACKGROUND_DISABLED,
    '--primary-button-disabled-border-color':
      stylesSettings.BUTTON_PRIMARY_BORDER_DISABLED,
    '--primary-button-disabled-text-color':
      stylesSettings.BUTTON_PRIMARY_TEXT_DISABLED,
    '--secondary-button-active-background-color':
      stylesSettings.BUTTON_SECONDARY_BACKGROUND_ACTIVE,
    '--secondary-button-active-border-color':
      stylesSettings.BUTTON_SECONDARY_BORDER_ACTIVE,
    '--secondary-button-active-text-color':
      stylesSettings.BUTTON_SECONDARY_TEXT_ACTIVE,
    '--secondary-button-clicked-background-color':
      stylesSettings.BUTTON_SECONDARY_BACKGROUND_CLICKED,
    '--secondary-button-clicked-border-color':
      stylesSettings.BUTTON_SECONDARY_BORDER_CLICKED,
    '--secondary-button-clicked-text-color':
      stylesSettings.BUTTON_SECONDARY_TEXT_CLICKED,
    '--secondary-button-disabled-background-color':
      stylesSettings.BUTTON_SECONDARY_BACKGROUND_DISABLED,
    '--secondary-button-disabled-border-color':
      stylesSettings.BUTTON_SECONDARY_BORDER_DISABLED,
    '--secondary-button-disabled-text-color':
      stylesSettings.BUTTON_SECONDARY_TEXT_DISABLED,
    '--counter-finished-background-color':
      stylesSettings.COUNTER_FINISHED_BACKGROUND_COLOR,
    '--counter-background-color': stylesSettings.COUNTER_BACKGROUND_COLOR,
    '--counter-border-color': stylesSettings.COUNTER_BORDER_COLOR,
    '--counter-font-color': stylesSettings.COUNTER_FONT_COLOR,
  };
};
