import { ReducersMapObject } from 'redux';

import { Action } from '@ac/library-utils/dist/declarations';

import { keyGeneratorStep } from '@gss/views/makeKeys/KeyGenerator/store/reducer';

import { LocalStores } from '../interfaces/localStores';

export const localReducers: ReducersMapObject<LocalStores, Action> = {
  keyGeneratorStep,
};
