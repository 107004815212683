import { Big } from 'big.js';
import { ids } from 'config';
import i18next from 'i18next';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import {
  getAllPurchaseItems,
  getPurchaseItemsAvailability,
} from 'store/availability/selectors';
import { getCurrentBusinessDate } from 'store/propertyManagement/selectors';
import {
  getBreakdown,
  getGuestsNumber,
  getNights,
  getProfileId,
} from 'store/reservation/selectors';
import { ApiPurchaseItem, DailyPrice } from 'types/Api/Availability';
import {
  Folio,
  FolioWithCommunicationChannels,
  PaymentMethod,
} from 'types/Api/Cashiering';
import { CompanyDetails, Details } from 'types/Api/Profile';
import { GuestCount } from 'types/Api/Reservation';
import { FolioTransaction } from 'types/Api/Shared';
import Store from 'types/Store/CashieringStore';
import CheckInAddonStore from 'types/Store/CheckInAddonStore';
import { Configurator, DateManager, findAdditionalName } from 'utils';

import { CalculationRule, CommunicationMode } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import { FolioStyleType } from '@gss/_LEGACY_/types/Api/Entities';

import { mapTransactions } from '../aggregator/selectors';
import {
  getAddress,
  getCommunicationChannels,
  getCompanyCommunicationChannels,
  getIndividualCommunicationChannels,
  getTravelAgentCommunicationChannels,
} from '../profile/selectors';

const DATE_FORMAT = 'YYYY-MM-DD';

export const getAuthorizationAmount = (
  folioId: string,
  paymentMethods: PaymentMethod[]
) => {
  const method = paymentMethods.find((paymentMethod) =>
    paymentMethod.assignedFolioWindowIds.includes(folioId)
  );
  if (!method) return 0;
  const { creditCardDetails } = method;
  if (!creditCardDetails) return 0;
  if (
    creditCardDetails.creditCardType ===
    Configurator.creditCardTypeCodes.OFFLINE
  )
    return 0;
  const amount = creditCardDetails.authorizationAmount;
  if (amount) return amount;
  const { creditCardAuthorizations: authorizations } = creditCardDetails;
  const folioAuthorization = authorizations.find(
    (item) => item.folioWindowAssignmentIds.includes(folioId) && item.isActive
  );

  return folioAuthorization ? folioAuthorization.amount.amount : 0;
};

export const getAuthorizationIdsToVoid = (
  folios: Folio[],
  paymentMethods: PaymentMethod[]
) => {
  const ids = folios
    .filter((item) => !item.grossBalance.amount)
    .map((item) => item.id)
    // eslint-disable-next-line array-callback-return
    .map((id) => {
      const authorization = getFolioAuthorization(id, paymentMethods);
      if (authorization && authorization.amount) return authorization.id;
    })
    .filter((id) => !!id);

  return ids;
};

export const getFolioAuthorization = (
  folioId: string,
  paymentMethods: PaymentMethod[]
) => {
  const method = paymentMethods.find((paymentMethod) =>
    paymentMethod.assignedFolioWindowIds.includes(folioId)
  );
  if (!method) return null;
  const { creditCardDetails } = method;
  if (!creditCardDetails) return null;
  if (
    creditCardDetails.creditCardType ===
    Configurator.creditCardTypeCodes.OFFLINE
  )
    return null;
  const { creditCardAuthorizations: authorizations } = creditCardDetails;
  const folioAuthorization = authorizations.find(
    (item) => item.folioWindowAssignmentIds.includes(folioId) && item.isActive
  );

  return folioAuthorization || null;
};

export const getCheckOutFolioOperation = (state: Store) =>
  state.cashiering.checkOutFolio;

export const getWrongVersionedFolioIds = createSelector(
  getCheckOutFolioOperation,
  (checkOutFolio) => checkOutFolio.wrongVersionedFolios
);

export const getCashieringErrors = (state: Store) => state.cashiering.errors;

export const getAuthorizationDetails = (state: Store) =>
  state.cashiering.authorizationDetails;

export const getPaymentMethods = (state: Store) =>
  state.cashiering.paymentMethods;

export const getMinibarPendingCharges = (state: Store) =>
  state.cashiering.minibarPendingCharges;

export const getAllFolios = (state: Store) => state.cashiering.folios;

export const getNotPostedCharges = (state: Store) =>
  state.cashiering.notPostedCharges;

export const getChargeReservationOperation = (state: Store) =>
  state.cashiering.chargeReservation;

export const shouldChargeReservation = createSelector(
  getNotPostedCharges,
  (charges) => !!charges.length
);

export const getRecentlySettledFolioIds = createSelector(
  getAllFolios,
  (folios) => folios.filter((folio) => folio.isRecentlySettled)
);

export const getPreAuthorizationAmount = (state: Store) =>
  state.cashiering.preAuthorizationAmount;

export const getFolios = createSelector(getAllFolios, (folios) =>
  folios.filter((folio) => folio.billViewAllowed)
);

export const getFoliosForCheckOut = createSelector(getAllFolios, (folios) =>
  folios.filter(
    (folio) =>
      folio.billViewAllowed ||
      (folio.folioTypeCode.code === Configurator.folioTypeCodes.TRAVEL_AGENT &&
        folio.folioStatusCode.code === Configurator.folioStatusCodes.ACTIVE)
  )
);

export const shouldLeaveOpenAccount = createSelector(getAllFolios, (folios) =>
  folios.some(
    (folio) =>
      folio.folioStatusCode.code === Configurator.folioStatusCodes.ACTIVE &&
      !folio.remoteCheckOutAllowed
  )
);

export const getActiveFolios = createSelector(getFolios, (folios) =>
  folios.filter(
    (folio) =>
      folio.folioStatusCode.code === Configurator.folioStatusCodes.ACTIVE
  )
);

export const getFoliosToCheckoutRemotely = createSelector(
  getActiveFolios,
  (activeFolios) =>
    activeFolios.filter(({ remoteCheckOutAllowed }) => remoteCheckOutAllowed)
);

export const getActiveCompanyFolio = createSelector(getActiveFolios, (folios) =>
  folios.find(
    (folio) => folio.folioTypeCode.code === Configurator.folioTypeCodes.COMPANY
  )
);

export const getPreAuthorization = (state: Store) =>
  state.cashiering.preAuthorization;

export const getPreAuthorizationId = createSelector(
  getPreAuthorization,
  (preAuthorization) => preAuthorization.operationId
);

export const getIndividualFolio = createSelector(getFolios, (folios) =>
  folios.find(
    (folio) =>
      folio.folioTypeCode.code === Configurator.folioTypeCodes.INDIVIDUAL
  )
);

export const getSettledFolios = createSelector(getFolios, (folios) =>
  folios.filter(
    (folio) =>
      folio.folioStatusCode.code === Configurator.folioStatusCodes.SETTLED
  )
);

export const getNotBalancedFolios = createSelector(getFolios, (folios) =>
  folios.filter((folio) => folio.grossBalance.amount)
);

const getProfileName = (profileDetails: CompanyDetails | Details) => {
  if ('fullName' in profileDetails) {
    return profileDetails.fullName;
  }

  const secundSurname = findAdditionalName(profileDetails?.additionalNames);

  return [profileDetails.firstName, profileDetails.lastName, secundSurname]
    .join(' ')
    .trim();
};

export const getAllDetailedFolios = createSelector(
  getAllFolios,
  getWrongVersionedFolioIds,
  (folios, folioIds) =>
    folios
      .filter(
        (folio) =>
          folio.isRecentlySettled ||
          folio.folioStatusCode.code === Configurator.folioStatusCodes.ACTIVE ||
          folio.grossBalance.amount ||
          folioIds.includes(folio.id)
      )
      .filter((folio) => folio.transactions && folio.profile?.addresses)
      .map((folio) => {
        const transactions = parseTransactions(
          folio.transactions
            .filter(
              (transaction) =>
                !(
                  transaction.isVoided ||
                  transaction.isVoidedCreditCardOperation ||
                  transaction.isVoidingCreditCardOperation
                )
            )
            .map(mapTransactions)
        );
        const grossBalanceAmount = sumTransactions(transactions);

        return {
          ...folio,
          transactions,
          profileName: folio.profile.details
            ? getProfileName(folio.profile.details)
            : '',
          grossBalance: {
            ...folio.grossBalance,
            amount: grossBalanceAmount,
          },
          address: getAddress(folio.profile.addresses),
        };
      })
      .filter((folio) => folio.transactions.length)
);

export const getDetailedFolios = createSelector(
  getAllDetailedFolios,
  (folios) => folios.filter((folio) => folio.billViewAllowed)
);

export const getActiveDetailedFolios = createSelector(
  getDetailedFolios,
  (folios) =>
    folios.filter(
      (folio) =>
        folio.folioStatusCode.code === Configurator.folioStatusCodes.ACTIVE
    )
);

export const getBalancedFoliosToSettle = createSelector(
  getActiveDetailedFolios,
  (folios) =>
    folios.filter(
      (folio) => folio.transactions.length && !folio.grossBalance.amount
    )
);

export const getDisplayedFolioCount = createSelector(
  getDetailedFolios,
  (folios) => folios.length
);

const getMinibarDescription = (code: string) => {
  const item = Configurator.minibarItems.find(
    (item) => item.transactionCode === code
  );

  return item ? item.title : '';
};

export const parseTransactions = (transactions: FolioTransaction[]) => {
  const transactionDays = transactions.reduce((prev: string[], curr) => {
    if (!prev.includes(curr.date)) {
      return [...prev, curr.date];
    }

    return prev;
  }, []);
  const minibarCodes = Configurator.minibarItems.map(
    (item) => item.transactionCode
  );

  const transactionsPerDays = transactionDays.map((day) => {
    const minibarTransactions = minibarCodes
      .map((code) => {
        return transactions
          .map((elem) => {
            const title = getMinibarDescription(elem.transactionCode);

            return { ...elem, description: title || elem.description };
          })
          .filter((elem) => elem.transactionCode === code && elem.date === day)
          .reduce(
            (
              prev:
                | FolioTransaction
                | {
                    quantity: number;
                    totalGross: string;
                    totalNet: string;
                  },
              next
            ) => {
              const data = prev.quantity
                ? {
                    quantity: new Big(prev.quantity)
                      .plus(next.quantity)
                      .toNumber(),
                    totalGross: new Big(prev.totalGross)
                      .plus(next.totalGross)
                      .toFixed(2),
                    totalNet: new Big(prev.totalNet)
                      .plus(next.totalNet)
                      .toFixed(2),
                  }
                : next;

              return !prev.quantity
                ? {
                    ...next,
                  }
                : {
                    ...prev,
                    ...next,
                    ...data,
                  };
            },
            {
              quantity: 0,
              totalGross: '0',
              totalNet: '0',
            }
          ) as FolioTransaction;
      })
      .filter((item) => item.quantity);

    return minibarTransactions;
  });

  return [
    ...transactions.filter(
      (elem) => !minibarCodes.includes(elem.transactionCode)
    ),
    ...transactionsPerDays.reduce((prev, curr) => [...prev, ...curr], []),
  ].sort((a, b) => {
    const format = Configurator.dateFormat.shortDateFormat;

    return (
      DateManager.getTimestamp(a.date, format) -
      DateManager.getTimestamp(b.date, format)
    );
  });
};

export const sumTransactions = (transactions: any[]) =>
  transactions
    .reduce((prev: Big, next: any) => {
      const isPayment =
        next.transactionType === Configurator.transactionCodes.PAYMENT;

      return isPayment
        ? prev.minus(next.totalGross)
        : prev.plus(next.totalGross);
    }, new Big(0))
    .toNumber();

export const getAllFolioTransactions = createSelector(
  getAllDetailedFolios,
  (folios) =>
    folios
      .filter((item) => item.remoteCheckOutAllowed)
      .reduce((prev: any[], curr: any) => [...prev, ...curr.transactions], [])
);

export const getAllFolioCharges = createSelector(
  getAllFolioTransactions,
  sumTransactions
);

export const getMinibarTransactions = createSelector(
  getAllFolioTransactions,
  (transactions) => {
    const minibarTransactionCodes = Configurator.minibarTransactionCodes || [];
    const minibarCodes = minibarTransactionCodes.map((item) => item.code);

    return transactions.filter((item) =>
      minibarCodes.includes(item.transactionCode)
    );
  }
);

export const getMinibarCharges = createSelector(
  getMinibarTransactions,
  (transactions) =>
    transactions
      .reduce((prev: any, curr: any) => prev.plus(curr.totalGross), new Big(0))
      .toNumber()
);

export const getCurrentMinibarCharges = createSelector(
  getFormValues(ids.MINIBAR_FORM),
  (form: { [key: string]: any }) => {
    const minibarTransactionCodes = Configurator.minibarTransactionCodes || [];
    const items = form || {};

    return Object.keys(items)
      .reduce((sum, currentItem) => {
        const quantity = form[currentItem];
        const item = minibarTransactionCodes.find(
          (elem) => elem.id === currentItem
        )!;

        return item.defaultPostingValue && quantity
          ? new Big(item.defaultPostingValue).times(quantity).plus(sum)
          : sum;
      }, new Big(0))
      .toNumber();
  }
);

export const getDataFetching = (state: Store) => state.cashiering.dataFetching;

export const isCheckingOut = createSelector(
  getDataFetching,
  (dataFetching) => dataFetching.checkOut
);

export const getCheckOutOperation = (state: Store) => state.cashiering.checkOut;

export const getAddTransactionOperation = (state: Store) =>
  state.cashiering.transaction;

export const getTransactionOperationStatus = createSelector(
  getAddTransactionOperation,
  (transaction) => transaction.status
);

export const getVoidAuthorizationOperation = (state: Store) =>
  state.cashiering.voidAuthorization;

export const getUpdateAuthorizationOperation = (state: Store) =>
  state.cashiering.updateAuthorization;

export const isProcessingTransaction = createSelector(
  getAddTransactionOperation,
  (transaction) => {
    if (!transaction.status) return false;
    const {
      operationStatuses: { PAYMENT_FAILURE, PAYMENT_SUCCESS, USER_CANCELLED },
    } = Configurator;
    const { status } = transaction;

    return (
      status !== PAYMENT_FAILURE &&
      status !== PAYMENT_SUCCESS &&
      status !== USER_CANCELLED
    );
  }
);

export const getCashieringAccount = (state: Store) => state.cashiering.account;

export const getBillingInstructions = (state: Store) =>
  state.cashiering.billingInstructions;

export const getActiveBillingInstructions = createSelector(
  getBillingInstructions,
  (billingInstructions) => billingInstructions.instructions
);

export const getFinalInvoice = (state: Store) => state.cashiering.finalInvoice;

export const isCashieringFetching = createSelector(
  getDataFetching,
  (dataFetching) => dataFetching.cashiering
);

export const isFetching = createSelector(
  isCashieringFetching,
  isCheckingOut,
  (cashiering, checkingOut) => cashiering || checkingOut
);

export const getCompanyIdLinkedToReservation = createSelector(
  getCashieringAccount,
  (account) => account.linkedProfiles.companyProfileId
);

export const getGrossBalanceFromAllFolios = createSelector(
  getFolios,
  (folios) =>
    folios
      .map(({ grossBalance }) => grossBalance?.amount)
      .filter(isDefined)
      .reduce((prev, current) => prev.plus(current), new Big(0))
      .toNumber()
);

export const getConfigurationAddons = (language: string) =>
  Configurator.getPurchaseElements(language)
    .filter((elem) => elem.kioskAddon)
    .reduce(
      (prev, current) => ({
        ...prev,
        [current.code]: current,
      }),
      {}
    );

export const getAddonCodes = (language: string) =>
  Object.keys(getConfigurationAddons(language));

export const getPrice = (
  purchaseItem: ApiPurchaseItem,
  date: string,
  { nights, guests }: { nights: number; guests: any[] }
) => {
  const formattedDate = DateManager.getFormattedDate(date, DATE_FORMAT);
  const { dailyPrices, calculationRule } = purchaseItem;
  const currentPrice = dailyPrices.find((item) => item.day === formattedDate);
  const nightsCount = nights || 1;
  if (isPerPerson(calculationRule)) {
    return guests.reduce(
      (prev: any, curr: any) => {
        const { count: guestCount } = curr;
        const price = getPriceForGuest(curr, dailyPrices);

        const gross = new Big(price.gross)
          .times(nightsCount)
          .times(guestCount)
          .plus(prev.gross)
          .toNumber();

        const net = new Big(price.net)
          .times(nightsCount)
          .times(guestCount)
          .plus(prev.net)
          .toNumber();

        return {
          gross,
          net,
        };
      },
      {
        gross: 0,
        net: 0,
      }
    );
  }
  if (currentPrice && !isPerPerson(calculationRule)) return currentPrice.price;
};

export const getDailyPrices = (dailyPrices: any, date: string) => {
  const formattedDate = DateManager.getFormattedDate(date, DATE_FORMAT);

  return dailyPrices.filter((elem: any) => elem.day === formattedDate);
};

const isPerPerson = (code: string): boolean =>
  code === Configurator.calculationRuleCodes.PER_PERSON;

const purchaseElementsWithPricingForEveryQuest = (
  guests: GuestCount[],
  purchaseElements: ApiPurchaseItem[]
): ApiPurchaseItem[] => {
  return purchaseElements.filter(({ calculationRule, dailyPrices }) => {
    if (!dailyPrices?.length) return false;

    return (
      !isPerPerson(calculationRule) ||
      guests.every((guest) =>
        dailyPrices.find(
          ({ ageBucketId }: DailyPrice) => ageBucketId === guest.ageBucketId
        )
      )
    );
  });
};

export const getCalculationRule = (code: string) =>
  isPerPerson(code) ? 'PER_STAY' : 'PER_ITEM';

export const getPurchaseItems = createSelector(
  getAllPurchaseItems,
  getCurrentBusinessDate,
  getNights,
  getBreakdown,
  (items, date, nights, breakdown) => {
    const { guests } = breakdown;
    const reservationData = { nights, guests };

    return items.map((item) => {
      const price = getPrice(item, date, reservationData);

      return {
        ...item,
        price,
        dailyPrices: getDailyPrices(item.dailyPrices, date),
        calculationRule: {
          code: getCalculationRule,
          description: i18next.t(getCalculationRule(item.calculationRule)),
        },
        isPerPerson: isPerPerson(item.calculationRule),
      };
    });
  }
);

export const getPriceForGuest = (guest: GuestCount, prices: DailyPrice[]) => {
  const ageBucketPrice = prices.find(
    (item) => item.ageBucketId === guest.ageBucketId
  );

  return ageBucketPrice
    ? ageBucketPrice.price
    : {
        gross: 0,
        net: 0,
      };
};

export const getPurchaseItemsAvailableToOrder = createSelector(
  getAllPurchaseItems,
  getPurchaseItemsAvailability,
  getGuestsNumber,
  (state: CheckInAddonStore) => state.checkInAddon.confirmedNewPurchaseOrders,
  (
    purchaseItems,
    purchaseItemsAvailability,
    guestCount,
    confirmedOrderItem = []
  ) => {
    const availableOrders = [...purchaseItemsAvailability];

    confirmedOrderItem.forEach((order) => {
      if (order.purchaseElementId) {
        const itemIndex = availableOrders.findIndex(
          (item) => item.id === order.purchaseElementId
        );

        if (itemIndex > -1) {
          availableOrders[itemIndex] = {
            id: order.purchaseElementId,
          };
        } else {
          availableOrders.push({
            id: order.purchaseElementId,
          });
        }
      }
    });

    const purchaseElementsWithAvailability = availableOrders
      .map((item) => {
        const purchaseElementData = purchaseItems.find(
          (element) => element.id === item.id
        );

        if (!purchaseElementData) return;

        return {
          ...purchaseElementData,
          ...item,
        };
      })
      .filter(isDefined);

    return purchaseElementsWithAvailability.filter((item): boolean => {
      if (!item.inventoryItemId) return true;

      if (item.calculationRule === CalculationRule.PerPerson) {
        return (item.totalRemaining ?? 0) >= guestCount;
      } else {
        return !!item.totalRemaining;
      }
    });
  }
);

export const getAddons = (state: any, props: any) =>
  createSelector(
    getPurchaseItemsAvailableToOrder,
    getCurrentBusinessDate,
    getNights,
    getBreakdown,
    (items, date, nights, breakdown) => {
      const {
        i18n: { language },
        t,
      } = props;
      const { guests } = breakdown;
      const configurationAddons: { [key: string]: any } =
        getConfigurationAddons(language);
      const addonCodes = getAddonCodes(language);
      const reservationData = { nights, guests };
      const newitems = items.filter((item) => addonCodes.includes(item.code));
      const newItemsWithCompletePricing =
        purchaseElementsWithPricingForEveryQuest(guests, newitems);

      return newItemsWithCompletePricing.map((item) => {
        const price = getPrice(item, date, reservationData);
        const addon = configurationAddons[item.code];

        return {
          ...item,
          price,
          dailyPrices: getDailyPrices(item.dailyPrices, date),
          calculationRule: {
            code: getCalculationRule,
            description: t(getCalculationRule(item.calculationRule)),
          },
          longDescription: addon.longDescription,
          displayName: Configurator.getDescription(addon.displayName),
          isPerPerson: isPerPerson(item.calculationRule),
        };
      });
    }
  )(state);

export const getFoliosWithCommunicationChannels = createSelector(
  getCommunicationChannels,
  getCompanyCommunicationChannels,
  getTravelAgentCommunicationChannels,
  getIndividualCommunicationChannels,
  getProfileId,
  getDetailedFolios,
  (
    guestCommunicationChannels,
    companyCommunicationChannels,
    travelAgentCommunicationChannels,
    individualCommunicationChannels,
    profileId,
    folios
  ) =>
    folios.map((folio) => {
      const {
        folioTypeCodes: { INDIVIDUAL, COMPANY, TRAVEL_AGENT },
      } = Configurator;
      const {
        folioTypeCode: { code },
        profileId: id,
      } = folio;
      const isGuestFolio = id === profileId;
      let communicationChannels;
      switch (code) {
        case INDIVIDUAL:
          communicationChannels = isGuestFolio
            ? guestCommunicationChannels
            : individualCommunicationChannels;
          break;
        case COMPANY:
          communicationChannels = companyCommunicationChannels || [];
          break;
        case TRAVEL_AGENT:
          communicationChannels = travelAgentCommunicationChannels || [];
          break;
        default:
          return [];
      }

      return {
        ...folio,
        communicationChannels,
      };
    })
);

export const isEmailPerFolio = (folio: FolioWithCommunicationChannels) => {
  if (!folio?.communicationChannels?.length) return false;

  return folio.communicationChannels.some((communicationChannel) => {
    return communicationChannel.mode === CommunicationMode.Email;
  });
};

export const getEmailToSendInvoice = (state: Store) =>
  state.cashiering.emailToSendInvoice;

export const getEmailsSent = (state: Store) => state.cashiering.emailsSent;

export const getAvailableFolioStyleIds = (
  state: Store
): Record<string, string[]> => {
  return state.cashiering.availableFolioStyleIds;
};

export const getAvailableFolioStyles = createSelector(
  [getAvailableFolioStyleIds],
  (availableFolioStyleIds): Record<string, FolioStyleType[]> => {
    return Object.entries(availableFolioStyleIds).reduce<
      Record<string, FolioStyleType[]>
    >((prev, [key, value]) => {
      prev[key] = value
        .map((id) => Configurator.folioStyles.find((style) => style.id === id))
        .filter(isDefined);

      return prev;
    }, {});
  }
);

export const getIsEveryFolioHasAvailableFolioStyle = createSelector(
  [getFoliosToCheckoutRemotely, getAvailableFolioStyles],
  (foliosToCheckoutRemotely, availableFolioStyles) =>
    foliosToCheckoutRemotely.every(
      (folio) => availableFolioStyles[folio.id]?.length
    )
);
