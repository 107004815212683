import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ApiError } from 'types/Api/Shared';

import { Loader } from '@ac/kiosk-components';

import { Modal as BaseModal } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import Alert from './Alert';
import DayUse from './DayUse';
import EditEmail from './EditEmail';
import MissingEmail from './MissingEmail';
import styles from './Modal.style';
import PaymentError from './PaymentError';

export type ModalType =
  | 'error'
  | 'fetching'
  | 'payment'
  | 'missingEmail'
  | 'editEmail'
  | 'dayUse';

export interface ModalProps extends WithTranslation, WithStyles<typeof styles> {
  type?: ModalType;
  isOpen: boolean;
  onClick?: () => void;
  onCancel?: () => void;
  defaultError?: string;
  size?: 'thin' | 'wide';
  error?: string;
  customErrorCode?: string;
  disableAutoFocus?: boolean;
  onSubmit?: (values?: any) => void;
  currentFolioNumber?: number;
  className?: string;
  displayedEmail?: string;
  customDescription?:
    | Array<ApiError | JSX.Element>
    | string
    | HTMLParagraphElement;
}

class Modal extends PureComponent<ModalProps> {
  public static defaultProps = {
    isOpen: false,
    defaultError: '',
    currentFolioNumber: 0,
    className: '',
    disableAutoFocus: false,
  };

  public render() {
    const { type, isOpen, onClick, className, disableAutoFocus } = this.props;
    const onBackdropClick = type === 'fetching' ? undefined : onClick;

    return (
      <BaseModal
        open={isOpen}
        onBackdropClick={onBackdropClick}
        disableAutoFocus={disableAutoFocus}
      >
        <div
          className={classNames(
            'with-default-kiosk-components-theme',
            this.modalClass,
            className
          )}
        >
          {this.renderContent()}
        </div>
      </BaseModal>
    );
  }

  private get modalClass() {
    const { type, size, classes } = this.props;
    if (type === 'fetching') return classes.squareWrapper;

    const wideTypes: ModalType[] = ['payment', 'missingEmail', 'dayUse'];

    const wrapperClass =
      size === 'wide' || wideTypes.includes(type!)
        ? classes.wideWrapper
        : classes.thinWrapper;

    return wrapperClass;
  }

  private renderContent = () => {
    const {
      t,
      type,
      classes,
      children,
      onClick,
      defaultError,
      error,
      customErrorCode,
      onCancel,
      onSubmit,
      currentFolioNumber,
      displayedEmail,
      customDescription,
    } = this.props;
    switch (type) {
      case 'error':
        return (
          <Alert
            onClick={onClick}
            defaultError={defaultError}
            error={error}
            customErrorCode={customErrorCode}
            customDescription={customDescription}
          />
        );
      case 'payment':
        return <PaymentError onClick={onClick} />;
      case 'fetching':
        return (
          <Loader
            title={t('LOADING')}
            description={t('PLEASE_WAIT')}
            className={classes.fetchingContent}
          />
        );
      case 'missingEmail':
        return (
          <MissingEmail
            onSubmit={onSubmit}
            currentFolioNumber={currentFolioNumber}
          />
        );
      case 'editEmail':
        return (
          <EditEmail
            onCancel={onCancel}
            displayedEmail={displayedEmail}
            currentFolioNumber={currentFolioNumber}
          />
        );
      case 'dayUse':
        return <DayUse />;
      default:
        return children;
    }
  };
}

const StyledModal = withStyles(styles)(Modal);

export default withTranslation()(StyledModal);
