import { reduxStore } from '@gss/store';
import { getCustomMessages } from '@gss/store/settings/selectors';

/**
 * Known error codes that might be returned by KIOSK API.
 * In case of which we should notify user with specific message (for example process session ended)
 *
 * Also this kind of errors are not something unexpected and won't be logged by Logger
 */
const knownErrorCodesMap = {
  NO_ROOM_AVAILABLE: {
    code: 'NoRoomsAvailable',
    get message(): string | undefined {
      const customMessages = getCustomMessages(reduxStore.getState());

      return customMessages?.ROOM_NOT_READY_MESSAGE;
    },
  },
};

export const knownErrorCodes = knownErrorCodesMap as {
  [key in keyof typeof knownErrorCodesMap]: {
    code: string;
    message?: string;
  };
};
