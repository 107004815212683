import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  AlignItems,
  Box,
  Flex,
  FlexDirection,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { useRouter } from '@gss/router';

import './Stepper.scss';

interface StepperProps {
  className?: string;
  dataTestSelector?: string;
}

export const Stepper = ({
  className,
  dataTestSelector,
}: StepperProps): JSX.Element | null => {
  const { t } = useTranslation();
  const router = useRouter();

  if (!router.stepCounter) return null;

  return (
    <Flex
      dataTestSelector={dataTestSelector}
      direction={FlexDirection.column}
      alignItems={AlignItems.flexEnd}
      className={classNames('stepper-component', className)}
    >
      <Box className="text-right spacing-right-lg spacing-bottom-xs step-counter">
        <Text size={TextSize.xs}>{t('COMPONENTS.STEPPER.STEPS_TITLE')}</Text>
        <Text size={TextSize.md}>
          {router.stepCounter.currentStep}/{router.stepCounter.totalSteps}
        </Text>
      </Box>
      <Flex className="gap-xs steps-graph">
        {Array(router.stepCounter.totalSteps)
          .fill(null)
          .map((_, index) => {
            return (
              <div
                data-test-selector="stepper-indicator-selector"
                key={index}
                className={classNames(
                  'step-box',
                  index + 1 <= (router.stepCounter?.currentStep ?? 0)
                    ? 'step-box-finished'
                    : 'step-box-todo'
                )}
              />
            );
          })}
      </Flex>
    </Flex>
  );
};
