import { isDefined } from '@ac/library-utils/dist/utils';

import { FeatureToggleDetailsDto } from '@gss/api/KioskApi/entries';
import { BaseObject } from '@gss/types/shared';

export type FeatureTogglesCodeMap<Type> = {
  [key in keyof Type]: Record<'key', string>;
};

export const normalizeFeatureToggles = <
  DataInputType extends BaseObject<boolean>
>(
  featureToggleMap: FeatureTogglesCodeMap<DataInputType>,
  featureToggles?: FeatureToggleDetailsDto[]
): DataInputType | undefined => {
  if (!featureToggles) return undefined;

  const mapEntries = Object.entries(featureToggleMap);
  const entries = featureToggles
    .filter(isDefined)
    .map(({ name, value }) => {
      const ftName = mapEntries.find(
        ([, configuration]) => configuration.key === name
      );

      return isDefined(ftName?.[0])
        ? [(ftName as [string, Record<'key', string>])[0], value]
        : undefined;
    })
    .filter(isDefined);

  return entries.length
    ? (Object.fromEntries(entries) as DataInputType)
    : undefined;
};
