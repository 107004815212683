import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Box,
  Circular,
  CircularType,
  Color,
  Flex,
  IconTypes,
  Text,
} from '@ac/kiosk-components';

import { getCustomMessages } from '@gss/store/settings/selectors';

interface ETDNotificationProps {
  etd: string;
  dataTestSelector?: string;
}

export const ETDNotification = ({
  etd,
  dataTestSelector,
}: ETDNotificationProps): JSX.Element => {
  const customMessages = useSelector(getCustomMessages);

  return (
    <Flex className="gap-lg" dataTestSelector={dataTestSelector}>
      <Circular
        icon={IconTypes.exclamationMark}
        iconColor={Color.primary}
        type={CircularType.warning}
        size={44}
      />
      <Box>
        <Text>
          <Trans
            i18nKey="COMPONENTS.MODALS.ETD_MODAL.DESCRIPTION"
            values={{ time: etd }}
          />
        </Text>
        <Text>{customMessages?.KIOSK_DAY_USE_MESSAGE}</Text>
      </Box>
    </Flex>
  );
};
