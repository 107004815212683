import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Body, Modal, View } from 'components';
import { QuickCheckOutModal } from 'components/modals';
import { History } from 'history';
import { compose } from 'redux';
import { clearState } from 'store/actions';
import { fetchAvailablePurchaseItems } from 'store/availability/actions';
import {
  chargeReservation,
  fetchAdditionalFolioInfo,
  fetchBillingInstructions,
  fetchCashieringAccount,
  fetchFolios,
  fetchNotPostedCharges,
  fetchPaymentMethods,
  fullCheckOut,
  getChargeReservationStatus,
} from 'store/cashiering/actions';
import {
  getActiveBillingInstructions,
  getActiveFolios,
  getAllFolios,
  getIsEveryFolioHasAvailableFolioStyle,
  shouldChargeReservation,
} from 'store/cashiering/selectors';
import { continueCheckOutAsFastTrackProcess } from 'store/checkOutProcess/actions';
import {
  getIsFastTrackCheckOutPossible,
  getIsFastTrackCheckOutProcess,
  getIsReviewChargesPossible,
} from 'store/checkOutProcess/selectors';
import {
  chooseProfile,
  fetchCommunicationChannels,
  fetchCompany,
  fetchProfile,
  fetchProfileInternalMemberships,
} from 'store/profile/actions';
import { getAllProfiles } from 'store/profile/selectors';
import { fetchCurrentDate } from 'store/propertyManagement/actions';
import { fetchRatePlans } from 'store/rateManager/actions';
import {
  fetchBreakdown,
  fetchReservation,
  fetchReservationCheckOut,
  fetchReservationExtended,
  fetchReservationPurchases,
} from 'store/reservation/actions';
import { getAccountId, getAllReservations } from 'store/reservation/selectors';
import { fetchRoomDetails, fetchRoomType } from 'store/room/actions';
import { getCompanyProfileIds, getErrors } from 'store/selectors';
import { refreshUi } from 'store/ui/actions';
import { BillingInstruction, Folio } from 'types/Api/Cashiering';
import { CommunicationChannel, Profile } from 'types/Api/Profile';
import { ApiError } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator } from 'utils';
import Router, { Path, paths } from 'utils/Router';

import { CommunicationMode } from '@ac/library-api';

import { Header } from '@gss/components/layout';

import CheckOutAuthForm from './CheckOutAuthForm';

interface PassedProps {}

interface CheckOutAuthProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation {
  fetchReservationCheckOut: typeof fetchReservationCheckOut;
  fetchBreakdown: typeof fetchBreakdown;
  fetchRoomType: typeof fetchRoomType;
  fetchReservationPurchases: typeof fetchReservationPurchases;
  fetchAvailablePurchaseItems: typeof fetchAvailablePurchaseItems;
  fetchProfile: typeof fetchProfile;
  fetchCompany: typeof fetchCompany;
  fetchRoomDetails: typeof fetchRoomDetails;
  fetchBillingInstructions: typeof fetchBillingInstructions;
  fetchAdditionalFolioInfo: typeof fetchAdditionalFolioInfo;
  fetchPaymentMethods: typeof fetchPaymentMethods;
  fetchFolios: typeof fetchFolios;
  fullCheckOut: typeof fullCheckOut;
  fetchCurrentDate: typeof fetchCurrentDate;
  clearState: typeof clearState;
  chooseProfile: typeof chooseProfile;
  fetchCashieringAccount: typeof fetchCashieringAccount;
  continueCheckOutAsFastTrackProcess: typeof continueCheckOutAsFastTrackProcess;
  fetchProfileInternalMemberships: typeof fetchProfileInternalMemberships;
  billingInstructions: BillingInstruction[];
  folios: Folio[];
  linkedCompanyIds: string[];
  reservations: Array<{
    id: string;
    profileId: string;
    roomId: string;
    roomTypeId: string;
    statusCode: {
      code: string;
    };
    departureDate: string;
    arrivalDate: string;
  }>;
  accountId: string;
  fetchReservation: typeof fetchReservation;
  profiles: Profile[];
  fetchCommunicationChannels: typeof fetchCommunicationChannels;
  allFolios: Folio[];
  fetchReservationExtended: typeof fetchReservationExtended;
  fetchRatePlans: typeof fetchRatePlans;
  refreshUi: typeof refreshUi;
  errors: ApiError[];
  fetchNotPostedCharges: typeof fetchNotPostedCharges;
  getChargeReservationStatus: typeof getChargeReservationStatus;
  chargeReservation: typeof chargeReservation;
  isEveryFolioHasAvailableFolioStyle: ReturnType<
    typeof getIsEveryFolioHasAvailableFolioStyle
  >;
  shouldChargeReservation: boolean;
  isReviewChargesPossible: boolean;
  isFastTrackCheckOutPossible: boolean;
  isFastTrackCheckOutProcess: boolean;
}

export const redirect = (history: History<unknown>) => {
  const singleReservationSteps = Router.allowedSteps.slice(1);
  const paymentStep = singleReservationSteps.find(
    (elem) => !elem.url.includes('reservations')
  );
  const step = paymentStep ? paymentStep.url : Router.nextStepURL;
  history.push(step);
};

interface CheckOutAuthState {
  checkoutAuthError: boolean;
  isEmailModalOpen: boolean;
  isQuickCheckOutModalOpen: boolean;
  isLoading: boolean;
}

class CheckOutAuth extends PureComponent<CheckOutAuthProps, CheckOutAuthState> {
  public state = {
    checkoutAuthError: false,
    isEmailModalOpen: false,
    isQuickCheckOutModalOpen: false,
    isLoading: false,
  };

  public componentDidMount() {
    this.clearState();
  }

  public render() {
    const {
      checkoutAuthError,
      isEmailModalOpen,
      isLoading,
      isQuickCheckOutModalOpen,
    } = this.state;
    const { CHECK_OUT_NOT_POSSIBLE } = Configurator.getTranslationCodes();
    const defaultError = Configurator.getTranslation(CHECK_OUT_NOT_POSSIBLE);

    return (
      <View
        idle={{ type: 'modal' }}
        modal={{ isLoading, disableLoader: checkoutAuthError }}
      >
        <Modal
          isOpen={checkoutAuthError}
          type="error"
          defaultError={defaultError}
          onClick={this.onModalClick}
        />
        <Modal
          isOpen={isEmailModalOpen}
          type="missingEmail"
          onSubmit={this.onModalSubmit}
        />

        {!checkoutAuthError && isQuickCheckOutModalOpen && (
          <QuickCheckOutModal
            onCancel={this.onQuickCheckoutCancel}
            onConfirm={this.onQuickCheckoutConfirm}
          />
        )}

        <Header title="Check Out" />
        <Body>
          <CheckOutAuthForm onSubmit={this.onSubmit} />
        </Body>
      </View>
    );
  }

  public onModalClick = () => {
    this.clearState();
    this.setState({ checkoutAuthError: false });
    this.props.history.replace(paths.WELCOME);
  };

  private clearState = () => {
    const { clearState } = this.props;
    clearState();
  };

  private onQuickCheckoutCancel = () => {
    this.configureRouting();
    this.goToNextStep();
  };

  private onQuickCheckoutConfirm = async () => {
    const { continueCheckOutAsFastTrackProcess, history } = this.props;
    this.closeQuickCheckOutModal();
    this.enableLoading();
    await continueCheckOutAsFastTrackProcess();
    history.push(Router.nextStepURL);
  };

  private onSubmit = async ({
    lastName,
    roomNumber,
  }: {
    lastName: string;
    roomNumber: string;
  }) => {
    const {
      refreshUi,
      fetchCurrentDate,
      fetchRoomDetails,
      fetchRoomType,
      fetchProfile,
    } = this.props;

    try {
      this.enableLoading();
      await refreshUi();
      await this.fetchReservationCheckOut(lastName, roomNumber);

      const {
        reservations: [reservation],
        reservations,
      } = this.props;
      const { roomId, roomTypeId } = reservation;

      await Promise.all([
        fetchCurrentDate(),
        fetchRoomDetails(roomId),
        fetchRoomType(roomTypeId),
        ...reservations.map((reservation) =>
          fetchProfile(reservation.profileId)
        ),
      ]);

      if (reservations.length > 1) {
        await this.moveToReservationChoose();
      } else {
        const { id: reservationId } = reservation;

        Router.removeStep(Path.checkOut, 'RESERVATIONS');
        await this.continueCheckoutCurrentReservation(reservationId);
      }
    } catch (error) {
      this.disableLoading();
      // eslint-disable-next-line no-console
      console.error(error);
      this.setState({ checkoutAuthError: true });
    }
  };

  private configureRouting = () => {
    const { history, billingInstructions, folios } = this.props;
    const singleReservationSteps = Router.allowedSteps.slice(1);
    const shouldSkipPaymentStep =
      folios.length > 2 ||
      billingInstructions.length > 0 ||
      (folios.length > 1 &&
        folios
          .map((elem) => elem.profileRoleCode.code)
          .includes(Configurator.profileRoles.COMPANY));

    if (shouldSkipPaymentStep) {
      const noPaymentStep = singleReservationSteps.find(
        (elem) =>
          !elem.url.includes('reservations') && !elem.url.includes('payment')
      );
      // eslint-disable-next-line no-console
      console.warn('Company invoice step is going to be skipped');
      Router.removeStep(Path.checkOut, 'RESERVATIONS');
      Router.removeStep(Path.checkOut, 'PAYMENT');

      return history.push(
        noPaymentStep ? noPaymentStep.url : Router.nextStepURL
      );
    }
  };

  private moveToReservationChoose = async () => {
    const { history } = this.props;
    const { checkoutAuthError } = this.state;

    if (!checkoutAuthError) return history.push(Router.nextStepURL);
  };

  private continueCheckoutCurrentReservation = async (
    reservationId: string
  ) => {
    const {
      chooseProfile,
      fetchReservation,
      fetchBreakdown,
      fetchCompany,
      fetchCashieringAccount,
      fetchBillingInstructions,
      fetchPaymentMethods,
      fetchRatePlans,
      fetchAvailablePurchaseItems,
      fetchReservationPurchases,
      fetchReservationExtended,
      fetchCommunicationChannels,
      fetchProfileInternalMemberships,
    } = this.props;

    await fetchReservation(reservationId);

    const {
      accountId,
      reservations: [reservation],
    } = this.props;
    const { profileId, departureDate, arrivalDate } = reservation;

    chooseProfile(profileId);

    await Promise.all([
      this.fetchReservationCharges(reservationId),
      fetchCashieringAccount(accountId),
      fetchProfileInternalMemberships(),
    ]);

    const { linkedCompanyIds } = this.props;

    await this.fetchFolioCompleteData();

    const isDocumentSelectionEnabled = Configurator.getSwitch(
      Configurator.switchCodes.SHOW_FISCAL_DOCUMENT_SELECTION
    );

    if (
      isDocumentSelectionEnabled &&
      !this.props.isEveryFolioHasAvailableFolioStyle
    ) {
      this.setState({ checkoutAuthError: true });

      return;
    }

    await Promise.all([
      ...linkedCompanyIds.map((id: string) => fetchCompany(id)),
      fetchBreakdown(reservationId),
      fetchPaymentMethods(),
      fetchBillingInstructions(accountId),
      fetchCommunicationChannels(),
      fetchReservationPurchases(reservationId),
      fetchAvailablePurchaseItems(arrivalDate, departureDate),
      fetchRatePlans(),
      fetchReservationExtended(reservationId),
    ]);

    this.checkReservationStatus();

    const email = this.getProfileEmail();
    if (!email) return this.openEmailModal();

    this.continueCheckOutProcess();
  };

  private fetchReservationCharges = async (reservationId: string) => {
    const {
      fetchNotPostedCharges,
      chargeReservation,
      getChargeReservationStatus,
    } = this.props;

    await fetchNotPostedCharges();

    const { shouldChargeReservation } = this.props;

    if (shouldChargeReservation) {
      await chargeReservation(reservationId);
      await getChargeReservationStatus();
    }
  };

  private fetchReservationCheckOut = async (
    lastName: string,
    roomNumber: string
  ) => {
    const { fetchReservationCheckOut } = this.props;
    await fetchReservationCheckOut(lastName.trim(), roomNumber.trim());
    const { errors } = this.props;
    if (errors.length > 0) {
      const { message } = errors[0];
      throw new Error(message);
    }
  };

  private getProfileEmail = () => {
    const { profiles } = this.props;
    const profile = profiles[0];
    const { communicationDetails } = profile;
    if (communicationDetails.length) {
      const communicationChannel = communicationDetails.find(
        (channel: CommunicationChannel) =>
          channel.mode === CommunicationMode.Email
      );

      return communicationChannel && communicationChannel.details;
    }

    return false;
  };

  private openEmailModal = () => this.setState({ isEmailModalOpen: true });

  private closeEmailModal = () => this.setState({ isEmailModalOpen: false });

  private openQuickCheckOutModal = () =>
    this.setState({ isQuickCheckOutModalOpen: true });

  private closeQuickCheckOutModal = () =>
    this.setState({ isQuickCheckOutModalOpen: false });

  private onModalSubmit = async () => {
    this.closeEmailModal();
    this.continueCheckOutProcess();
  };

  private continueCheckOutProcess = async () => {
    const { isFastTrackCheckOutPossible, isReviewChargesPossible } = this.props;
    this.enableLoading();
    if (!isFastTrackCheckOutPossible) {
      this.configureRouting();
    }

    if (!isReviewChargesPossible) {
      await this.fullCheckOut();
    } else if (isFastTrackCheckOutPossible) {
      this.disableLoading();

      return this.openQuickCheckOutModal();
    }

    this.goToNextStep();
  };

  private fetchFolioCompleteData = async () => {
    const { fetchAdditionalFolioInfo, fetchFolios } = this.props;

    await fetchFolios();

    const { folios } = this.props;
    await Promise.all(folios.map(({ id }) => fetchAdditionalFolioInfo(id)));
  };

  private fullCheckOut = async () => {
    const { fullCheckOut } = this.props;
    Router.removeStep(Path.checkOut, 'RESERVATIONS');
    Router.removeStep(Path.checkOut, 'PAYMENT');

    return fullCheckOut();
  };

  private checkReservationStatus = () => {
    const {
      reservations: [reservation],
      allFolios: folios,
    } = this.props;
    const {
      statusCode: { code },
    } = reservation;
    const { TRAVEL_AGENT } = Configurator.folioTypeCodes;
    const { ACTIVE } = Configurator.folioStatusCodes;
    const isCorrectReservationStatus =
      code === Configurator.reservationStatuses.DUE_OUT;
    if (!isCorrectReservationStatus) {
      throw new Error(`Reservation has type ${code}`);
    }
    const isTravelAgentError = folios.some(
      (folio) =>
        folio.folioTypeCode.code === TRAVEL_AGENT && folio.grossBalance.amount
    );
    if (isTravelAgentError) {
      throw new Error(
        'There is a travel agent folio that cannot be checked out'
      );
    }
    const isFolioError = folios.some(
      (folio) =>
        !folio.billViewAllowed &&
        !folio.remoteCheckOutAllowed &&
        folio.folioStatusCode.code === ACTIVE &&
        folio.folioTypeCode.code !== TRAVEL_AGENT &&
        folio.grossBalance.amount !== 0
    );
    if (isFolioError) {
      throw new Error('One or more folios cannot be checked out');
    }
  };

  private enableLoading = () => {
    this.setState({ isLoading: true });
  };

  private disableLoading = () => {
    this.setState({ isLoading: false });
  };

  private goToNextStep = () => {
    const { history } = this.props;
    const { checkoutAuthError } = this.state;
    if (!checkoutAuthError) redirect(history);
  };
}

const mapStateToProps = (state: Store) => ({
  isEveryFolioHasAvailableFolioStyle:
    getIsEveryFolioHasAvailableFolioStyle(state),
  linkedCompanyIds: getCompanyProfileIds(state),
  reservations: getAllReservations(state),
  folios: getActiveFolios(state),
  isReviewChargesPossible: getIsReviewChargesPossible(state),
  isFastTrackCheckOutProcess: getIsFastTrackCheckOutProcess(state),
  allFolios: getAllFolios(state),
  billingInstructions: getActiveBillingInstructions(state),
  profiles: getAllProfiles(state),
  accountId: getAccountId(state),
  errors: getErrors(state),
  shouldChargeReservation: shouldChargeReservation(state),
  isFastTrackCheckOutPossible: getIsFastTrackCheckOutPossible(state),
});

const mapDispatchToProps = {
  fetchReservationCheckOut,
  fetchCashieringAccount,
  fetchProfile,
  fetchBillingInstructions,
  fetchCompany,
  fetchRoomDetails,
  fetchRoomType,
  fetchBreakdown,
  fetchFolios,
  chooseProfile,
  clearState,
  fetchReservationPurchases,
  fetchAvailablePurchaseItems,
  fetchPaymentMethods,
  fetchCurrentDate,
  fullCheckOut,
  fetchReservation,
  fetchCommunicationChannels,
  fetchRatePlans,
  fetchReservationExtended,
  refreshUi,
  fetchNotPostedCharges,
  getChargeReservationStatus,
  chargeReservation,
  continueCheckOutAsFastTrackProcess,
  fetchAdditionalFolioInfo,
  fetchProfileInternalMemberships,
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckOutAuth) as (props: PassedProps) => JSX.Element;
