import {
  createAction,
  createAsyncAction,
} from '@ac/library-utils/dist/redux-utils';

import {
  CheckInAvailableReservationDto,
  CheckInProfileDetailsDto,
  CheckInReservationDetailsDto,
} from '@gss/api/KioskApi/entries';

import {
  ChangeGuestProfilePayload,
  StartCheckInProcessPayload,
  StartCheckInSessionPayload,
} from './interfaces';

const ACTION_PREFIX = '@flow/checkIn/';

export const authenticateGuest = createAsyncAction<
  StartCheckInProcessPayload,
  CheckInAvailableReservationDto[]
>(`${ACTION_PREFIX}authenticateGuest`);

export const startCheckInSession = createAsyncAction<
  StartCheckInSessionPayload,
  string
>(`${ACTION_PREFIX}startCheckInSession`);

export const startCheckInProcess = createAction<StartCheckInProcessPayload>(
  `${ACTION_PREFIX}startCheckInProcess`
);

export const closeCheckInProcess = createAsyncAction<() => void, void>(
  `${ACTION_PREFIX}closeCheckInProcess`
);

export const resetAuthProcess = createAction(
  `${ACTION_PREFIX}resetAuthProcess`
);

export const selectReservation = createAction<string>(
  `${ACTION_PREFIX}selectReservation`
);

export const selectProfile = createAction<string>(
  `${ACTION_PREFIX}selectProfile`
);

export const changeGuestProfile = createAsyncAction<
  ChangeGuestProfilePayload,
  void
>(`${ACTION_PREFIX}changeGuestProfile`);

export const fetchCheckInReservationDetails = createAsyncAction<
  void,
  CheckInReservationDetailsDto
>(`${ACTION_PREFIX}fetchCheckInReservationDetails`);

export const fetchCheckInProfileDetails = createAsyncAction<
  void,
  CheckInProfileDetailsDto
>(`${ACTION_PREFIX}fetchCheckInProfileDetails`);
