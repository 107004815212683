import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  BasicModalSize,
  ErrorModal,
  Field,
  FormHeader,
  Grid,
  IconTypes,
  LargeButton,
  Section,
  Text,
} from '@ac/kiosk-components';
import { Form, FormApi, formFieldFactory } from '@ac/react-infrastructure';

import { Body, Header } from '@gss/components/layout';
import { QRScannerModal, ScannerData } from '@gss/components/modals';
import { useRouter } from '@gss/router';
import {
  resetAuthProcess,
  startMakeKeysProcess,
} from '@gss/store/flows/makeKeysFlow/actions';
import {
  getIsMakeKeysProcessReady,
  getIsMakeKeysSecondFactorNeeded,
  getMakeKeysAuthenticationStatus,
} from '@gss/store/flows/makeKeysFlow/selectors';
import {
  AuthProcessDataSource,
  AuthProcessStatus,
} from '@gss/types/authProcess';
import { mapFieldRenderProps } from '@gss/utils/form';
import { makeKeysTestSelector } from '@gss/utils/testSelectors';

import { MakeKeysFooter, MakeKeysView } from '../components';

import { KeyAuthFormValues, useValidator } from './formConfig';

const TEST_SELECTOR_PREFIX = 'authorization';

const FormField = formFieldFactory<KeyAuthFormValues>();

export const KeyAuth = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const validator = useValidator();

  const [isQRScannerOpen, setQRScannerOpen] = useState(false);

  const isSecondFactorVisible = useSelector(getIsMakeKeysSecondFactorNeeded);
  const isMakeKeyProcessReady = useSelector(getIsMakeKeysProcessReady);
  const authenticationStatus = useSelector(getMakeKeysAuthenticationStatus);

  const clearProcess = (): void => {
    dispatch(resetAuthProcess());
  };

  const toggleQRScanner = (): void => {
    setQRScannerOpen((isOpen) => !isOpen);
  };

  const handleQRScan = (formData: ScannerData): void => {
    toggleQRScanner();
    handleFormSubmit(formData);
  };

  const handleFormSubmit = (
    formData: KeyAuthFormValues | ScannerData,
    formApi?: FormApi<KeyAuthFormValues>
  ): void => {
    dispatch(
      startMakeKeysProcess({
        guestLastName: formData.lastName,
        confirmationNumber: formData.identificationNumber,
        roomNumber: formData.roomNumber,
        dataSource: formApi
          ? AuthProcessDataSource.form
          : AuthProcessDataSource.qrCode,
      })
    );
  };

  useEffect(() => {
    if (isMakeKeyProcessReady) {
      router.goToNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMakeKeyProcessReady]);

  return (
    <MakeKeysView>
      {isQRScannerOpen && (
        <QRScannerModal
          onClose={toggleQRScanner}
          onScanComplete={handleQRScan}
        />
      )}
      <Form initialValues={{}} destroyOnUnregister onSubmit={handleFormSubmit}>
        {(formRenderProp): JSX.Element => (
          <>
            {authenticationStatus === AuthProcessStatus.failed && (
              <ErrorModal
                className="with-default-kiosk-components-theme"
                description={t(
                  'VIEWS.KEY_FLOW.KEY_AUTH.ERRORS.GENERATE_KEYS_UNAVAILABLE'
                )}
                size={BasicModalSize.sm}
                onConfirm={(): void => {
                  clearProcess();
                  formRenderProp.form.reset();
                }}
              />
            )}

            <Header title={t('VIEWS.KEY_FLOW.SHARED.TITLE')} />
            <Body className="padding-vertical-lg missing-device-container">
              <Section>
                <FormHeader
                  className="spacing-bottom-xxlg"
                  title={t('VIEWS.KEY_FLOW.KEY_AUTH.QR_CODE_SECTION.TITLE')}
                  hint={t('VIEWS.KEY_FLOW.KEY_AUTH.QR_CODE_SECTION.HINT')}
                />
                <LargeButton
                  icon={IconTypes.qrCode}
                  className="spacing-bottom-xxlg"
                  onClick={toggleQRScanner}
                  dataTestSelector={makeKeysTestSelector(
                    TEST_SELECTOR_PREFIX,
                    'qr-scanner-button'
                  )}
                >
                  {t('VIEWS.KEY_FLOW.KEY_AUTH.QR_CODE_SECTION.SCAN')}
                </LargeButton>
                <FormHeader
                  className="spacing-bottom-xxlg"
                  title={t('VIEWS.KEY_FLOW.KEY_AUTH.FORM_SECTION.TITLE')}
                  hint={t('VIEWS.KEY_FLOW.KEY_AUTH.FORM_SECTION.HINT')}
                />
                <Grid
                  gridTemplateColumnsSm="1fr 1fr"
                  gridTemplateRowsSm="auto"
                  className="gap-lg"
                >
                  <Grid.Item>
                    <FormField
                      valuePath="lastName"
                      validate={validator.validateSingleField}
                    >
                      {(fieldRenderProps): JSX.Element => (
                        <Field
                          {...mapFieldRenderProps(fieldRenderProps)}
                          label={t(
                            'VIEWS.KEY_FLOW.KEY_AUTH.FORM_SECTION.LAST_NAME'
                          )}
                          placeholder={t(
                            'VIEWS.KEY_FLOW.KEY_AUTH.FORM_SECTION.LAST_NAME'
                          )}
                          dataTestSelector={makeKeysTestSelector(
                            TEST_SELECTOR_PREFIX,
                            'last-name-field'
                          )}
                        />
                      )}
                    </FormField>
                  </Grid.Item>
                  <Grid.Item>
                    <FormField
                      valuePath="identificationNumber"
                      validate={validator.validateSingleField}
                    >
                      {(fieldRenderProps): JSX.Element => (
                        <Field
                          {...mapFieldRenderProps(fieldRenderProps)}
                          label={t(
                            'VIEWS.KEY_FLOW.KEY_AUTH.FORM_SECTION.CONFIRMATION_NO'
                          )}
                          placeholder={t(
                            'VIEWS.KEY_FLOW.KEY_AUTH.FORM_SECTION.CONFIRMATION_NO'
                          )}
                          dataTestSelector={makeKeysTestSelector(
                            TEST_SELECTOR_PREFIX,
                            'confirmation-number-field'
                          )}
                        />
                      )}
                    </FormField>
                  </Grid.Item>
                  {isSecondFactorVisible && (
                    <>
                      <Grid.Item colSpanSm={2}>
                        <Text hint className="spacing-bottom-lg">
                          {t(
                            'VIEWS.KEY_FLOW.KEY_AUTH.FORM_SECTION.ADDITIONAL_FIELD_HINT'
                          )}
                        </Text>
                      </Grid.Item>

                      <Grid.Item>
                        <FormField
                          valuePath="roomNumber"
                          validate={validator.validateSingleField}
                        >
                          {(fieldRenderProps): JSX.Element => (
                            <Field
                              {...mapFieldRenderProps(fieldRenderProps)}
                              className="padding-right-sm"
                              label={t(
                                'VIEWS.KEY_FLOW.KEY_AUTH.FORM_SECTION.ROOM_NO'
                              )}
                              placeholder={t(
                                'VIEWS.KEY_FLOW.KEY_AUTH.FORM_SECTION.ROOM_NO'
                              )}
                              dataTestSelector={makeKeysTestSelector(
                                TEST_SELECTOR_PREFIX,
                                'room-number-field'
                              )}
                            />
                          )}
                        </FormField>
                      </Grid.Item>
                    </>
                  )}
                </Grid>
              </Section>
            </Body>
            <MakeKeysFooter
              hasCancelButton
              hasPrimaryButton
              isPrimaryDisabled={formRenderProp.invalid}
              onPrimaryClick={formRenderProp.handleSubmit}
            />
          </>
        )}
      </Form>
    </MakeKeysView>
  );
};
