import { CustomError } from './customErrors';

export class AuthTooManyReservationsFoundError extends CustomError {
  static errorName = 'AuthTooManyReservationsFoundError';

  constructor(...args: ConstructorParameters<typeof CustomError>) {
    super(...args);
    this.errorName = AuthTooManyReservationsFoundError.errorName;
  }
}

export class AuthFailedError extends CustomError {
  static errorName = 'AuthFailedError';

  constructor(...args: ConstructorParameters<typeof CustomError>) {
    super(...args);
    this.errorName = AuthFailedError.errorName;
  }
}
