import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AlignItems,
  BasicModal,
  BasicModalSize,
  Button,
  Flex,
  FlexDirection,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { getCustomMessages } from '@gss/store/settings/selectors';

interface HelpModalProps {
  onClose: () => void;
  dataTestSelector?: string;
}

export const HelpModal = ({
  onClose,
  dataTestSelector,
}: HelpModalProps): JSX.Element => {
  const { t } = useTranslation();
  const customMessages = useSelector(getCustomMessages);

  return (
    <BasicModal
      className="with-default-kiosk-components-theme"
      size={BasicModalSize.lg}
      dataTestSelector={dataTestSelector}
    >
      <Flex direction={FlexDirection.column} alignItems={AlignItems.center}>
        <Text size={TextSize.xlg}>{t('DO_YOU_NEED_HELP')}</Text>
        <Text className="spacing-lg text-center">
          {customMessages?.NEED_HELP_MESSAGE}
        </Text>
        <Button dataTestSelector="help-modal-cancel-selector" onClick={onClose}>
          {t('SHARED.CLOSE')}
        </Button>
      </Flex>
    </BasicModal>
  );
};
