import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  ButtonPattern,
  Flex,
  FormHeader,
  MagicButton,
  MagicButtonHorizontalPosition,
  MagicButtonVerticalPosition,
  Section,
} from '@ac/kiosk-components';

import { Body, Header } from '@gss/components/layout';
import { NotificationBar, NotificationBarType } from '@gss/components/utility';
import { useRouter } from '@gss/router';
import { getCustomMessages, getImages } from '@gss/store/settings/selectors';
import { makeKeysTestSelector } from '@gss/utils/testSelectors';

import {
  MakeKeysDetailsHeader,
  MakeKeysFooter,
  MakeKeysView,
} from '../components';

import { generateKey, resetGenerateKeyStatus } from './store/actions';
import { KeyGenerationStatus } from './store/interfaces';
import {
  getGenerateKeysAmount,
  getGenerateKeyStatus,
  getKeyGeneratorStepErrors,
  getPossibleKeysAmountToGenerate,
} from './store/selectors';
import { KeyGenerationModal } from './components';

import './KeyGenerator.scss';

const TEST_SELECTOR_PREFIX = 'cutting';

const INITIAL_AMOUNT_OF_KEYS = 1;

enum KeyGenerationCompletionType {
  partial = 'partial',
  full = 'full',
}

export const KeyGenerator = (): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const customMessages = useSelector(getCustomMessages);
  const errors = useSelector(getKeyGeneratorStepErrors);
  const images = useSelector(getImages);
  const possibleKeysAmount = useSelector(getPossibleKeysAmountToGenerate);
  const generateKeyStatus = useSelector(getGenerateKeyStatus);
  const amountOfCreatedKeys = useSelector(getGenerateKeysAmount);

  const [isGenerateKeyModalOpen, setGenerateKeyModalOpen] = useState(false);
  const [keyAmount, setKeyAmount] = useState(INITIAL_AMOUNT_OF_KEYS);
  const [currentKey, setCurrentKey] = useState(1);
  const [completionType, setCompletionType] =
    useState<KeyGenerationCompletionType>();

  const zeroKeySelected = keyAmount === 0;
  const isProcessFullyCompleted =
    completionType === KeyGenerationCompletionType.full;

  const openGenerateModal = (): void => {
    if (!keyAmount) {
      router.goToNextStep();
    } else {
      setGenerateKeyModalOpen(true);
    }
  };

  const handleGenerateModalCancel = useCallback((): void => {
    setGenerateKeyModalOpen(false);
    setCurrentKey(1);
    dispatch(resetGenerateKeyStatus());

    if (generateKeyStatus === KeyGenerationStatus.success || currentKey > 1) {
      setCompletionType(KeyGenerationCompletionType.partial);
    }
  }, [dispatch, currentKey, generateKeyStatus]);

  const handleGenerateModalConfirm = useCallback((): void => {
    if (generateKeyStatus === KeyGenerationStatus.success) {
      dispatch(resetGenerateKeyStatus());
      setCurrentKey((current) => current + 1);
    } else {
      dispatch(generateKey.trigger());
    }
  }, [generateKeyStatus, dispatch]);

  const keyGenerationData = useMemo(() => {
    return {
      type: generateKeyStatus,
      keyNumber: currentKey,
      amount: keyAmount,
      onCancel: handleGenerateModalCancel,
      onConfirm: handleGenerateModalConfirm,
    };
  }, [
    currentKey,
    handleGenerateModalCancel,
    handleGenerateModalConfirm,
    keyAmount,
    generateKeyStatus,
  ]);

  useEffect(() => {
    if (
      generateKeyStatus === KeyGenerationStatus.success &&
      currentKey === keyAmount
    ) {
      handleGenerateModalCancel();

      if (completionType !== KeyGenerationCompletionType.full) {
        setCompletionType(KeyGenerationCompletionType.full);
      }
    }
  }, [
    completionType,
    currentKey,
    generateKeyStatus,
    handleGenerateModalCancel,
    keyAmount,
  ]);

  return (
    <MakeKeysView isError={Boolean(errors.length)}>
      <Header title={t('VIEWS.KEY_FLOW.SHARED.TITLE')} />
      <MakeKeysDetailsHeader />

      {Boolean(completionType) && (
        <NotificationBar
          description={t('VIEWS.KEY_FLOW.KEY_GENERATOR.KEYS_READY', {
            count: amountOfCreatedKeys,
          })}
          type={NotificationBarType.success}
        />
      )}

      {isGenerateKeyModalOpen && <KeyGenerationModal {...keyGenerationData} />}

      <Body className="key-generator-container">
        <Flex className="key-generator-selector-container">
          <Box sizeSm={images?.KEYS_GENERATE_IMAGE ? 6 : 12}>
            <Section className="relative">
              {isProcessFullyCompleted ? (
                <FormHeader
                  title={t('VIEWS.KEY_FLOW.KEY_GENERATOR.FINISH_PROCESS')}
                />
              ) : (
                <>
                  <FormHeader
                    className="spacing-bottom-xxlg"
                    title={t(
                      'VIEWS.KEY_FLOW.KEY_GENERATOR.KEY_FORM_SECTION_TITLE'
                    )}
                    hint={customMessages?.KEY_MESSAGE}
                  />
                  <Flex
                    className="gap-sm"
                    dataTestSelector={makeKeysTestSelector(
                      TEST_SELECTOR_PREFIX,
                      'keys-number-selection'
                    )}
                  >
                    {Array(possibleKeysAmount)
                      .fill(null)
                      .map((_, keyIndex) => (
                        <Button
                          pattern={
                            keyIndex + 1 === keyAmount
                              ? ButtonPattern.primary
                              : ButtonPattern.secondary
                          }
                          onClick={(): void => setKeyAmount(keyIndex + 1)}
                          key={keyIndex}
                        >
                          {keyIndex + 1}
                        </Button>
                      ))}
                  </Flex>
                  <MagicButton
                    onClick={(): void => setKeyAmount(0)}
                    positionHorizontal={MagicButtonHorizontalPosition.right}
                    positionVertical={MagicButtonVerticalPosition.top}
                    width={90}
                    height={90}
                    dataTestSelector={makeKeysTestSelector(
                      TEST_SELECTOR_PREFIX,
                      'magic-button'
                    )}
                  />
                </>
              )}
            </Section>
          </Box>
          {images?.KEYS_GENERATE_IMAGE && (
            <Box
              sizeSm={6}
              dataTestSelector={makeKeysTestSelector(
                TEST_SELECTOR_PREFIX,
                'image-box'
              )}
            >
              <img
                className="key-generator-selector-image"
                src={images?.KEYS_GENERATE_IMAGE}
              />
            </Box>
          )}
        </Flex>
      </Body>
      <MakeKeysFooter
        hasCancelButton
        hasPrimaryButton={Boolean(completionType) || zeroKeySelected}
        primaryLabel={t('SHARED.FINISH')}
        onPrimaryClick={(): void => router.goToNextStep()}
      >
        {!isProcessFullyCompleted && !zeroKeySelected && (
          <Button className="spacing-left-auto" onClick={openGenerateModal}>
            {t('VIEWS.KEY_FLOW.SHARED.MAKE_KEY')}
          </Button>
        )}
      </MakeKeysFooter>
    </MakeKeysView>
  );
};
