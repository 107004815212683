import { TimeFormat } from '@ac/library-api';

export const APP_NAME = 'guest-self-service';
export const CLIENT_ID = 'GuestSelfService-KioskApp';

export const APP_ROOT_CONTAINER_ID = 'root';
export const CONSOLE_SIMULATOR_ROOT_CONTAINER_ID = 'console';

export const DEFAULT_APP_LANGUAGE = 'en';

export const LOG_TYPES = {
  app: 'aboveCloud.appLogs',
  auth: 'aboveCloud.authLogs',
  authErrorCache: 'aboveCloud.authErrorCache',
};

export const DEFAULT_SHORT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_LONG_DATE_FORMAT = 'dddd, MMMM dd, YYYY';
export const DEFAULT_TIME_FORMAT_CODE = TimeFormat.H24;
export const TIME_12H_FORMAT = 'h:mm A';
export const TIME_24H_FORMAT = 'HH:mm';

export const LOG_MESSAGES = {
  API_FAILURE: '[API FAILURE]',
  REDUX_EXCEPTION: '[REDUX EXCEPTION]',
  REDUX_MIDDLEWARE_EXCEPTION: '[REDUX MIDDLEWARE EXCEPTION]',
  REACT_EXCEPTION: '[REACT EXCEPTION]',
  REACT_UNHANDLED_REJECTION: '[REACT UNHANDLED REJECTION]',

  API_FAILURE_ACTION: '[API FAILURE ACTION]',
  SAGA_FAILURE_ACTION: '[SAGA FAILURE ACTION]',

  SAGA_UNCATCHED_ERROR: '[SAGA UNCATCHED ERROR]',
  REDUCER_UNCATCHED_ERROR: '[REDUCER UNCATCHED ERROR]',

  CONNECTION_LOST_LOG_MESSAGE: '[OFFLINE] Connection lost',
  CONNECTION_RECEIVED_LOG_MESSAGE: '[ONLINE] Connection returned',

  REFRESH_CONFIGURATION_FAILURE:
    '[BACKGROUND TASK FAILURE] Refresh configuration',
};

export const API_HEADERS = {
  acceptLanguage: 'Accept-Language',
  acPropertyId: 'AC-Property-ID',
  kioskSessionId: 'AC-KioskSession-ID',
  ifMatch: 'If-Match',
};

export const EXPORTED_FILES_NAMES = {
  app: 'error-logs',
  auth: 'auth-logs',
};
