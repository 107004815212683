import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  AlignItems,
  Color,
  Flex,
  FormHeader,
  Icon,
  IconTypes,
  Section,
  SectionType,
  Size,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { Body, Header } from '@gss/components/layout';
import { useRouter } from '@gss/router';
import { startCheckInSession } from '@gss/store/flows/checkInFlow/actions';
import {
  getIsCheckInProcessReady,
  getIsMultiRoomReservation,
  getSelectedReservation,
} from '@gss/store/flows/checkInFlow/selectors';
import { useLargeScreenMatchMedia } from '@gss/utils/hooks';

import {
  CheckInFooter,
  CheckInView,
  CheckNameDetailsModal,
} from '../components';

import { AccompanyingGuestTable } from './components/AccompanyingGuestTable';

export const CheckInAccompanyingGuests = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const [isCheckNameModalOpen, setCheckNameModalOpen] = useState(false);
  const [changeProfileRequired, setChangeProfileRequired] = useState(false);
  const isLargeScreen = useLargeScreenMatchMedia();
  const isMultiRoomReservation = useSelector(getIsMultiRoomReservation);
  const reservation = useSelector(getSelectedReservation);
  const isCheckInProcessReady = useSelector(getIsCheckInProcessReady);

  const isMainGuestCheckedIn = reservation?.guest?.checkedIn ?? false;

  const startStartSession = useCallback(
    (profileId: string) => {
      if (reservation?.id && reservation?.guest?.id) {
        dispatch(
          startCheckInSession.trigger({
            reservationId: reservation.id,
            profileId,
          })
        );
      }
    },
    [dispatch, reservation]
  );

  const toggleCheckNameModal = useCallback(() => {
    setCheckNameModalOpen((isOpen) => !isOpen);
  }, []);

  const handleCheckInClick = useCallback(
    (profileId?: string): void => {
      if (!profileId) return;

      if (isMultiRoomReservation && profileId === reservation?.guest?.id) {
        toggleCheckNameModal();
      } else {
        startStartSession(profileId);
      }
    },
    [
      toggleCheckNameModal,
      reservation,
      isMultiRoomReservation,
      startStartSession,
    ]
  );

  const handleConfirmNameDetails = useCallback(() => {
    toggleCheckNameModal();
    if (reservation?.guest?.id) {
      startStartSession(reservation.guest.id);
    }
  }, [toggleCheckNameModal, startStartSession, reservation]);

  const handleChangeNameDetails = useCallback(() => {
    toggleCheckNameModal();
    setChangeProfileRequired(true);
    if (reservation?.guest?.id) {
      startStartSession(reservation.guest.id);
    }
  }, [toggleCheckNameModal, startStartSession, reservation]);

  const handleBackClick = (): void => {
    router.goBack();
  };

  useEffect(() => {
    if (isCheckInProcessReady) {
      if (changeProfileRequired) {
        router.goTo('CHECK_IN_NAME_DETAILS');
      } else {
        router.goToNextStep();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckInProcessReady]);

  return (
    <>
      {isCheckNameModalOpen && (
        <CheckNameDetailsModal
          guest={reservation?.guest}
          onClose={toggleCheckNameModal}
          onChange={handleChangeNameDetails}
          onConfirm={handleConfirmNameDetails}
        />
      )}

      <CheckInView>
        <Header
          title={[
            t('CHECK_IN_FLOW.TITLE'),
            t('CHECK_IN_FLOW.VIEWS.ACCOMPANYING_GUESTS.HEADER'),
          ].join(' - ')}
        />
        <Body>
          <Section type={isLargeScreen ? SectionType.narrow : SectionType.wide}>
            <FormHeader
              className="spacing-bottom-xxlg spacing-top-md"
              title={
                isMainGuestCheckedIn
                  ? t(
                      'CHECK_IN_FLOW.VIEWS.ACCOMPANYING_GUESTS.SELECTION_SECTION.TITLE_MAIN_GUEST_CHECKED_IN'
                    )
                  : t(
                      'CHECK_IN_FLOW.VIEWS.ACCOMPANYING_GUESTS.SELECTION_SECTION.TITLE_MAIN_GUEST_NOT_CHECKED_IN'
                    )
              }
            />
            {reservation && (
              <AccompanyingGuestTable
                reservation={reservation}
                onCheckInClick={handleCheckInClick}
              />
            )}
          </Section>
        </Body>
        <CheckInFooter
          hasCancelButton
          hasSecondaryButton={isMultiRoomReservation}
          onSecondaryClick={handleBackClick}
        >
          {isMainGuestCheckedIn && (
            <Flex
              className="gap-sm spacing-horizontal-lg"
              alignItems={AlignItems.center}
            >
              <Icon
                size={Size.sm}
                type={IconTypes.checkFilled}
                color={Color.success}
              />
              <Text size={TextSize.xs}>
                {t('CHECK_IN_FLOW.VIEWS.MULTI_ROOM.STATUSES.CHECKED_IN')}
              </Text>
            </Flex>
          )}
        </CheckInFooter>
      </CheckInView>
    </>
  );
};
