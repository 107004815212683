import { useRef } from 'react';

import { createRequiredValidator } from '@ac/react-infrastructure';

import { FormValidator } from '@gss/utils/form';

import { KeyAuthFormValues } from './types';

export const useValidator = (): FormValidator<KeyAuthFormValues> => {
  const validator = useRef(
    new FormValidator<KeyAuthFormValues>({
      identificationNumber: createRequiredValidator(
        'VALIDATION_MESSAGES.FIELD_IS_MANDATORY'
      ),
      lastName: createRequiredValidator(
        'VALIDATION_MESSAGES.FIELD_IS_MANDATORY'
      ),
      roomNumber: createRequiredValidator(
        'VALIDATION_MESSAGES.FIELD_IS_MANDATORY'
      ),
    })
  );

  return validator.current;
};
