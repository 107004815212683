import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Flex, Header as BasicHeader, Link } from '@ac/kiosk-components';

import { LogoPlaceholder } from '@gss/assets/images';
import { HelpModal } from '@gss/components/modals';
import { SupportedLanguagesSelector } from '@gss/components/utility';
import { getImages, getStyles } from '@gss/store/settings/selectors';

import './Header.scss';

interface HeaderProps {
  dataTestSelector?: string;
  title?: string;
  languageSelector?: boolean;
  helpLink?: boolean;
  className?: string;
}

export const Header = ({
  dataTestSelector,
  title,
  languageSelector = true,
  helpLink = true,
  className,
}: HeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const [isHelpModalOpened, setHelpModalOpen] = useState(false);
  const images = useSelector(getImages);
  const styleSettings = useSelector(getStyles);

  const toggleHelpModal = useCallback(() => {
    setHelpModalOpen((isOpened) => !isOpened);
  }, []);

  return (
    <>
      <BasicHeader
        className={classNames(
          'header-container',
          {
            ['header-container-transparent']:
              !styleSettings?.SEPARATE_HEADER_BG,
          },
          className
        )}
        dataTestSelector={dataTestSelector}
        logo={images?.WELCOME_LOGO || LogoPlaceholder}
        title={title}
      >
        <Flex className="padding-right-lg spacing-left-auto">
          {languageSelector && (
            <SupportedLanguagesSelector
              dataTestSelector="header-language-selector"
              className="spacing-left-xlg"
            />
          )}
          {helpLink && (
            <Link
              dataTestSelector="header-help-link-selector"
              className="spacing-left-xlg"
              onClick={toggleHelpModal}
            >
              {t('NEED_HELP')}
            </Link>
          )}
        </Flex>
      </BasicHeader>

      {isHelpModalOpened && (
        <HelpModal
          dataTestSelector="header-help-modal-selector"
          onClose={toggleHelpModal}
        />
      )}
    </>
  );
};
