import { all } from 'redux-saga/effects';

import { SagasGenerator } from '@gss/types/shared';

import { globalSagas } from './globalSagas';
import { localSagas } from './localSagas';

export function* rootSagas(): SagasGenerator {
  yield all([...globalSagas, ...localSagas]);
}
