import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlignItems,
  Button,
  ButtonPattern,
  Color,
  Flex,
  Icon,
  IconTypes,
  Size,
  Table,
  Text,
  TextSize,
} from '@ac/kiosk-components';
import { TableConfig } from '@ac/kiosk-components/dist/components/layoutComponents/Table/types';

import { CheckInAvailableReservationDto } from '@gss/api/KioskApi/entries';

import { mapGuestData } from '../../utils';

import './AccompanyingGuestTable.scss';

const TABLE_CONFIG: TableConfig = {
  align: AlignItems.center,
  columns: [
    { sizeSm: '1fr' },
    { sizeSm: 'max-content' },
    { sizeSm: 'max-content' },
  ],
};

interface Props {
  reservation: CheckInAvailableReservationDto;
  onCheckInClick?: (profileId?: string) => void;
}

// IT Fails for Check-in in case reservation was checked-in

export const AccompanyingGuestTable = ({
  reservation,
  onCheckInClick,
}: Props): JSX.Element => {
  const { t, i18n } = useTranslation();

  const mainGuest = useMemo(() => {
    return reservation?.guest && mapGuestData(reservation.guest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation?.guest, i18n.language]);

  const accompanyingGuests = useMemo(() => {
    return reservation?.accompanyingGuests?.map(mapGuestData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation?.accompanyingGuests, i18n.language]);

  return (
    <Table config={TABLE_CONFIG} className="accompanying-guest-table">
      <Table.Header>
        <Table.Cell>
          {t(
            'CHECK_IN_FLOW.VIEWS.ACCOMPANYING_GUESTS.SELECTION_SECTION.MAIN_GUEST'
          )}
        </Table.Cell>
        <Table.Cell colSpan={2}>
          {t('CHECK_IN_FLOW.VIEWS.ACCOMPANYING_GUESTS.SELECTION_SECTION.STAY')}
        </Table.Cell>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <Flex alignItems={AlignItems.center} className="gap-sm">
              {mainGuest?.isCheckedIn && (
                <Icon
                  type={IconTypes.checkFilled}
                  size={Size.sm}
                  color={Color.success}
                />
              )}
              <Text>{mainGuest?.guestName}</Text>
            </Flex>
          </Table.Cell>
          <Table.Cell>{mainGuest?.stay}</Table.Cell>
          <Table.Cell className="padding-none">
            {!mainGuest?.isCheckedIn && (
              <Button
                pattern={ButtonPattern.tertiary}
                onClick={(): void => onCheckInClick?.(mainGuest?.id)}
              >
                {t(
                  'CHECK_IN_FLOW.VIEWS.ACCOMPANYING_GUESTS.SELECTION_SECTION.CHECK_IN'
                )}
              </Button>
            )}
          </Table.Cell>
        </Table.Row>

        <Table.Header>
          <Table.Cell
            className="accompanying-guest-section-header-cell"
            colSpan={3}
          >
            <Flex alignItems={AlignItems.center} className="gap-sm">
              {t(
                'CHECK_IN_FLOW.VIEWS.ACCOMPANYING_GUESTS.SELECTION_SECTION.ACCOMPANYING_GUEST'
              )}
              {!mainGuest?.isCheckedIn && (
                <>
                  <Icon
                    type={IconTypes.warning}
                    size={Size.sm}
                    color={Color.warning}
                    className="spacing-left-sm"
                  />
                  <Text size={TextSize.xs}>
                    {t(
                      'CHECK_IN_FLOW.VIEWS.ACCOMPANYING_GUESTS.STATUSES.WARNING'
                    )}
                  </Text>
                </>
              )}
            </Flex>
          </Table.Cell>
        </Table.Header>

        {accompanyingGuests?.map(
          (guest): JSX.Element => (
            <Table.Row key={guest.id}>
              <Table.Cell>
                <Flex alignItems={AlignItems.center} className="gap-sm">
                  {guest?.isCheckedIn && (
                    <Icon
                      type={IconTypes.checkFilled}
                      size={Size.sm}
                      color={Color.success}
                    />
                  )}
                  <Text>{guest?.guestName}</Text>
                </Flex>
              </Table.Cell>
              <Table.Cell>{guest?.stay}</Table.Cell>
              <Table.Cell className="padding-none">
                {!guest?.isCheckedIn && mainGuest?.isCheckedIn && (
                  <Button
                    pattern={ButtonPattern.tertiary}
                    onClick={(): void => onCheckInClick?.(guest?.id)}
                  >
                    {t(
                      'CHECK_IN_FLOW.VIEWS.MULTI_ROOM.SELECTION_SECTION.CHECK_IN'
                    )}
                  </Button>
                )}
              </Table.Cell>
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
  );
};
