import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  Button,
  ButtonPattern,
  Flex,
  Footer as BasicFooter,
} from '@ac/kiosk-components';

import { Stepper } from '@gss/components/utility';
import { getStyles } from '@gss/store/settings/selectors';

import './Footer.scss';

type BasicFooterProps = React.ComponentProps<typeof BasicFooter>;

interface FooterProps extends BasicFooterProps {
  hasCancelButton?: boolean;
  onCancelClick?: () => void;
  withStepper?: boolean;
}

export const Footer = ({
  children,
  hasCancelButton,
  onCancelClick,
  className,
  withStepper,
  ...rest
}: FooterProps): JSX.Element => {
  const { t } = useTranslation();
  const styleSettings = useSelector(getStyles);

  return (
    <BasicFooter
      {...rest}
      className={classNames(
        'footer-container',
        {
          ['footer-container-transparent']: !styleSettings?.SEPARATE_FOOTER_BG,
        },
        className
      )}
    >
      {withStepper && (
        <Stepper
          dataTestSelector="footer-stepper-selector"
          className="footer-stepper"
        />
      )}
      <Flex>
        {hasCancelButton && (
          <Button
            dataTestSelector="footer-cancel-button"
            onClick={onCancelClick}
            pattern={ButtonPattern.secondary}
            className="spacing-right-lg"
          >
            {t('SHARED.CANCEL')}
          </Button>
        )}
        {children}
      </Flex>
    </BasicFooter>
  );
};
