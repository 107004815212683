import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Body, Footer, View } from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import { getFormValues, submit } from 'redux-form';
import {
  addBillingInstruction,
  addCompanyFolio,
  applyBillingInstruction,
  fetchFolios,
  fetchFolioTransactions,
  fetchPaymentMethods,
  getApplyBillingInstructionStatus,
  linkPaymentMethod,
  transferTransactions,
  updateFolioProfile,
} from 'store/cashiering/actions';
import { fetchFolio } from 'store/cashiering/folio/actions';
import { getNewFolioId } from 'store/cashiering/folio/selectors';
import {
  getActiveCompanyFolio,
  getActiveFolios,
  getBillingInstructions,
  getIndividualFolio,
  getPaymentMethods,
} from 'store/cashiering/selectors';
import {
  createCompany,
  fetchCreateCompanyStatus,
  linkCompanyToProfile,
} from 'store/profile/actions';
import { getNewCompanyId } from 'store/profile/selectors';
import { getReservation } from 'store/reservation/selectors';
import { getCompanyProfileIds, getErrors } from 'store/selectors';
import { Folio, PaymentMethod } from 'types/Api/Cashiering';
import { CompanyToCreate, LinkCompanyDataModel } from 'types/Api/Profile';
import { ReservationView } from 'types/Api/Reservation';
import { ApiError, Transaction } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';

import { Header } from '@gss/components/layout';
import { Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './CheckInPayment.style';
import CompanySelect from './CompanySelect';
import InvoiceForm from './InvoiceForm';

const { CHECK_IN_FAILED_MESSAGE } = Configurator.getTranslationCodes();

interface PassedProps {}

interface CheckInPaymentProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  submit: typeof submit;
  createCompany: typeof createCompany;
  linkCompanyToProfile: typeof linkCompanyToProfile;
  addBillingInstruction: typeof addBillingInstruction;
  applyBillingInstruction: typeof applyBillingInstruction;
  updateFolioProfile: typeof updateFolioProfile;
  getApplyBillingInstructionStatus: typeof getApplyBillingInstructionStatus;
  addCompanyFolio: typeof addCompanyFolio;
  fetchFolio: typeof fetchFolio;
  linkedCompanyIds: string[];
  billingInstructions: {
    targetFolioId: string;
    operationId: string;
  };
  companyFolio: Folio;
  individualFolio: Folio;
  folios: Folio[];
  reservation: ReservationView;
  newCompanyId: string;
  companyDetails: {
    fullName: string;
    taxId: string;
  };
  companyAddress: {
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    city: string;
    countryCode: string;
    stateCode: string;
    districtId?: string;
  };
  errors: ApiError[];
  newFolioId?: string;
  fetchPaymentMethods: typeof fetchPaymentMethods;
  linkPaymentMethod: typeof linkPaymentMethod;
  fetchFolios: typeof fetchFolios;
  paymentMethods: PaymentMethod[];
  fetchFolioTransactions: typeof fetchFolioTransactions;
  transferTransactions: typeof transferTransactions;
  fetchCreateCompanyStatus: typeof fetchCreateCompanyStatus;
}

interface CheckInPaymentState {
  isSubmitDisabled: boolean;
  isPanelExpanded: boolean;
  selectedCompanyId: string;
  isFormVisible: boolean;
  areMultipleFolios: boolean;
  isContinueLoading: boolean;
}

class CheckInPayment extends PureComponent<
  CheckInPaymentProps,
  CheckInPaymentState
> {
  public state = {
    isSubmitDisabled: true,
    isPanelExpanded: false,
    selectedCompanyId: '',
    isFormVisible: false,
    areMultipleFolios: false,
    isContinueLoading: false,
  };

  public componentDidMount() {
    const { folios } = this.props;
    const companyFolioExists = folios.some(
      (folio) =>
        folio.profileRoleCode.code === Configurator.profileRoles.COMPANY
    );

    if (folios.length > 1 && companyFolioExists)
      this.setState({
        areMultipleFolios: true,
      });
  }

  public render() {
    const { classes, t, errors } = this.props;
    const {
      isFormVisible,
      selectedCompanyId,
      isPanelExpanded,
      areMultipleFolios,
      isContinueLoading,
    } = this.state;

    return (
      <View
        className={classes.view}
        idle={{ type: 'modal' }}
        modal={{
          values: errors,
          customErrorCode: CHECK_IN_FAILED_MESSAGE,
          isLoading: isContinueLoading,
        }}
      >
        <Header title={`${t('CHECK_IN')} - ${t('PAYMENT')}`} />
        <Body className={classes.body}>
          <Typography className={classes.title}>
            {t('GET_INVOICE', { routeName: t('CHECK_OUT') })}
          </Typography>
          {isFormVisible ? (
            <InvoiceForm
              setSubmitState={this.setSubmitState}
              onSubmit={this.onSubmit}
              selectedCompanyId={selectedCompanyId}
            />
          ) : (
            <CompanySelect
              onToggle={this.toggleSelectExpansionState}
              onSelect={this.onCompanySelect}
              isExpanded={isPanelExpanded}
              isLocked={areMultipleFolios}
            />
          )}
        </Body>
        <Footer
          hasContinueButton
          hasBackButton
          hasCancelButton
          isContinueLoading={isContinueLoading}
          routeName={t('CHECK_IN')}
          onGoBack={this.onGoBack}
          onContinue={this.submit}
          isContinueDisabled={this.isContinueDisabled()}
        />
      </View>
    );
  }

  private onGoBack = () => {
    const { isFormVisible } = this.state;
    const { history } = this.props;
    if (!isFormVisible) return history.push(Router.prevStepURL);

    return this.setState({
      selectedCompanyId: '',
      isFormVisible: false,
      isSubmitDisabled: true,
    });
  };

  private setSubmitState = (isFormValid: boolean) =>
    this.setState({ isSubmitDisabled: !isFormValid });

  private onCompanySelect = async (companyId?: string) => {
    await this.setState({
      isFormVisible: !companyId,
      selectedCompanyId: companyId || '',
    });
    if (companyId) {
      this.enableLoading();
      await this.onSubmit();
    }
  };

  private toggleSelectExpansionState = () => {
    const { isPanelExpanded } = this.state;
    this.setState({ isPanelExpanded: !isPanelExpanded });
    if (isPanelExpanded) this.setState({ selectedCompanyId: '' });
  };

  private submit = async () => {
    const { history } = this.props;
    const { selectedCompanyId, isPanelExpanded } = this.state;
    if (!isPanelExpanded) return history.push(Router.nextStepURL);
    const { submit } = this.props;
    this.enableLoading();
    if (selectedCompanyId) return submit(ids.DETAILS_FORM);
    await this.onCompanyAdd();
    this.onSubmit();
  };

  private onCompanyAdd = async () => {
    const {
      companyDetails: { fullName, taxId },
      companyAddress: {
        addressLine1,
        addressLine2,
        city,
        countryCode,
        postalCode,
        stateCode,
        districtId,
      },
      createCompany,
      i18n,
    } = this.props;

    const data: CompanyToCreate = {
      communicationChannels: [],
      externalSystemIdentifiers: [],
      customFieldContainerJson: '{}',
      addresses: [
        {
          addressTypeCode: Configurator.defaultCompanyAddressType?.code,
          addressLine1,
          addressLine2,
          city,
          countryCode,
          postalCode,
          stateCode,
          districtId,
          isArPrimary: false,
          isPrimary: true,
          languageCode: i18n.language.toUpperCase(),
        },
      ],
      details: {
        fullName,
        taxId,
        businessSegmentCodes: [],
        corporateId: '',
        industryCodes: [],
      },
      typeCode: Configurator.getProfileTypeCode(
        Configurator.profileRoles.COMPANY
      ),
    };

    await createCompany(data);
    const {
      newCompanyId,
      linkCompanyToProfile,
      fetchCreateCompanyStatus,
      reservation: { profileId },
    } = this.props;
    await fetchCreateCompanyStatus(newCompanyId);
    const companyLinkData: LinkCompanyDataModel = {
      profileId,
      profileRoleCode: Configurator.profileRoles.INDIVIDUAL,
    };
    await linkCompanyToProfile(newCompanyId, companyLinkData);
  };

  private onSubmit = async () => {
    const {
      companyFolio,
      individualFolio,
      history,
      fetchFolios,
      fetchFolioTransactions,
    } = this.props;
    if (!companyFolio) await this.onFolioAdd();
    if (this.props.errors.length) return this.disableLoading();
    await this.onBillingInstructionAdd();
    await fetchFolios();
    if (this.props.errors.length) return this.disableLoading();
    if (individualFolio) await fetchFolioTransactions(individualFolio.id);
    if (this.props.errors.length) return this.disableLoading();
    await this.transferTransactions();
    if (this.props.errors.length) return this.disableLoading();
    history.push(Router.nextStepURL);
  };

  private onFolioAdd = async () => {
    const {
      reservation: { accountId },
      addCompanyFolio,
      newCompanyId,
      fetchFolio,
    } = this.props;
    const { selectedCompanyId: id } = this.state;
    const {
      cashieringDefaultSettings: { billViewAllowed, remoteCheckOutAllowed },
      folioCodes: { FOLIO_COMPANY_TYPE },
      defaultFolioStyle: { code: folioStyleCode },
    } = Configurator;
    const data = {
      remoteCheckOutAllowed,
      billViewAllowed,
      additionalText: '',
      ...(folioStyleCode && { folioStyleCode }),
      folioTypeCode: FOLIO_COMPANY_TYPE,
      paymentMethodId: null,
      profileId: id || newCompanyId,
    };
    await addCompanyFolio(accountId, data);
    if (this.props.newFolioId) {
      await fetchFolio(this.props.newFolioId);
    }
  };

  private onBillingInstructionAdd = async () => {
    const {
      reservation: { accountId },
      addBillingInstruction,
      applyBillingInstruction,
      getApplyBillingInstructionStatus,
      billingInstructions,
      companyFolio,
    } = this.props;
    const targetFolioId = companyFolio
      ? companyFolio.id
      : billingInstructions.targetFolioId;
    const applicableTransactionCodeIds =
      Configurator.billingTransactionCodes.map((elem) => elem.id);
    const data = {
      targetFolioId,
      newFolio: null,
      profileName: null,
      selector: {
        applicableTransactionCodeIds,
      },
      targetAccountId: null,
      version: '',
    };
    await addBillingInstruction(accountId, data);
    await applyBillingInstruction(accountId);
    await getApplyBillingInstructionStatus();
  };

  private disableLoading = () => {
    this.setState({ isContinueLoading: false });
  };

  private enableLoading = () => {
    this.setState({ isContinueLoading: true });
  };

  private transferTransactions = async () => {
    const {
      transferTransactions,
      reservation: { accountId },
      companyFolio,
      individualFolio,
    } = this.props;
    const cashierNumber = Configurator.cashieringSettings.cashierNumber || '';
    const data = {
      cashierNumber,
      targetAccountId: accountId,
      targetFolioWindowId: companyFolio ? companyFolio.id : '',
      transferToNewFolio: false,
      transactions: individualFolio
        ? individualFolio.transactions.map((transaction: Transaction) => ({
            transactionId: transaction.id,
            version: transaction.version,
          }))
        : '',
    };
    if (individualFolio.transactions.length) await transferTransactions(data);
  };

  private isContinueDisabled = () => {
    const {
      isSubmitDisabled,
      isFormVisible,
      selectedCompanyId,
      isPanelExpanded,
      areMultipleFolios,
    } = this.state;
    const isCommpanyIdRequired = isFormVisible
      ? !selectedCompanyId
      : selectedCompanyId;
    const isNotCompleted = areMultipleFolios
      ? !selectedCompanyId || isFormVisible
      : isCommpanyIdRequired || isFormVisible;
    const isContinueDisabled =
      (isSubmitDisabled && isNotCompleted) ||
      (isPanelExpanded && !isFormVisible);

    return !!isContinueDisabled;
  };
}

const mapStateToProps = (state: Store) => ({
  companyFolio: getActiveCompanyFolio(state),
  individualFolio: getIndividualFolio(state),
  billingInstructions: getBillingInstructions(state),
  companyDetails: getFormValues(ids.DETAILS_FORM)(state),
  companyAddress: getFormValues(ids.ADDRESS_FORM)(state),
  folios: getActiveFolios(state),
  newCompanyId: getNewCompanyId(state),
  linkedCompanyIds: getCompanyProfileIds(state),
  reservation: getReservation(state),
  errors: getErrors(state),
  newFolioId: getNewFolioId(state),
  paymentMethods: getPaymentMethods(state),
});

const mapDispatchToProps = {
  updateFolioProfile,
  addBillingInstruction,
  addCompanyFolio,
  applyBillingInstruction,
  getApplyBillingInstructionStatus,
  linkCompanyToProfile,
  createCompany,
  submit,
  fetchFolio,
  fetchPaymentMethods,
  linkPaymentMethod,

  fetchFolios,
  fetchFolioTransactions,
  transferTransactions,
  fetchCreateCompanyStatus,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInPayment) as (props: PassedProps) => JSX.Element;
