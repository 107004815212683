import {
  createAction,
  createAsyncAction,
} from '@ac/library-utils/dist/redux-utils';

const ACTION_PREFIX = '@local/keyGeneratorStep/';

export const generateKey = createAsyncAction(`${ACTION_PREFIX}generateKey`);

export const resetGenerateKeyStatus = createAction(
  `${ACTION_PREFIX}resetGenerateKeyStatus`
);
