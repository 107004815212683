import { CustomError } from '@gss/utils/errors';

export class GenerateKeyError extends CustomError {
  static errorName = 'GenerateKeyError';

  constructor(...args: ConstructorParameters<typeof CustomError>) {
    super(...args);
    this.errorName = GenerateKeyError.errorName;
  }
}
